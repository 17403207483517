import axios from "axios";
import moment from "moment-timezone";
import {
    ClientBindingModel,
    CustomFieldTypeDTO,
    LeadStatusForClientConfigDTO,
    ProductTypeDTO,
} from "../clientConfiguratorTypes";
import {
    AdminBrokerLeadStatusLogParams,
    AdminBrokerLeadStatusPagingLogParams,
    AdminCarrierMetricsUICurrentDTO,
    AdminPerformanceSummaryDTO,
    AssignBrokerBindingModel,
    AssignToNextEligibleBrokerBindingModel,
    BrokerAssocationByAdminBindingModel,
    BrokerAuditTrailDTO,
    BrokerDayScheduleBindingModel,
    BrokerDoNotAcceptLeadBindingModel,
    BrokerMinDTO,
    BrokerPerformanceSummaryDTO,
    BrokerRegistrationSearchDTO,
    BrokerSearchAssignBindingModel,
    BrokerSearchBindingModel,
    BrokerSearchDTO,
    BrokersInfoDTO,
    BrokersPagingDTO,
    BrokersScheduleBindingModel,
    BrokerUpdateProfileByAdminBindingModel,
    BrokerUserDTO,
    BrokerVacationBindingModel,
    CarrierBrokerBaseFieldTooltipDTO,
    CarrierDTO,
    CarrierIdGuidBindingModel,
    CarrierIdGuidNameDTO, CarrierJustificationsMinDTO,
    CarrierReferralBulkUploadConfigurationBindingModel,
    CreateAdminBrokerBindingModel,
    CreateCarrierUserBindingModel,
    CreateLeadAdminBindingModel,
    CreateUserBindingModel,
    DoNotAllowRecordDTO,
    LeadAuditTrailDTO,
    LeadDetailAdminDTO,
    LeadSearchBindingModel,
    LeadsPagingDTO,
    LeadStatusLogPagingDTO,
    MetricsUIDailyDTO,
    NonBrokerUserDTO,
    PerformanceStats,
    ReferralBulkUploadColumn,
    SaveBrandingDocumentBindingModel,
    SearchCarrierBrokerBindingModel,
    UpdateCarrierBrandingFooterBindingModel,
    UpdateCarrierBrandingHeaderBindingModel,
    UpdateCarrierBrandingIconBindingModel,
    UpdateCarrierBrandingSystemMessagesBindingModel,
    UpdateCarrierBrandingTermsBindingModel,
    UpdateLeadAdminBindingModel,
    UploadReportDTO,
    ViewMetricsDailyTotalsParams,
    ViewMetricsTotalsParams,
    WhiteListIpAddressDTO
} from "../types";
import {AuditTrailTypes} from "../util/AuditTrail";
import AuthAPI from "./AuthAPI";
import BaseAPI from "./BaseAPI";
import {BrandingElement, BrandingNameSearchResult} from "../BrandingTypes";

export default class AdminAPI extends BaseAPI {
    static async get_min_carriers(token: string): Promise<CarrierIdGuidNameDTO[]> {
        return (await axios.get('api/admin/carriers/minimal', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async leads_search(token: string, params: LeadSearchBindingModel, page?: number): Promise<LeadsPagingDTO> {
        return (await axios.post(`api/admin/leads-search${page ? `/pages/${page}` : ''}`, params, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async brokers_search(token: string, params: BrokerSearchBindingModel, page?: number): Promise<BrokersPagingDTO> {
        return (await axios.post(`api/admin/broker-search${page ? `/pages/${page}` : ''}`, params, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async get_lead_by_guid(guid: string, token: string): Promise<LeadDetailAdminDTO> {
        return (await axios.get(`api/admin/leads/${guid}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async get_broker_by_guid(guid: string, token: string): Promise<BrokerUserDTO> {
        return (await axios.get(`api/admin/brokers/${guid}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async get_carrier(guid: string, token: string): Promise<CarrierDTO> {
        return (await axios.get(`api/admin/carriers/${guid}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async get_lead_audit_trail(guid: string, token: string): Promise<LeadAuditTrailDTO[]> {
        return (await axios.get(`api/admin/leads/${guid}/audit/3`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async get_broker_audit_trail(guid: string, type_id: AuditTrailTypes, token: string): Promise<BrokerAuditTrailDTO[]> {
        return (await axios.get(`api/admin/brokers/${guid}/audit/${type_id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async update_lead(update_lead_data: UpdateLeadAdminBindingModel, lead_guid: string, token: string) {
        const form_data = new URLSearchParams();
        form_data.set('firstName', update_lead_data.firstName);
        form_data.set('LastName', update_lead_data.LastName);
        form_data.set('preferredContactMethodId', `${update_lead_data.preferredContactMethodId}`);
        form_data.set('email', update_lead_data.email);
        form_data.set('CellPhone', update_lead_data.CellPhone);
        form_data.set('Phone', update_lead_data.Phone);
        form_data.set('ZipCode', update_lead_data.ZipCode);
        form_data.set('City', update_lead_data.City);
        form_data.set('statusId', `${update_lead_data.statusId || ''}`);
        form_data.set('note', update_lead_data.note);
        form_data.set('timeStamp', update_lead_data.timeStamp);
        form_data.set('PostalCodeId', update_lead_data.PostalCodeId || '');
        form_data.set('justificationId', `${update_lead_data.justificationId || ''}`);
        form_data.set('justificationNote', update_lead_data.justificationNote || '');
        form_data.set('CarrierGuid', update_lead_data.CarrierGuid);
        for (let i = 0; i < update_lead_data.customFieldValues.length; i++) {
            form_data.set(`customFieldValues[${i}][customFieldId]`, `${update_lead_data.customFieldValues[i].customFieldId}`);
            form_data.set(`customFieldValues[${i}][value]`, `${update_lead_data.customFieldValues[i].value}`);
        }
        await axios.put(`api/admin/leads/${lead_guid}`, form_data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    static async insert_lead(update_lead_data: CreateLeadAdminBindingModel, token: string) {
        const form_data = new URLSearchParams();
        form_data.set('firstName', update_lead_data.firstName);
        form_data.set('lastName', update_lead_data.LastName);
        form_data.set('preferredContactMethodId', `${update_lead_data.preferredContactMethodId}`);
        form_data.set('email', update_lead_data.email);
        form_data.set('cellPhone', update_lead_data.cellPhone);
        form_data.set('phone', update_lead_data.phone);
        form_data.set('zipCode', update_lead_data.zipCode);
        form_data.set('city', update_lead_data.city);
        form_data.set('postalCodeId', update_lead_data.postalCodeId || '');
        form_data.set('carrierGuid', update_lead_data.carrierGuid);
        if (update_lead_data.brokerToBeAssignedLead) {
            form_data.set('brokerToBeAssignedLead[brokerId]', `${update_lead_data.brokerToBeAssignedLead.brokerId}`);
            form_data.set('brokerToBeAssignedLead[email]', update_lead_data.brokerToBeAssignedLead.email);
            form_data.set('brokerToBeAssignedLead[fullName]', update_lead_data.brokerToBeAssignedLead.fullName);

        } else {
            form_data.set('brokerToBeAssignedLead[brokerId]', '0');
            form_data.set('brokerToBeAssignedLead[email]', '');
            form_data.set('brokerToBeAssignedLead[fullName]', '');
        }
        for (let i = 0; i < update_lead_data.customFieldValues.length; i++) {
            form_data.set(`customFieldValues[${i}][customFieldId]`, `${update_lead_data.customFieldValues[i].customFieldId}`);
            form_data.set(`customFieldValues[${i}][value]`, `${update_lead_data.customFieldValues[i].value}`);
        }
        await axios.put(`api/admin/leads`, form_data, AuthAPI.bearer_token(token));
    }

    static async assign_lead_to_next_eligible_broker(assign_model: AssignToNextEligibleBrokerBindingModel, token: string): Promise<string> {
        return (await axios.post('api/admin/assign-lead-to-next-eligible-broker', assign_model, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async get_search_assign_brokers(search_model: BrokerSearchAssignBindingModel, token: string): Promise<BrokerMinDTO[]> {
        const form_data = new URLSearchParams();
        form_data.set('leadGuid', search_model.leadGuid);
        form_data.set('search', search_model.search);
        return (await axios.post('api/admin/brokers/search', form_data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async get_search_new_assign_brokers(search_model: SearchCarrierBrokerBindingModel, token: string): Promise<BrokerMinDTO[]> {
        const form_data = new URLSearchParams();
        form_data.set('carrierGuid', search_model.carrierGuid);
        form_data.set('search', search_model.search);
        return (await axios.post('api/admin/brokers/search-by-carrier', form_data, AuthAPI.bearer_token(token))).data;
    }

    static async assign_broker(assign_model: AssignBrokerBindingModel, token: string) {
        const form_data = new URLSearchParams();
        form_data.set('leadGuid', assign_model.leadGuid);
        form_data.set('brokerId', `${assign_model.brokerId}`);
        form_data.set('timeStamp', assign_model.timeStamp);
        await axios.post('api/admin/leads/assign', form_data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    static async upload_report(file: File, role: string, token: string, carrier_guid: string): Promise<UploadReportDTO> {
        const form_data = new FormData();
        form_data.append('data', file);
        return (await axios.post(`api/admin/upload/carriers/${carrier_guid}/${role.toLowerCase()}/report`, form_data, AuthAPI.bearer_token(token))).data;
    }

    static async add_do_not_allow_record(record: DoNotAllowRecordDTO, token: string): Promise<DoNotAllowRecordDTO> {
        return (await axios.post('api/admin/add-do-not-allow-record', record, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async upload_insert(file: File, role: string, token: string, carrier_guid: string): Promise<UploadReportDTO> {
        const form_data = new FormData();
        form_data.append('data', file);
        return (await axios.post(`api/admin/upload/carriers/${carrier_guid}/${role.toLowerCase()}/insert`, form_data, AuthAPI.bearer_token(token))).data;

    }

    static async add_whitelist_ip_address(whitelist: WhiteListIpAddressDTO, token: string): Promise<WhiteListIpAddressDTO> {
        return (await axios.post('api/admin/add-white-list-ip-address', whitelist, AuthAPI.bearer_token(token))).data;
    }

    static async hide_metrics_export_record(id: number, token: string): Promise<boolean> {
        return (await axios.put(`api/admin/hide-metrics-export-record/${id}`, null, AuthAPI.bearer_token(token))).data;
    }

    static async get_min_brokers_by_email(carrier_guid: string, broker_email: string, token: string): Promise<BrokerMinDTO[]> {
        const params = new URLSearchParams();
        params.append('brokerEmail', broker_email);
        return (await axios.get(`api/admin/carriers/${carrier_guid}/brokers/min?${params}`, AuthAPI.bearer_token(token))).data;
    }

    static async display_non_broker_users(token: string): Promise<NonBrokerUserDTO[]> {
        return (await axios.post('api/admin/displayNonBrokerUsers', {
            Guid: null,
            Name: null
        }, AuthAPI.bearer_token(token))).data;
    }

    static async update_non_broker_users_statuses(updated_users: NonBrokerUserDTO[], token: string): Promise<string> {
        const params = new URLSearchParams();
        for (let i = 0; i < updated_users.length; i++) {
            const user = updated_users[i];
            params.append(`nonBrokerUsers[${i}][carrier]`, user.carrier || '');
            params.append(`nonBrokerUsers[${i}][carrierGuid]`, user.carrierGuid || '');
            params.append(`nonBrokerUsers[${i}][userId]`, user.userId);
            params.append(`nonBrokerUsers[${i}][firstName]`, user.firstName);
            params.append(`nonBrokerUsers[${i}][lastName]`, user.lastName);
            params.append(`nonBrokerUsers[${i}][active]`, `${user.active}`);
            params.append(`nonBrokerUsers[${i}][email]`, user.email);
            params.append(`nonBrokerUsers[${i}][userName]`, user.userName);
            params.append(`nonBrokerUsers[${i}][phoneNumber]`, user.phoneNumber);
            params.append(`nonBrokerUsers[${i}][userType]`, user.userType);
            params.append(`nonBrokerUsers[${i}][userTypeId]`, `${user.userTypeId}`);
            params.append(`nonBrokerUsers[${i}][joinDateUTC]`, user.joinDateUTC);
            params.append(`nonBrokerUsers[${i}][timeZone]`, user.timeZone);
        }
        return (await axios.put(`api/admin/updateNonBrokerUsersStatuses`, params, AuthAPI.bearer_token(token))).data;
    }

    static async hide_deactivate_user_record(user_id: string, token: string): Promise<boolean> {
        return (await axios.put(`api/admin/hide-deactivate-user-record/${user_id}`, null, AuthAPI.bearer_token(token))).data;
    }

    static async update_non_broker_user(model: NonBrokerUserDTO, token: string): Promise<string> {
        const params = new URLSearchParams();
        params.set('carrier', model.carrier || '');
        params.set('carrierGuid', model.carrierGuid || '');
        params.append('userId', model.userId);
        params.append('firstName', model.firstName);
        params.append('lastName', model.lastName);
        params.append('active', `${model.active}`);
        params.append('email', model.email);
        params.append('userName', model.userName);
        params.append('phoneNumber', model.phoneNumber);
        params.append('userType', model.userType);
        params.append('userTypeId', `${model.userTypeId}`);
        params.append('joinDateUTC', model.joinDateUTC);
        params.append('timeZone', model.timeZone);
        return (await axios.post('api/admin/updateNonBrokerUser', params, AuthAPI.bearer_token(token))).data;
    }

    static async add_hidden_user_back(model: CreateCarrierUserBindingModel, token: string): Promise<CreateCarrierUserBindingModel> {
        return (await axios.post('api/admin/add-hidden-user-back', model, AuthAPI.bearer_token(token))).data;
    }

    static async get_broker_basefield_tooltips(carrier_guid: string, token: string): Promise<CarrierBrokerBaseFieldTooltipDTO[]> {
        return (await axios.get(`api/admin/brokers/broker-base-field-tooltips/${carrier_guid}`, AuthAPI.bearer_token(token))).data;
    }

    static async create_admin_user(model: CreateUserBindingModel, token: string): Promise<{ userId: string }> {
        return (await axios.post('api/admin/create-admin-user', model, AuthAPI.bearer_token(token))).data;
    }

    static async create_carrier_user(model: CreateCarrierUserBindingModel, token: string): Promise<{ userId: string }> {
        return (await axios.post('api/admin/create-carrier-user', model, AuthAPI.bearer_token(token))).data;
    }

    static async update_user_type(user_id: string, user_type_id: string, token: string) {
        await axios.put(`api/admin/updateUserType/${user_id}/${user_type_id}`, null, AuthAPI.bearer_token(token));
    }

    static async create_broker(model: CreateAdminBrokerBindingModel, token: string) {
        await axios.post(`api/admin/brokers`, model, AuthAPI.bearer_token(token));
    }

    static async associate_broker(broker_guid: string, model: BrokerAssocationByAdminBindingModel, token: string) {
        await axios.put(`api/admin/brokers/${broker_guid}/associate`, model, AuthAPI.bearer_token(token));
    }

    static async get_metrics_ui_totals(totals_params: ViewMetricsTotalsParams, token: string): Promise<AdminCarrierMetricsUICurrentDTO> {
        const {carrier_guid} = totals_params;
        const params = AdminAPI.view_metrics_search_params(totals_params);
        return (await axios.get(`api/admin/metrics-ui/carriers/${carrier_guid}/totals?${params}`, AuthAPI.bearer_token(token))).data;
    }

    static async get_metrics_ui_daily_totals(daily_params: ViewMetricsDailyTotalsParams, token: string): Promise<MetricsUIDailyDTO> {
        const {carrier_guid, page} = daily_params;
        const params = AdminAPI.view_metrics_search_params(daily_params);
        return (await axios.get(`api/admin/metrics-ui/carriers/${carrier_guid}/daily-totals/pages/${page}?${params}`, AuthAPI.bearer_token(token))).data;
    }

    static async get_average_response_time(total_params: ViewMetricsTotalsParams, access_token: string): Promise<AdminPerformanceSummaryDTO> {
        const {carrier_guid} = total_params;
        const params = AdminAPI.view_metrics_search_params(total_params);
        return (await axios.get(`api/admin/carrier-average-response-time/${carrier_guid}?${params}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async check_broker(token: string, carrier_guid: string, broker_email: string): Promise<BrokerRegistrationSearchDTO> {
        const params = new URLSearchParams();
        params.append('brokerEmail', broker_email);
        return (await axios.get(`/api/admin/carriers/${carrier_guid}/brokers/registration/?${params}`, AuthAPI.bearer_token(token))).data;
    }

    static async brokers_info(token: string, carrier_guid: string): Promise<BrokersInfoDTO> {
        return (await axios.get(`/api/admin/carriers/${carrier_guid}/brokers/info`, AuthAPI.bearer_token(token))).data;
    }

    static async broker_reinvite(token: string, broker: BrokerSearchDTO) {
        const params = new URLSearchParams();
        params.append('carrierGuid', broker.carrierGuid);
        params.append('brokerGuid', broker.brokerGuid);
        await axios.post(`api/admin/broker-reinvite`, params, AuthAPI.bearer_token(token));
    }

    static async get_broker_lead_status_log(params: AdminBrokerLeadStatusLogParams, access_token: string): Promise<LeadStatusLogPagingDTO> {
        const {broker_guid, carrier_guid, from, to} = params;
        const url_params = new URLSearchParams();
        url_params.append('carrierGuid', carrier_guid);
        url_params.append('from', `${from}`);
        url_params.append('to', `${to}`);
        return (await axios.get(`api/admin/brokers/${broker_guid}/lead-status-log?${url_params}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_broker_lead_paging_status_log(params: AdminBrokerLeadStatusPagingLogParams, access_token: string): Promise<LeadStatusLogPagingDTO> {
        const {broker_guid, carrier_guid, from, to, pages} = params;
        const url_params = new URLSearchParams();
        url_params.append('carrierGuid', carrier_guid);
        url_params.append('from', `${from}`);
        url_params.append('to', `${to}`);
        url_params.append('pages', `${pages}`);
        return (await axios.get(`api/admin/brokers/${broker_guid}/lead-status-log/pages/${pages}?${url_params}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_performance_stats(params: PerformanceStats, access_token: string): Promise<BrokerPerformanceSummaryDTO> {
        const {broker_guid, carrier_guid, fromDate, toDate, timezone} = params;
        const url_params = new URLSearchParams();
        url_params.append('brokerGuid', broker_guid);
        url_params.append('carrierGuid', carrier_guid);
        url_params.append('fromDate', `${fromDate}`);
        url_params.append('toDate', `${toDate}`);
        url_params.append('offset', `${Number(moment.tz.zone(timezone.timeZoneName)?.utcOffset(new Date().getTime())) / 60}`);
        return (await axios.get(`api/admin/average-response-time?${url_params}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async update_broker_profile(broker_guid: string, model: BrokerUpdateProfileByAdminBindingModel, access_token: string) {
        const params = new URLSearchParams();
        params.append('firstName', model.firstName);
        params.append('lastName', model.lastName);
        params.append('preferredContactMethodId', `${model.preferredContactMethodId}`);
        params.append('zipCode', model.zipCode);
        model.additionalEmails.forEach((additional_email, index) => {
            params.append(`additionalEmails[${index}][email]`, additional_email.email);
        });
        model.additionalCellPhones.forEach((additional_phone_number, index) => {
            params.append(`additionalCellPhones[${index}][wirelessProviderId]`, `${additional_phone_number.wirelessProviderId}`);
            params.append(`additionalCellPhones[${index}][cellPhone]`, `${additional_phone_number.cellPhone}`);
            params.append(`additionalCellPhones[${index}][wirelessProviderName]`, `${additional_phone_number.wirelessProviderName}`);
            params.append(`additionalCellPhones[${index}][cellPhoneFormatted]`, `${additional_phone_number.cellPhoneFormatted}`);

        });
        params.append('email', model.email);
        params.append('phoneNumber', model.phoneNumber);
        params.append('wirelessProviderId', `${model.wirelessProviderId}`);
        model.carriers.forEach((carrier, carrier_index) => {
            params.append(`carriers[${carrier_index}][carrierGuid]`, carrier.carrierGuid);
            params.append(`carriers[${carrier_index}][statusId]`, `${carrier.statusId}`);
            params.append(`carriers[${carrier_index}][suspendedReasonId]`, `${carrier.suspendedReasonId}`);
            params.append(`carriers[${carrier_index}][note]`, carrier.note);
            params.append(`carriers[${carrier_index}][inactiveNote]`, carrier.inactiveNote);


            carrier.customFieldValues.forEach((custom_field, custom_field_index) => {
                params.append(`carriers[${carrier_index}][customFieldValues][${custom_field_index}][customFieldId]`, `${custom_field.customFieldId}`);
                params.append(`carriers[${carrier_index}][customFieldValues][${custom_field_index}][value]`, custom_field.value);
            });
        });
        return (await axios.put(`api/admin/brokers/${broker_guid}`, params, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_broker_schedule(broker_guid: string, access_token: string): Promise<BrokerDayScheduleBindingModel[]> {
        return (await axios.get(`api/admin/brokers/${broker_guid}/schedule`, AuthAPI.bearer_token(access_token))).data;
    }

    static async set_broker_schedule(broker_guid: string, model: BrokersScheduleBindingModel, access_token: string) {
        await axios.post(`api/admin/brokers/${broker_guid}/schedule`, model, AuthAPI.bearer_token(access_token));
    }

    static async get_broker_vacation(broker_guid: string, access_token: string): Promise<BrokerVacationBindingModel> {
        return (await axios.get(`api/admin/brokers/${broker_guid}/vacation`, AuthAPI.bearer_token(access_token))).data;
    }

    static async set_broker_vacation(broker_guid: string, model: BrokerVacationBindingModel, access_token: string) {
        await axios.post(`api/admin/brokers/${broker_guid}/vacation`, model, AuthAPI.bearer_token(access_token));
    }

    static async set_dont_accept_lead(model: BrokerDoNotAcceptLeadBindingModel, access_token: string) {
        await axios.put('api/admin/dont_accept_lead', model, AuthAPI.bearer_token(access_token));
    }

    static async set_broker_availability(available: boolean, broker_guid: string, access_token: string) {
        const form_data = new URLSearchParams();
        form_data.append('available', available ? '1' : '0');
        await axios.put(`api/admin/brokers/${broker_guid}/broker_available`, form_data, AuthAPI.bearer_token(access_token));
    }

    static async get_broker_master_toggle_switch(broker_guid: string, access_token: string): Promise<boolean> {
        return (await axios.get(`api/admin/brokers/${broker_guid}/master-toggle-switch`, AuthAPI.bearer_token(access_token))).data;
    }

    static async set_broker_manual_override_master_toggle_switch(manual_override_master_toggle_switch: boolean, broker_guid: string, access_token: string) {
        const form_data = new URLSearchParams();
        form_data.append('masterToggleSwitch', manual_override_master_toggle_switch ? '1' : '0');

        await axios.put(`api/admin/brokers/${broker_guid}/master-toggle-switch`, form_data, AuthAPI.bearer_token(access_token));
    }

    static async set_broker_time_zone(timeZoneId: number, broker_guid: string, access_token: string) {
        const form_data = new URLSearchParams();
        form_data.append('timeZoneId', `${timeZoneId}`);

        await axios.put(`api/admin/brokers/${broker_guid}/time-zone`, form_data, AuthAPI.bearer_token(access_token));
    }

    static async get_lead_statuses_for_client_configurator(access_token: string): Promise<LeadStatusForClientConfigDTO[]> {
        return (await axios.get('/api/admin/get-lead-status-client-configurator', AuthAPI.bearer_token(access_token))).data;
    }

    static async get_custom_field_types(access_token: string): Promise<CustomFieldTypeDTO[]> {
        return (await axios.get('/api/admin/get-custom-field-types', AuthAPI.bearer_token(access_token))).data;
    }

    static async search_branding_header_names(search: string, access_token: string): Promise<BrandingNameSearchResult[]> {
        return (await axios.post(`api/admin/branding/search-branding-header-names`, {search}, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_branding_content_html(banner_name: string, access_token: string): Promise<{
        branding_html: string
    }> {
        return (await axios.get(`api/admin/branding/banner-content-html/${banner_name}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async parse_html_to_branding_element(html_text: string, access_token: string): Promise<BrandingElement[]> {
        return (await axios.post('api/admin/branding/parse-html', {html_string: html_text.trim()}, AuthAPI.bearer_token(access_token))).data;
    }

    static async save_branding_document(model: SaveBrandingDocumentBindingModel, access_token: string) {
        await axios.post('api/admin/branding/save-branding-document', model, AuthAPI.bearer_token(access_token));
    }

    static async list_image_library_images(access_token: string): Promise<string[]> {
        return (await axios.get('api/admin/image-lib/list', AuthAPI.bearer_token(access_token))).data;
    }

    static async upload_image(image: File, access_token: string) {
        const form_data = new FormData();
        form_data.append('data', image);
        await axios.post('api/admin/image-lib/upload', form_data, AuthAPI.bearer_token(access_token));
    }

    static async update_carrier_branding_terms(model: UpdateCarrierBrandingTermsBindingModel, access_token: string) {
        await axios.post('api/admin/branding/update-terms', model, AuthAPI.bearer_token(access_token));
    }

    static async update_carrier_branding_header(model: UpdateCarrierBrandingHeaderBindingModel, access_token: string) {
        await axios.post('api/admin/branding/update-header', model, AuthAPI.bearer_token(access_token));
    }

    static async get_product_types(access_token: string): Promise<ProductTypeDTO[]> {
        return (await axios.get(`api/admin/get-product-types`, AuthAPI.bearer_token(access_token))).data;
    }

    static async generate_client_script(model: ClientBindingModel, access_token: string) {
        return (await axios.post(`/api/admin/generate-client-script`, model, AuthAPI.bearer_token(access_token)));
    }

    static async update_carrier_branding_footer(model: UpdateCarrierBrandingFooterBindingModel, access_token: string) {
        await axios.post('api/admin/branding/update-footer', model, AuthAPI.bearer_token(access_token));
    }

    static async create_client(model: ClientBindingModel, access_token: string) {
        await axios.post('api/admin/create-client', model, AuthAPI.bearer_token(access_token));
    }

    static async request_new_carrier_id(access_token: string): Promise<CarrierIdGuidBindingModel> {
        return (await axios.get('api/admin/request-new-carrier-id', AuthAPI.bearer_token(access_token))).data as CarrierIdGuidBindingModel;
    }

    static async validate_new_carrier_id_guid(id: number, guid: string, access_token: string) {
        await axios.get(`api/admin/validate-new-carrier-id-guid/${id}/${guid}`, AuthAPI.bearer_token(access_token));
    }

    static async update_carrier_branding_icon(model: UpdateCarrierBrandingIconBindingModel, access_token: string) {
        await axios.post('api/admin/branding/update-icon', model, AuthAPI.bearer_token(access_token));
    }

    static async update_carrier_referral_bulk_upload_config(model: CarrierReferralBulkUploadConfigurationBindingModel, access_token: string) {
        await axios.post('api/admin/referral-bulk-upload/update-config', model, AuthAPI.bearer_token(access_token));
    }

    static async get_carrier_referral_bulk_upload_columns(carrier_guid: string, access_token: string): Promise<ReferralBulkUploadColumn[]> {
        return (await axios.get(`api/admin/referral-bulk-upload/carrier/${carrier_guid}/columns`, AuthAPI.bearer_token(access_token))).data;
    }

    static async upload_new_referral_bulk_upload(carrier_guid: string, file: File, access_token: string) {
        const form_data = new FormData();
        form_data.append('data', file);
        await axios.post(`api/admin/referral-bulk-upload/carriers/${carrier_guid}/new`, form_data, AuthAPI.bearer_token(access_token));
    }

    static async update_carrier_system_messages(model: UpdateCarrierBrandingSystemMessagesBindingModel, access_token: string) {
        await axios.post(`api/admin/branding/update-system-messages`, model, AuthAPI.bearer_token(access_token));
    }

    static async get_system_monitor_data(access_token: string, hours: number): Promise<SystemMonitorMetricData[]> {
        return (await this.authenticatedAxiosInstance(access_token).get<SystemMonitorMetricData[]>(`api/admin/monitor/system?${new URLSearchParams({hours: `${hours}`})}`)).data;
    }

    static async get_system_metrics(access_token: string): Promise<SystemMetric[]> {
        return (await this.authenticatedAxiosInstance(access_token).get<SystemMetric[]>('api/admin/monitor/metrics')).data;
    }

    static async add_system_metric(access_token: string, model: NewSystemMonitorMetricBindingModel): Promise<void> {
        await this.authenticatedAxiosInstance(access_token).post('api/admin/monitor/metrics/metric', model);
    }

    static async get_system_metric_list(access_token: string): Promise<NewSystemMonitorMetricBindingModel[]> {
        return (await this.authenticatedAxiosInstance(access_token).get<NewSystemMonitorMetricBindingModel[]>('api/admin/monitor/metrics/list')).data;
    }

    static async rearrange_system_metrics(access_token: string, rearranges: RearrangeSystemMonitorMetric[]) {
        await this.authenticatedAxiosInstance(access_token).post('api/admin/monitor/metrics/rearrange', {rearranges});
    }

    static async remove_system_metric(access_token: string, metricId: string) {
        await this.authenticatedAxiosInstance(access_token).post('api/admin/monitor/metrics/remove', {metricId});
    }

    static async getExpertBulkUploadCarrierConfiguration(accessToken: string, carrierGuid: string): Promise<ExpertBulkUpload.CarrierConfiguration> {
        return (await this.authenticatedAxiosInstance(accessToken).get<ExpertBulkUpload.CarrierConfiguration>(`api/admin/expert-bulk-upload/carrier/${carrierGuid}/config`)).data;
    }

    static async updateExpertBulkUploadCarrierConfiguration(accessToken: string, model: ExpertBulkUpload.UpdateCarrierConfigEvent): Promise<void> {
        await this.authenticatedAxiosInstance(accessToken).post(`api/admin/expert-bulk-upload/update-carrier-config`, model);
    }

    static async uploadNewExpertBulkUpload(accessToken: string, carrierGuid: string, file: NonNullable<File>) {
        const formData = new FormData();
        formData.append('data', file);
        await BaseAPI.authenticatedAxiosInstance(accessToken).post(`api/admin/expert-bulk-upload/carrier/${carrierGuid}/new`, formData);
    }

    static async get_carrier_justifications(carrier_guid: string, access_token: string): Promise<CarrierJustificationsMinDTO[]> {
        return (await BaseAPI.authenticatedAxiosInstance(access_token).get<CarrierJustificationsMinDTO[]>(`api/admin/carriers/${carrier_guid}/justifications`)).data;
    }

}
