import * as Inputs from '../inputs';
import * as validators from '../util/validators';
import {
    CustomFieldValidation,
    FieldDTO,
    FieldWithSetDTO,
    TextAlignedColorUrlBindingModel,
    UpdateCarrierBrandingHeaderLogoElementBindingModel
} from '../types';
import {TextAlignmentColorForm} from "../components/TextAlignmentColorCard/TextAlignmentColorCard";
import {TextAlignedColorUrl} from "../BrandingTypes";
import {HeaderLogoElementForm, LogoVisibilityTypes} from "../components/HeaderLogoElementCard/HeaderLogoElementCard";

type UpdateFormParams<T extends Record<keyof T, Inputs.InputField>> = {
    value: any;
    key: keyof T;
    form: T;
}

type UpdateFormReturn<T extends Record<keyof T, Inputs.InputField>> = {
    form: T;
    form_valid: boolean;
};

type UpdateCustomFieldsReturn = {
    form: Inputs.CustomField[];
    form_valid: boolean;
};

export default class Forms {

    private static is_form_valid(form: Record<string, Inputs.InputField>): boolean {
        let form_valid = true;
        for (const key in form) {
            form_valid = form_valid && form[key].valid;
        }
        return form_valid;
    }

    private static is_custom_field_form_valid(form: Inputs.CustomField[]): boolean {
        return form.filter(field => !field.valid).length === 0;
    }

    static update_form<T extends Record<keyof T, Inputs.InputField>>({key, value, form}: UpdateFormParams<T>): UpdateFormReturn<T> {
        const updated_form = {
            ...form
        };
        const updated_field = {
            ...updated_form[key]
        };

        updated_field.value = updated_field.filter ? updated_field.filter(value, updated_form) : value;

        updated_field.touched = true;
        updated_field.valid = updated_field.validator ? updated_field.validator(updated_field.value, updated_form) : true;

        updated_form[key] = updated_field;

        const filtered = Forms.filter_and_validate_form(updated_form);

        return {form: filtered.form, form_valid: filtered.form_valid};
    }

    static filter_and_validate_form<T extends Record<keyof T, Inputs.InputField>>(form: T): UpdateFormReturn<T> {
        const updated_form = {
            ...form
        }

        for (const key in form) {
            const updated_field = {
                ...form[key]
            };
            updated_field.value = updated_field.filter ? updated_field.filter(updated_field.value, form) : updated_field.value;
            updated_field.valid = updated_field.validator ? updated_field.validator(updated_field.value, form) : true;
            updated_form[key] = updated_field;
        }

        const form_valid = Forms.is_form_valid(updated_form);

        return {form: updated_form, form_valid};
    }

    static evaluate_custom_fields_dependencies(custom_field: Inputs.CustomField, _index: number, fields: Inputs.CustomField[]): Inputs.CustomField {
        if (!custom_field.field.dependOn) return {...custom_field};
        const field_depending_on = fields.find(field => field.field.id === custom_field.field.dependOn);
        if (!field_depending_on) {
            throw new Error(`The custom field with id ${custom_field.field.id} depends on field with id ${custom_field.field.dependOn} but it could not be found.`);
        }
        const selected_value_texts = new Set(field_depending_on.value.split(',').map(val => val.trim()));
        const field_set_values = (custom_field.field as FieldWithSetDTO).fieldSet.values
            .filter(set_value => selected_value_texts.has(set_value.text))
            .sort((a, b) => a.order - b.order);
        return {
            ...custom_field,
            field: {
                ...(custom_field.field as FieldWithSetDTO),
                fieldSet: {
                    ...(custom_field.field as FieldWithSetDTO).fieldSet,
                    values: field_set_values
                }
            } as FieldWithSetDTO
        };
    }

    static custom_fields_to_initial_fields(fields: FieldDTO[], label_span?: Inputs.LabelSpanType, hide_valid?: boolean): Inputs.CustomField[] {
        const map_to_input = (custom_field: FieldDTO): Inputs.CustomField => {
            return ({
                type: Inputs.FieldDataType.CustomField,
                label: custom_field.label,
                touched: false,
                valid: this.custom_field_initially_valid(custom_field),
                dom_id: `custom-field-${custom_field.id}`,
                name: `custom-field-${custom_field.id}`,
                label_span: label_span,
                value: this.custom_field_initial_value(custom_field),
                field: custom_field,
                hide_valid: hide_valid,
                tooltip: custom_field.additionalInformation && custom_field.additionalInformation.trim().length > 0 ? custom_field.additionalInformation : undefined,
                validator: custom_field.fieldType === 'ZipCodeRange' ? validators.is_valid_zip_code_range : undefined
            });
        };
        return fields.map(map_to_input);
    }

    private static custom_field_initial_value(custom_field: FieldDTO) {
        if (custom_field.defaultValue) return custom_field.defaultValue;
        if (custom_field.useSet) return ((custom_field as FieldWithSetDTO).fieldSet.values[0].value);
        if (custom_field.fieldType === 'Boolean') return "False";
        return '';
    }

    private static custom_field_initially_valid(custom_field: FieldDTO) {
        if (!custom_field.mandatory) return true;
        if (!!custom_field.defaultValue && custom_field.defaultValue.length > 0) return true;
        if (custom_field.useSet) return true;
        return custom_field.fieldType === 'Boolean';
    }

    private static find_custom_validator_error(value: string, customFieldValidations: CustomFieldValidation[]): CustomFieldValidation | undefined {
        return customFieldValidations.find(validation => value.match(validation.pattern) === null);
    }

    static validate_custom_field(updated_field: Inputs.CustomField, force_require?: boolean): {
        valid: boolean,
        error?: CustomFieldValidation
    } {
        const value = updated_field.value;
        const valid = !updated_field.field.mandatory || value.trim().length > 0;
        if (!valid) {
            return {valid: false};
        }

        if (value.trim().length !== 0 || updated_field.field.mandatory || force_require) {
            const error = Forms.find_custom_validator_error(value, updated_field.field.customFieldValidations);
            if (error) {
                return {valid: false, error};
            }
        }
        return {valid: updated_field.validator ? updated_field.validator(updated_field.value, {} as Record<string, Inputs.InputField>) : true};
    }

    static update_custom_fields(index: number, value: string, form: Inputs.CustomField[]): UpdateCustomFieldsReturn {
        const updated_form = [
            ...form
        ];
        const updated_field = {
            ...updated_form[index]
        };

        updated_field.value = value;
        updated_field.touched = true;
        const {valid, error} = Forms.validate_custom_field(updated_field);
        if (!valid) {
            updated_field.valid = false;
        } else {
            if (error) {
                updated_field.error = error.errorMessage;
                updated_field.valid = false;
            } else {
                updated_field.error = undefined;
                updated_field.valid = true;
            }
        }


        updated_form[index] = updated_field;

        const form_valid = Forms.is_custom_field_form_valid(updated_form);
        return {form: updated_form, form_valid};

    }

    private static evaluate_multi_select_value_strings(current_field: Inputs.CustomField, _index: number, form: Inputs.CustomField[]): Inputs.CustomField {
        if (current_field.field.fieldType !== 'MultiSelectValueString') return {...current_field};
        const multi_select_field_id = Number(current_field.field.defaultValue);
        const multi_select_field = form.find(field => field.field.id === multi_select_field_id);
        if (!multi_select_field) {
            return {...current_field};
        }
        const selected_values = new Set(multi_select_field.value.split(',').map(val => Number(val.trim())));
        const selected_custom_field_set_values = (multi_select_field.field as FieldWithSetDTO).fieldSet.values
            .filter(value => selected_values.has(Number(value.value)))
            .sort((a, b) => a.order - b.order);

        return {
            ...current_field,
            value: selected_custom_field_set_values.map(value => value.text).join(", ")
        };
    }

    static validate_custom_fields(form: Inputs.CustomField[]): UpdateCustomFieldsReturn {
        const updated_form = [...form];
        for (let i = 0; i < form.length; i++) {
            const updated_field = {...form[i]};
            const {valid, error} = Forms.validate_custom_field(updated_field);
            if (!valid) {
                updated_field.valid = false;
            } else {
                if (error) {
                    updated_field.error = error.errorMessage;
                    updated_field.valid = false;
                } else {
                    updated_field.error = undefined;
                    updated_field.valid = true;
                }
            }
            updated_form[i] = updated_field;
        }
        const form_valid = Forms.is_custom_field_form_valid(updated_form);
        return {form: updated_form.map(Forms.evaluate_multi_select_value_strings), form_valid};
    }

    static generate_time_id() {
        return `${new Date().getTime().toString(16)}${Math.random().toString(16).substring(2, 10)}`;
    }

    static text_align_forms_to_docs(forms: TextAlignmentColorForm[]) {
        return forms.map(form => Forms.text_align_form_to_doc(form));
    }

    static text_align_form_to_doc(form: TextAlignmentColorForm): TextAlignedColorUrl {
        const text: TextAlignedColorUrl = {
            alignment: form.left_aligned.value ? "left" : "right",
            text: form.text.value,
            lang: form.language.value === "Spanish" ? "es" : "en",
        };
        if (form.use_color.value) {
            text.color = form.color.value.trim().toLowerCase();
        }
        if (form.url.value.trim().toLowerCase().length > 0) {
            text.href = form.url.value.trim().toLowerCase()
        }
        return text;
    };

    static text_align_to_binding_model(text: TextAlignedColorUrl): TextAlignedColorUrlBindingModel {
        return {
            text: text.text,
            left_aligned: text.alignment === 'left',
            color: text.color ?? null,
            href: text.href ?? null,
            lang: text.lang ?? "en",
        }
    };

    static text_align_forms_to_binding_models(forms: TextAlignmentColorForm[]) {
        return Forms.text_align_forms_to_docs(forms).map(text => Forms.text_align_to_binding_model(text));
    }

    static header_logo_elements_to_binding_models(forms: HeaderLogoElementForm[]): UpdateCarrierBrandingHeaderLogoElementBindingModel[] {
        const get_visibility_type = (form: HeaderLogoElementForm): "llp" | "system" | "both" => {
            switch (form.visibility.value.value) {
                case LogoVisibilityTypes.LLP:
                    return "llp";
                case LogoVisibilityTypes.SYSTEM:
                    return "system";
                case LogoVisibilityTypes.SYSTEM_AND_LLP:
                default:
                    return "both";
            }
        }
        const transform_src_value = (form: HeaderLogoElementForm, src: string): string => form.is_image.value ? src.trim().toLowerCase() : src.trim();
        return forms.map(form => ({
            is_image: form.is_image.value,
            src: transform_src_value(form, form.src.value),
            alt: form.alt.value.trim().length > 0 ? form.alt.value.trim() : null,
            target: form.target.value.trim().length > 0 ? form.target.value.trim() : null,
            visibility: get_visibility_type(form),
            ...(form.include_spanish ? {
                ...(form.src_es.value.trim().length > 0 ? {src_es: transform_src_value(form, form.src_es.value)} : {}),
                ...(form.alt_es.value.trim().length > 0 ? {alt_es: form.alt_es.value.trim()} : {}),
            } : {}),
        }))
    }

}


