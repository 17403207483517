import * as action_types from './actionTypes';
import moment from 'moment-timezone';
import {BrokerPreferredContactMethodDTO, LeadPreferredContactMethodDTO, TimezoneDTO} from '../../types';
import AccountActions from './AccountActions';
import {CarrierBrandingDocument} from "../../BrandingTypes";

export class GeneralActions {
    static show_spinning_wheel(text?: string) {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_SHOW_SPINNING_WHEEL, text});
        }
    }

    static hide_spinning_wheel() {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_HIDE_SPINNING_WHEEL});
        };
    }

    static show_error(text: string) {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_SET_ERROR, error: text});
        };
    }

    static clear_error() {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_CLEAR_ERROR});
        };
    }

    static nav_to(to: string) {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_NAV_TO, to})
        };
    }

    static pop_nav() {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_POP_NAV})
        };
    }

    static set_timezones(timezones: TimezoneDTO[], selected_timezone?: TimezoneDTO) {
        return (dispatch: any) => {
            const tz_obj: Record<string, TimezoneDTO> = timezones.reduce((obj: Record<string, TimezoneDTO>, current) => {
                obj[current.timeZoneName] = current;
                return obj;
            }, {});
            dispatch({type: action_types.GENERAL_SET_TIMEZONES, timezones: tz_obj});
            dispatch({
                type: action_types.GENERAL_SELECT_TIMEZONE,
                timezone: selected_timezone ? selected_timezone.timeZoneName : timezones[0].timeZoneName
            });
        };
    }

    static set_selected_timezone(timezone: TimezoneDTO) {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_SELECT_TIMEZONE, timezone: timezone.timeZoneName});
        };
    }

    static set_broker_preferred_contact_methods(broker_preferred_contact_methods: BrokerPreferredContactMethodDTO[]) {
        return (dispatch: any) => {
            const sorted_list = [...broker_preferred_contact_methods].sort((a, b) => a.order - b.order);
            dispatch({
                type: action_types.GENERAL_SET_BROKER_PREFERRED_CONTACT_METHODS,
                broker_preferred_contact_methods: sorted_list
            });
        };
    }

    static set_lead_preferred_contact_methods(lead_preferred_contact_methods: LeadPreferredContactMethodDTO[]) {
        return (dispatch: any) => {
            const sorted_list = [...lead_preferred_contact_methods].sort((a, b) => a.order - b.order);
            dispatch({
                type: action_types.GENERAL_SET_LEAD_PREFERRED_CONTACT_METHODS,
                lead_preferred_contact_methods: sorted_list
            });
        };
    }

    static select_guessed_default_timezone(timezones: TimezoneDTO[]) {
        const timezones_to_ignore = new Set<string>(['LMT', 'EWT', 'EPT']);
        return (dispatch: any) => {
            const user_tz = moment.tz.zone(moment.tz.guess(true));
            let timezone_index = -1;
            if (user_tz) {
                const user_abbrs = new Set([...user_tz.abbrs].filter(abbr => !timezones_to_ignore.has(abbr)));
                for (let i = 0; i < timezones.length && timezone_index < 0; i++) {
                    const zone = moment.tz.zone(timezones[i].timeZoneName);
                    if (!zone) continue;
                    const zone_abbrs = new Set([...zone.abbrs].filter(abbr => !timezones_to_ignore.has(abbr)));
                    if ([...user_abbrs].every(abbr => zone_abbrs.has(abbr))) {
                        timezone_index = i;
                    }
                }
            }
            timezone_index = timezone_index >= 0 ? timezone_index : 0;
            dispatch({type: action_types.GENERAL_SELECT_TIMEZONE, timezone: timezones[timezone_index].timeZoneName});
        };
    }

    static logout_if_unauthorized(error: any) {
        return (dispatch: any) => {
            if (!error || !error.response || !error.response.status) return;
            const status_code = Number(error.response.status);
            if ([401, 419].indexOf(status_code) > -1) {
                dispatch(AccountActions.logout());
            }
        };
    };

    static set_error_from_response(e: any) {
        return (dispatch: any) => {
            if (e.response && e.response.data && e.response.data && e.response.data.message) {
                dispatch(GeneralActions.show_error(e.response.data.message));
            }
        };
    }

    static show_mobile_nav() {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_SET_MOBILE_NAV, show_mobile_nav: true});
        };
    }

    static hide_mobile_nav() {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_SET_MOBILE_NAV, show_mobile_nav: false});
        };
    }

    static show_tooltip(tooltip: string) {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_SET_TOOLTIP, tooltip: tooltip});
        };
    }

    static clear_tooltip() {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_SET_TOOLTIP, tooltip: null});
        };
    }

    static clear_success() {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_SET_SUCCESS, success: null});
        }
    }

    static show_success(success: string, clear_timeout_millis?: number) {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_SET_SUCCESS, success});
            if (clear_timeout_millis) {
                window.setTimeout(() => {
                    dispatch(GeneralActions.clear_success());
                }, clear_timeout_millis);
            }
        };
    }

    static set_carrier_branding(carrier_branding: CarrierBrandingDocument) {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_SET_CARRIER_BRANDING, carrier_branding})
        };
    }

    static clear_carrier_branding() {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_CLEAR_CARRIER_BRANDING})
        };
    }

    static open_image_library() {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_OPEN_IMAGE_LIBRARY});
        };
    }

    static close_image_library() {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_CLOSE_IMAGE_LIBRARY});
        };
    }

    static set_reload_broker_referrals(reload_broker_referrals: boolean) {
        return (dispatch: any) => {
            dispatch({type: action_types.GENERAL_SET_RELOAD_BROKER_REFERRALS, reload_broker_referrals});
        };
    }

}
