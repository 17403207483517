import React from "react";
import { Route, Routes } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import StateType from "../../store/store";
import { Func } from "../../types";
import TwoColumnLayout from "../../hoc/TwoColumnLayout/TwoColumnLayout";
import AccountTimeoutContainer from "../../hoc/AccountTimeoutContainer/AccountTimeoutContainer";

const AdminRouter = React.lazy(() => import('../AdminRouter/AdminRouter'));
const Expired = React.lazy(() => import('../../containers/ExpiredPassword/ExpiredPassword'));
const BrokerRouter = React.lazy(() => import('../BrokerRouter/BrokerRouter'));
const CarrierUserRouter = React.lazy(() => import('../CarrierUserRouter/CarrierUserRouter'));
const ExpertAssociation = React.lazy(() => import('../../containers/ExpertAssociation/ExpertAssociation'));
const SelectProfile = React.lazy(() => import('../../containers/SelectProfile/SelectProfile'));
const PageNotFound = React.lazy(() => import('../../containers/PageNotFound/PageNotFound'));

const map_state_to_props = (state: StateType) => ({
    account: {
        is_admin: state.account.is_admin,
        is_carrier: state.account.is_carrier,
        is_broker: state.account.is_broker,
        is_enter_referrals: state.account.is_enter_referrals
    }
});

const connector = connect(map_state_to_props);

const AccountRouter = ({ account }: ConnectedProps<typeof connector>) => {

    const protected_route = (pred: Func<[], boolean>, path: string, element: React.ReactNode, otherwise?: React.ReactNode) => {
        return pred() ? <Route path={path} element={element} /> : otherwise;
    };
    return (
        <TwoColumnLayout>
            <Routes>
                <Route path={'expired'} element={<React.Suspense fallback={<></>}><Expired /></React.Suspense>} />
                {protected_route((() => account.is_admin), '/admin/*', <AccountTimeoutContainer><AdminRouter /></AccountTimeoutContainer>)}
                {protected_route((() => account.is_broker), '/broker/*', <AccountTimeoutContainer><BrokerRouter /></AccountTimeoutContainer>)}
                {protected_route((() => account.is_broker), '/association', <AccountTimeoutContainer><ExpertAssociation/></AccountTimeoutContainer>)}
                {protected_route((() => account.is_broker), '/select_profile', <AccountTimeoutContainer><SelectProfile/></AccountTimeoutContainer>)}
                {protected_route((() => account.is_carrier), '/admin/*', <AccountTimeoutContainer><CarrierUserRouter /></AccountTimeoutContainer>)}
                <Route path='*' element={<React.Suspense fallback={<></>}><PageNotFound/></React.Suspense>}/>
            </Routes>
        </TwoColumnLayout>
    );
};

export default connector(AccountRouter);
