import { LeadMinimalDTO } from "../types";
import PreferredContactMethodIds from "./PreferredContactMethodIds";

export default class Formatters {

    static format_lead_preferred_contact_method(lead: LeadMinimalDTO) {
        switch (lead.preferredContactMethodId) {
            case PreferredContactMethodIds.PHONE:
                return Formatters.format_phone_number(lead.phone);
            case PreferredContactMethodIds.CELLPHONE:
                return Formatters.format_phone_number(lead.cellphone);
            case PreferredContactMethodIds.EMAIL:
                return lead.email;
            default:
                return '';
        }
    }

    static lead_preferred_contact_method_name(lead: LeadMinimalDTO) {
        switch (lead.preferredContactMethodId) {
            case PreferredContactMethodIds.PHONE:
                return 'Phone';
            case PreferredContactMethodIds.CELLPHONE:
                return 'Cellphone';
            case PreferredContactMethodIds.EMAIL:
                return 'Email';
            default:
                return '';
        }
    }

    static format_phone_number(phone_number: any) {
        if ((typeof phone_number) !== 'string') return phone_number;
        const str = (phone_number as string).replace(/[^\d]/g, '').padEnd(10, '_');
        return `(${str.substring(0, 3)}) ${str.substring(3, 6)}-${str.substring(6)}`;
    }

    private static is_numeric(str: string) {
        return !isNaN(Number(str)) && isFinite(Number(str));
    }
}
