import React from "react";
import { Col, Form } from "react-bootstrap";

import * as Input from '../../../inputs';

type Props = {
    element: Input.RadioField<any>;
    blurred?(): void;
    changed(new_value: any): void;
};

const RadioElement = ({ element, changed, blurred }: Props) => {
    const render_form_control = () => (
        <div>
            {element.options.map((value: any) => (
                <Form.Check
                    type={'radio'}
                    inline={!element.vertical}
                    id={value.dom_id}
                    checked={element.option_to_value_string(element.value) === element.option_to_value_string(value)}
                    label={value.title}
                    name={value.name || value.dom_id}
                    disabled={!!value.disabled}
                    onBlur={blurred}
                    value={element.option_to_value_string(value)}
                    onChange={(event) => changed(element.value_from_option_string(event.target.value, element.options))}
                    key={element.option_to_value_string(value)}
                >
                </Form.Check>
            ))}
        </div>
    )

    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
}

export default RadioElement;
