import * as action_types from './actionTypes';
import {NewNotification, Notification} from "../../types";

export default class NotificationActions {
    static set_show_notification_center(show: boolean) {
        return (dispatch: any) => {
            dispatch({type: action_types.NOTIFICATIONS_SET_SHOW, show: show});
        }
    }

    static push_notification(notification: NewNotification) {
        return (dispatch: any) => {
            dispatch({type: action_types.NOTIFICATIONS_PUSH_NOTIFICATION, notification: notification});
        }
    }

    static mark_notification_read(notification: Notification) {
        return (dispatch: any) => {
            dispatch({type: action_types.NOTIFICATIONS_MARK_READ, id: notification.id})
        }
    }

    static clear_notifications = () => {
        return (dispatch: any) => {
            dispatch({type: action_types.NOTIFICATIONS_CLEAR})
        }
    }

}
