import React from "react";
import {Col, Offcanvas, Row} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {useLocation} from "react-router-dom";
import {GeneralActions} from "../../store/actions/GeneralActions";
import StateType from "../../store/store";
import NavigationItem from "./NavigationItem/NavigationItem";

import * as config from "../../config";
import {Func} from "../../types";
import {ExpertTerm, ReferralTerm} from "../BrandingEngine/BrandingTerm/BrandingTerms";
import styles from "./Navigation.module.scss"

export type NavItem = {
    title: React.ReactNode;
    path: string;
    is_active?: Func<[string], boolean>;
    display_link?: Func<[string], boolean>;
}

const AdminNav: NavItem[] = [
    {
        title: 'Enter Referrals',
        path: '/admin/',
        is_active: path => ['/admin', '/admin/'].indexOf(path) >= 0 || path.startsWith('/admin/leads')
    },
    {
        title: 'Enter Experts',
        path: '/admin/brokers',
        is_active: path => ['/admin/brokers', '/admin/brokers/'].indexOf(path) >= 0 || path.startsWith('/admin/brokers')
    },
    {title: 'Dashboard', path: '/admin/view_metrics'},
    {title: 'Export Data', path: '/admin/metrics'},
    {title: 'Settings', path: '/admin/settings'},
    {title: 'Client Configurator', path: '/admin/manage-clients'},
    {title: 'Manage Users', path: '/admin/manageUsers'},
    {
        title: 'System Monitor',
        path: '/admin/system-monitor',
        display_link: (email) => !!config.PRIVILEGED_ADMINS.find(admin_email => admin_email === email.toLowerCase()),
        is_active: path => path.startsWith('/admin/system-monitor')
    },
    {
        title: 'Do Not Allow Record',
        path: '/admin/addDoNotAllowRecord',
        display_link: (email) => !!config.PRIVILEGED_ADMINS.find(admin_email => admin_email === email.toLowerCase())
    },
    {
        title: 'White List IP Address',
        path: '/admin/whiteListIpAddress',
        display_link: (email) => !!config.PRIVILEGED_ADMINS.find(admin_email => admin_email === email.toLowerCase())
    },
    {
        title: 'Branding Settings',
        path: '/admin/branding',
        display_link: (email) => !!config.PRIVILEGED_ADMINS.find(admin_email => admin_email === email.toLowerCase())
    },
];

const BrokerNav: NavItem[] = [
    {title: <>Manage <ReferralTerm/>s</>, path: '/broker/'},
    {title: 'Dashboard', path: '/broker/view_metrics'},
    {title: 'Export Data', path: '/broker/metrics'},
    {title: 'Profile', path: '/broker/profile'},
    {title: 'Availability Settings', path: '/broker/availability'}
];

const CarrierUserNav: NavItem[] = [
    {
        title: <>Enter <ReferralTerm/>s</>,
        path: '/admin/',
        is_active: path => ['/admin', '/admin/'].indexOf(path) >= 0 || path.startsWith('/admin/leads')
    },
    {
        title: <>Enter <ExpertTerm/>s</>,
        path: '/admin/brokers',
        is_active: path => ['/admin/brokers', '/admin/brokers/'].indexOf(path) >= 0 || path.startsWith('/admin/brokers')
    },
    {title: 'Dashboard', path: '/admin/view_metrics'},
    {title: 'Export Data', path: '/admin/metrics'},
    {title: 'Settings', path: '/admin/settings'}
]

const AdvancedCarrierUserNav: NavItem[] = [
    {
        title: <>Enter <ReferralTerm/>s</>,
        path: '/admin/',
        is_active: path => ['/admin', '/admin/'].indexOf(path) >= 0 || path.startsWith('/admin/leads')
    },
    {
        title: <>Enter <ExpertTerm/>s</>,
        path: '/admin/brokers',
        is_active: path => ['/admin/brokers', '/admin/brokers/'].indexOf(path) >= 0 || path.startsWith('/admin/brokers')
    },
    {title: 'Dashboard', path: '/admin/view_metrics'},
    {title: 'Export Data', path: '/admin/metrics'},
    {title: 'Settings', path: '/admin/settings'},
    {title: 'Manage Users', path: '/admin/manageUsers'}
]

const EnterReferralsUserNav: NavItem[] = [
    {title: 'Enter Referrals', path: 'admin/leads/enter-single-lead'},
    {title: 'Settings', path: 'admin/settings'}
]

const map_state_to_props = (state: StateType) => ({
    state: {
        show_mobile_nav: state.general.show_mobile_nav,
        user_email: state.account.user_data?.email,
        roles: state.account.user_data?.roles
    }
});

const map_dispatch_to_props = (dispatch: any) => ({
    actions: {
        hide_mobile_nav: () => dispatch(GeneralActions.hide_mobile_nav())
    }
});

const connector = connect(map_state_to_props, map_dispatch_to_props);

const Navigation = ({state, actions}: ConnectedProps<typeof connector>) => {
    let nav_items: NavItem[];
    if (!state.roles) {
        nav_items = [];
    } else if (state.roles.find(role => role.toLowerCase() === 'admin')) {
        nav_items = [...AdminNav];
    } else if (state.roles.find(role => role.toLowerCase() === 'carrier-poweruser')) {
        nav_items = [...CarrierUserNav];
    } else if (state.roles.find(role => role.toLowerCase() === 'carrier-advancedpoweruser')) {
        nav_items = [...AdvancedCarrierUserNav]
    } else if (state.roles.find(role => role.toLowerCase() === 'broker')) {
        nav_items = [...BrokerNav];
    } else if (state.roles.find(role => role.toLowerCase() ==='carrier-leads')){
        nav_items = [...EnterReferralsUserNav];
    }
    else {
        nav_items = [];
    }
    const render_nav_items = () => nav_items.filter(nav_item => nav_item.display_link ? nav_item.display_link(state.user_email || '') : true)
        .map((nav_item, index) => <NavigationItem nav_item={nav_item} key={index}/>);
    const location = useLocation();
    const hidden_nav_paths = [
        '/expired',
        '/association',
        '/add-client',
        '/manage-clients/update-terms',
        '/manage-clients/update-header',
        '/manage-clients/update-footer',
        '/manage-clients/update-carrier-icon',
        '/select_profile',
        '/manage-clients/update-referral-bulk-upload',
        '/404',
        '/manage-clients/update-system-messages',
        '/manage-clients/update-expert-bulk-upload',
    ];
    if (hidden_nav_paths.findIndex(path => location.pathname.indexOf(path) >= 0) >= 0) return null;
    return (
        <>
            <Col className={`d-none d-xl-block ${styles.NavigationPane}`} xl={2}>
                <Row className={`${styles.NavigationMargin}`}>
                    <Col className="d-grid gap-2 mt-5 ms-auto me-auto" xl={12}>
                        {render_nav_items()}
                    </Col>
                </Row>
            </Col>
            <Offcanvas className={'d-xl-none'} show={state.show_mobile_nav} onHide={actions.hide_mobile_nav}
                       backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{/*Blank title for close button positioning*/}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className={'d-grid gap-2'}>
                        {render_nav_items()}
                        <NavigationItem nav_item={{path: '/logout', title: 'Log Out'}}/>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
};

export default connector(Navigation);
