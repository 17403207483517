import axios from "axios";
import {
    AdminCarrierMetricsUICurrentDTO,
    AssignBrokerBindingModel,
    AssignToNextEligibleBrokerBindingModel,
    BrokerAssocationByAdminBindingModel,
    BrokerAuditTrailDTO,
    BrokerDayScheduleBindingModel,
    BrokerMinDTO,
    BrokerPerformanceSummaryDTO,
    BrokerRegistrationSearchDTO,
    BrokerSearchAssignBindingModel,
    BrokerSearchBindingModel,
    BrokerSearchDTO,
    BrokersInfoDTO,
    BrokersPagingDTO,
    BrokersScheduleBindingModel,
    BrokerUpdateProfileByCarrierUserBindingModel,
    BrokerUserDTO,
    BrokerVacationBindingModel,
    CarrierBrokerBaseFieldTooltipDTO,
    CarrierBrokerLeadStatusLogParams,
    CarrierBrokerLeadStatusPagingLogParams, CarrierJustificationsMinDTO,
    CarrierMinDTO,
    CarrierPerformanceSummaryDTO,
    CreateAdminBrokerBindingModel,
    CreateCarrierUserBindingModel,
    CreateLeadAdminBindingModel,
    LeadAuditTrailDTO,
    LeadDetailAdminDTO,
    LeadSearchBindingModel,
    LeadsPagingDTO,
    LeadStatusLogPagingDTO,
    MetricsUIDailyDTO,
    NonBrokerUserDTO,
    PerformanceStats, ReferralBulkUploadColumn,
    SearchCarrierBrokerBindingModel,
    UpdateLeadBindingModel,
    UploadReportDTO,
    ViewMetricsDailyTotalsParams,
    ViewMetricsTotalsParams,
} from "../types";
import {AuditTrailTypes} from "../util/AuditTrail";
import AuthAPI from "./AuthAPI";
import BaseAPI from "./BaseAPI";
import moment from "moment-timezone";
import {CarrierBrandingDocument} from "../BrandingTypes";

export default class CarrierAPI extends BaseAPI {
    static async leads_search(token: string, params: LeadSearchBindingModel, page?: number): Promise<LeadsPagingDTO> {
        return (await axios.post(`api/carrier/leads-search${page ? `/pages/${page}` : ''}`, params, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async brokers_info(token: string): Promise<BrokersInfoDTO> {
        return (await axios.get(`api/carrier/brokers/info`, AuthAPI.bearer_token(token))).data;
    }

    static async broker_reinvite(token: string, broker: BrokerSearchDTO) {
        const params = new URLSearchParams();
        params.append('carrierGuid', broker.carrierGuid);
        params.append('brokerGuid', broker.brokerGuid);
        await axios.post(`api/carrier/broker-reinvite`, params, AuthAPI.bearer_token(token));
    }

    static async brokers_search(token: string, params: BrokerSearchBindingModel, page?: number): Promise<BrokersPagingDTO> {
        return (await axios.post(`api/carrier/brokers-search${page ? `/pages/${page}` : ''}`, params, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async upload_report(file: File, role: string, token: string, carrier_guid: string): Promise<UploadReportDTO> {
        const form_data = new FormData();
        form_data.append('data', file);
        return (await axios.post(`api/carrier/upload/${role.toLowerCase()}/report/${carrier_guid}`, form_data, AuthAPI.bearer_token(token))).data;
    }

    static async upload_insert(file: File, role: string, token: string, carrier_guid: string): Promise<UploadReportDTO> {
        const form_data = new FormData();
        form_data.append('data', file);
        return (await axios.post(`api/carrier/upload/${role.toLowerCase()}/insert/${carrier_guid}`, form_data, AuthAPI.bearer_token(token))).data;
    }

    static async assign_lead_to_next_eligible_broker(assign_model: AssignToNextEligibleBrokerBindingModel, token: string): Promise<string> {
        return (await axios.post('api/carrier/assign-lead-to-next-eligible-broker', assign_model, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async get_search_assign_brokers(search_model: BrokerSearchAssignBindingModel, token: string): Promise<BrokerMinDTO[]> {
        const form_data = new URLSearchParams();
        form_data.set('leadGuid', search_model.leadGuid);
        form_data.set('search', search_model.search);
        return (await axios.post('api/carrier/brokers/search', form_data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async get_average_response_time(total_params: ViewMetricsTotalsParams, access_token: string): Promise<CarrierPerformanceSummaryDTO> {
        const {carrier_guid} = total_params;
        const params = CarrierAPI.view_metrics_search_params(total_params);
        return (await axios.get(`api/carrier/carrier-average-response-time/?${params}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_search_new_assign_brokers(search_model: SearchCarrierBrokerBindingModel, token: string): Promise<BrokerMinDTO[]> {
        const form_data = new URLSearchParams();
        form_data.set('carrierGuid', search_model.carrierGuid);
        form_data.set('search', search_model.search);
        return (await axios.post('api/carrier/brokers/search-by-carrier', form_data, AuthAPI.bearer_token(token))).data;
    }

    static async insert_lead(update_lead_data: CreateLeadAdminBindingModel, token: string) {
        const form_data = new URLSearchParams();
        form_data.set('firstName', update_lead_data.firstName);
        form_data.set('lastName', update_lead_data.LastName);
        form_data.set('preferredContactMethodId', `${update_lead_data.preferredContactMethodId}`);
        form_data.set('email', update_lead_data.email);
        form_data.set('cellPhone', update_lead_data.cellPhone);
        form_data.set('phone', update_lead_data.phone);
        form_data.set('zipCode', update_lead_data.zipCode);
        form_data.set('city', update_lead_data.city);
        form_data.set('postalCodeId', update_lead_data.postalCodeId || '');
        form_data.set('carrierGuid', update_lead_data.carrierGuid);
        if (update_lead_data.brokerToBeAssignedLead) {
            form_data.set('brokerToBeAssignedLead[brokerId]', `${update_lead_data.brokerToBeAssignedLead.brokerId}`);
            form_data.set('brokerToBeAssignedLead[email]', update_lead_data.brokerToBeAssignedLead.email);
            form_data.set('brokerToBeAssignedLead[fullName]', update_lead_data.brokerToBeAssignedLead.fullName);

        } else {
            form_data.set('brokerToBeAssignedLead[brokerId]', '0');
            form_data.set('brokerToBeAssignedLead[email]', '');
            form_data.set('brokerToBeAssignedLead[fullName]', '');
        }
        for (let i = 0; i < update_lead_data.customFieldValues.length; i++) {
            form_data.set(`customFieldValues[${i}][customFieldId]`, `${update_lead_data.customFieldValues[i].customFieldId}`);
            form_data.set(`customFieldValues[${i}][value]`, `${update_lead_data.customFieldValues[i].value}`);
        }
        await axios.put(`api/carrier/leads`, form_data, AuthAPI.bearer_token(token));
    }

    static async create_broker(model: CreateAdminBrokerBindingModel, token: string) {
        await axios.post(`api/carrier/brokers`, model, AuthAPI.bearer_token(token));
    }

    static async associate_broker(broker_guid: string, model: BrokerAssocationByAdminBindingModel, token: string) {
        await axios.put(`api/carrier/brokers/${broker_guid}/associate`, model, AuthAPI.bearer_token(token));
    }

    static async check_broker(token: string, carrier_guid: string, broker_email: string): Promise<BrokerRegistrationSearchDTO> {
        const params = new URLSearchParams();
        params.append('brokerEmail', broker_email);
        return (await axios.get(`api/carrier/${carrier_guid}/brokers/registration?${params}`, AuthAPI.bearer_token(token))).data;
    }

    static async set_broker_schedule(broker_guid: string, model: BrokersScheduleBindingModel, access_token: string) {
        await axios.post(`api/admin/brokers/${broker_guid}/schedule`, model, AuthAPI.bearer_token(access_token));
    }

    static async set_broker_vacation(broker_guid: string, model: BrokerVacationBindingModel, access_token: string) {
        await axios.post(`api/admin/brokers/${broker_guid}/vacation`, model, AuthAPI.bearer_token(access_token));
    }

    static async get_broker_by_guid(guid: string, token: string): Promise<BrokerUserDTO> {
        return (await axios.get(`api/carrier/brokers/${guid}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async get_broker_audit_trail(guid: string, carrierId: number, type_id: AuditTrailTypes, token: string): Promise<BrokerAuditTrailDTO[]> {
        return (await axios.get(`api/carrier/brokers/${guid}/carrier/${carrierId}/audit/${type_id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async get_broker_schedule(broker_guid: string, access_token: string): Promise<BrokerDayScheduleBindingModel[]> {
        return (await axios.get(`api/carrier/brokers/${broker_guid}/schedules_for_carrier`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_broker_vacation(broker_guid: string, access_token: string): Promise<BrokerVacationBindingModel> {
        return (await axios.get(`api/carrier/brokers/${broker_guid}/vacations_for_carrier`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_metrics_ui_totals(totals_params: ViewMetricsTotalsParams, token: string): Promise<AdminCarrierMetricsUICurrentDTO> {
        const {carrier_guid} = totals_params;
        const params = BaseAPI.view_metrics_search_params(totals_params);
        return (await axios.get(`api/carrier/metrics-ui/carriers/${carrier_guid}/totals?${params}`, AuthAPI.bearer_token(token))).data;
    }

    static async get_metrics_ui_daily_totals(daily_params: ViewMetricsDailyTotalsParams, token: string): Promise<MetricsUIDailyDTO> {
        const {carrier_guid, page} = daily_params;
        let params = BaseAPI.view_metrics_search_params(daily_params);
        return (await axios.get(`api/carrier/metrics-ui/carriers/${carrier_guid}/daily-totals/pages/${page}?${params}`, AuthAPI.bearer_token(token))).data;
    }

    static async get_lead_by_guid(lead_guid: string, access_token: string): Promise<LeadDetailAdminDTO> {
        return (await axios.get(`api/carrier/leads/${lead_guid}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async update_lead(update_data: UpdateLeadBindingModel, lead_guid: string, token: string) {
        const params = BaseAPI.update_lead_binding_model_to_url_search_params(update_data);
        await axios.put(`api/carrier/leads/${lead_guid}`, params, AuthAPI.bearer_token(token));
    }

    static async get_lead_audit_trail(lead_guid: string, carrier_id: number, access_token: string): Promise<LeadAuditTrailDTO[]> {
        return (await axios.get(`api/carrier/leads/${lead_guid}/carrier/${carrier_id}/audit/3`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_broker_lead_status_log(params: CarrierBrokerLeadStatusLogParams, access_token: string): Promise<LeadStatusLogPagingDTO> {
        const {broker_guid, from, to} = params;
        const url_params = new URLSearchParams();
        url_params.append('from', `${from}`);
        url_params.append('to', `${to}`);
        return (await axios.get(`api/carrier/brokers/${broker_guid}/lead-status-log?${url_params}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_broker_performance_stats(params: PerformanceStats, access_token: string): Promise<BrokerPerformanceSummaryDTO> {
        const {broker_guid, carrier_guid, fromDate, toDate, timezone} = params;
        const url_params = new URLSearchParams();
        url_params.append('brokerGuid', broker_guid);
        url_params.append('carrierGuid', carrier_guid);
        url_params.append('fromDate', `${fromDate}`);
        url_params.append('toDate', `${toDate}`);
        url_params.append('offset', `${Number(moment.tz.zone(timezone.timeZoneName)?.utcOffset(new Date().getTime())) / 60}`);
        return (await axios.get(`api/carrier/average-response-time?${url_params}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_broker_lead_paging_status_log(params: CarrierBrokerLeadStatusPagingLogParams, access_token: string): Promise<LeadStatusLogPagingDTO> {
        const {broker_guid, from, to, pages} = params;
        const url_params = new URLSearchParams();
        url_params.append('brokerGuid', broker_guid);
        url_params.append('from', `${from}`);
        url_params.append('to', `${to}`);
        url_params.append('pages', `${pages}`);
        return (await axios.get(`api/carrier/brokers/${broker_guid}/lead-status-log/pages/${pages}?${url_params}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async update_broker_profile(broker_guid: string, model: BrokerUpdateProfileByCarrierUserBindingModel, access_token: string) {
        const params = new URLSearchParams();
        params.append('statusId', `${model.statusId}`);
        params.append('suspendedReasonId', `${model.suspendedReasonId}`);
        params.append('note', model.note);
        params.append('inactiveNote', model.inactiveNote);
        model.customFieldValues.forEach((custom_field, custom_field_index) => {
            params.append(`customFieldValues[${custom_field_index}][customFieldId]`, `${custom_field.customFieldId}`);
            params.append(`customFieldValues[${custom_field_index}][value]`, custom_field.value);
        });
        return (await axios.put(`api/carrier/brokers/${broker_guid}`, params, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_min_brokers_by_email(broker_email: string, token: string): Promise<BrokerMinDTO[]> {
        const params = new URLSearchParams({brokerEmail: broker_email});
        return (await axios.get(`api/carrier/brokers/min?${params}`, AuthAPI.bearer_token(token))).data;
    }

    static async hide_metrics_export_record(id: number, token: string): Promise<boolean> {
        return (await axios.put(`api/carrier/hide-metrics-export-record/${id}`, null, AuthAPI.bearer_token(token))).data;
    }

    static async assign_broker(assign_model: AssignBrokerBindingModel, token: string) {
        const form_data = new URLSearchParams();
        form_data.set('leadGuid', assign_model.leadGuid);
        form_data.set('brokerId', `${assign_model.brokerId}`);
        form_data.set('timeStamp', assign_model.timeStamp);
        await axios.post('api/carrier/leads/assign', form_data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    }

    static async display_non_broker_users(carrier: CarrierMinDTO, token: string): Promise<NonBrokerUserDTO[]> {
        return (await axios.post('api/admin/displayNonBrokerUsers', carrier, AuthAPI.bearer_token(token))).data;
    }

    static async hide_deactivate_user_record(user_id: string, token: string): Promise<boolean> {
        return (await axios.put(`api/carrier/hide-deactivate-user-record/${user_id}`, null, AuthAPI.bearer_token(token))).data;
    }


    static async add_hidden_user_back(model: CreateCarrierUserBindingModel, token: string): Promise<CreateCarrierUserBindingModel> {
        return (await axios.post('api/carrier/add-hidden-user-back', model, AuthAPI.bearer_token(token))).data;
    }

    static async get_broker_basefield_tooltips(token: string): Promise<CarrierBrokerBaseFieldTooltipDTO[]> {
        return (await axios.get(`api/carrier/broker-base-fields-tooltips/`, AuthAPI.bearer_token(token))).data;
    }

    static async get_carrier_branding_configuration(carrier_guid: string): Promise<CarrierBrandingDocument> {
        return (await axios.get(`api/carrier/branding-config/${carrier_guid}`)).data;
    }

    static async list_image_library_images(access_token: string): Promise<string[]> {
        return (await axios.get('api/carrier/image-lib/list', AuthAPI.bearer_token(access_token))).data;
    }

    static async upload_image(image: File, access_token: string) {
        const form_data = new FormData();
        form_data.append('data', image);
        await axios.post('api/carrier/image-lib/upload', form_data, AuthAPI.bearer_token(access_token));
    }

    static async upload_new_referral_bulk_upload(carrier_guid: string, file: File, access_token: string) {
        const form_data = new FormData();
        form_data.append('data', file);
        await axios.post(`api/carrier/referral-bulk-upload/carriers/${carrier_guid}/new`, form_data, AuthAPI.bearer_token(access_token));
    }

    static async get_carrier_referral_bulk_upload_columns(carrier_guid: string, access_token: string): Promise<ReferralBulkUploadColumn[]> {
        return (await axios.get(`api/carrier/referral-bulk-upload/carrier/${carrier_guid}/columns`, AuthAPI.bearer_token(access_token))).data;
    }

    static async getExpertBulkUploadCarrierConfiguration(accessToken: string, carrierGuid: string): Promise<ExpertBulkUpload.CarrierConfiguration> {
        return (await this.authenticatedAxiosInstance(accessToken).get<ExpertBulkUpload.CarrierConfiguration>(`api/carrier/expert-bulk-upload/carrier/${carrierGuid}/config`)).data;
    }

    static async uploadNewExpertBulkUpload(accessToken: string, carrierGuid: string, file: NonNullable<File>) {
        const formData = new FormData();
        formData.append('data', file);
        await BaseAPI.authenticatedAxiosInstance(accessToken).post(`api/carrier/expert-bulk-upload/carrier/${carrierGuid}/new`, formData);
    }

    static async get_carrier_justifications(carrier_guid: string, access_token: string): Promise<CarrierJustificationsMinDTO[]> {
        return (await BaseAPI.authenticatedAxiosInstance(access_token).get<CarrierJustificationsMinDTO[]>(`api/carrier/carriers/${carrier_guid}/justifications`)).data;
    }

}
