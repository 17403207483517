import React, {MouseEventHandler} from "react";
import {Alert} from "react-bootstrap";
import {Variant} from "react-bootstrap/types";

type Props = {
    variant: Variant;
    on_close_click?: MouseEventHandler<HTMLButtonElement>;
    children: React.ReactNode;
};

const NotificationBar = ({children, variant, on_close_click}: Props) => {
    return (
        <Alert
            className={'text-center mb-0'}
            variant={variant}
            onClose={on_close_click}
            dismissible
        >
            {children}
        </Alert>
    )
};

export default NotificationBar;
