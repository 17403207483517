import React from "react";
import { Col, Form } from "react-bootstrap";

import * as Input from '../../../inputs';

type Props = {
    element: Input.TextAreaField;
    blurred?(): void;
    changed(new_value: any): void;
};

const TextAreaElement = ({ element, changed, blurred }: Props) => {
    const render_form_control = () => (
        <Form.Control
            type='text'
            as={'textarea'}
            rows={5}
            name={element.name || element.dom_id}
            placeholder={element.placeholder || ''}
            value={element.value}
            onChange={event => changed(event.target.value)}
            onBlur={blurred}
            isInvalid={!element.valid && element.touched}
            disabled={!!element.disabled}
            isValid={!element.hide_valid && element.valid}
            maxLength={element.max_length}
        />
    );
    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
};

export default TextAreaElement;
