import React, {useEffect} from "react";
import {Col, Form} from "react-bootstrap";
import {AsyncTypeahead} from "react-bootstrap-typeahead";

import * as Input from '../../../inputs';

type Props = {
    element: Input.TypeAheadSingleField<any>;
    blurred?(): void;
    changed(new_value: any): void;
};

const TypeAheadSingleElement = ({element, changed, blurred}: Props) => {

    const render_form_control = () => {
        if (!element.filter_by)
            return (
                <AsyncTypeahead
                    disabled={element.disabled}
                    id={element.dom_id}
                    onBlur={blurred}
                    onChange={changed}
                    isInvalid={element.touched && !element.valid}
                    isValid={!element.hide_valid && element.valid}
                    labelKey={element.option_to_label_string}
                    options={element.options}
                    selected={element.value}
                    isLoading={element.is_loading}
                    useCache={element.use_cache}
                    onSearch={element.on_search}
                    renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
                        <Form.Control
                            {...(inputProps as any)}
                            title={element.title ?? element.name ?? element.label ?? undefined}
                            ref={(input: any) => {
                                inputRef(input);
                                referenceElementRef(input);
                            }}
                        />
                    )}
                />
            );
        return (
            <AsyncTypeahead
                disabled={element.disabled}
                id={element.dom_id}
                onBlur={blurred}
                onChange={changed}
                isInvalid={element.touched && !element.valid}
                isValid={!element.hide_valid && element.valid}
                labelKey={element.option_to_label_string}
                options={element.options}
                selected={element.value}
                isLoading={element.is_loading}
                useCache={element.use_cache}
                onSearch={element.on_search}
                filterBy={element.filter_by}
                renderInput={({inputRef, referenceElementRef, ...inputProps}) => (
                    <Form.Control
                        {...(inputProps as any)}
                        title={element.title ?? element.name ?? element.label ?? undefined}
                        ref={(input: any) => {
                            inputRef(input);
                            referenceElementRef(input);
                        }}
                    />
                )}
            />
        )
    };
    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
};

export default TypeAheadSingleElement;
