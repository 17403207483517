import React from "react";
import {Col, Form} from "react-bootstrap";

import * as Input from '../../../inputs';

type Props = {
    element: Input.SingleSelectField<any>;
    blurred?(): void;
    changed(new_value: any): void;
    form_control_class_name?: string;
};

const SingleSelectElement = ({element, changed, blurred, form_control_class_name}: Props) => {
    const render_form_control = () => (
        <Form.Select
            name={element.name || element.dom_id}
            value={element.option_to_value_string(element.value)}
            disabled={!!element.disabled}
            onChange={event => changed(element.value_from_option_string(event.target.value, element.options))}
            onBlur={blurred}
            className={form_control_class_name}>
            {element.options.map((select_option: any) => (
                <option
                    value={element.option_to_value_string(select_option)}
                    className={element.option_to_option_class ? element.option_to_option_class(select_option) : undefined}
                    key={element.option_to_value_string(select_option)}>
                    {element.option_to_display_string(select_option)}
                </option>
            ))}
        </Form.Select>
    );
    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
};

export default SingleSelectElement;
