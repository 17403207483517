import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import * as Input from "../../../inputs";
import {GeneralActions} from "../../../store/actions/GeneralActions";
import {InputElementProps} from '../InputElement';

import styles from './PasswordElement.module.scss';
import React, {useMemo, useState} from "react";

const map_dispatch_to_props = (dispatch: any) => ({
    actions: {
        show_tooltip: (tooltip: string) => dispatch(GeneralActions.show_tooltip(tooltip))
    }
});

const connector = connect(null, map_dispatch_to_props);

type Props = Omit<InputElementProps, 'element'> & ConnectedProps<typeof connector> & {
    element: Input.PasswordField;
};

const ControlElement = (props: Props & { showPassword: boolean }) => {
    const {
        element,
        changed,
        blurred,
        form_control_class_name,
        showPassword,
    } = props;

    const classNames = useMemo(() => {
        if (!form_control_class_name) return undefined;
        return form_control_class_name.split(/\s+/)
            .map(c => c === 'rounded-pill' ? styles.RoundedPassword : c)
            .join(' ');
    }, [form_control_class_name])

    return (
        <Form.Control
            type={showPassword ? 'text' : 'password'}
            name={element.name || element.dom_id}
            placeholder={element.placeholder || ''}
            value={element.value}
            onChange={event => changed(event.target.value)}
            onBlur={blurred}
            isInvalid={!element.valid && element.touched}
            isValid={!element.hide_valid && element.valid}
            className={classNames}
            disabled={!!element.disabled}
        />
    );
}

type PasswordToggleProps = Props & {
    showPassword: boolean,
    setShowPassword: React.Dispatch<React.SetStateAction<boolean>>
};
const PasswordToggle = (props: PasswordToggleProps) => {
    const {
        showPassword,
        setShowPassword,
        form_control_class_name,
        element: {
            dom_id,
        },
    } = props;

    const classNames = useMemo(() => {
        if (!form_control_class_name) return undefined;
        return form_control_class_name.split(/\s+/)
            .map(c => c === 'rounded-pill' ? styles.RoundedPasswordButton : c)
            .join(' ');
    }, [form_control_class_name])
    return (
        <Button
            type={'button'}
            variant={'secondary'}
            className={classNames}
            onClick={() => setShowPassword(current => !current)}
            title={`${showPassword ? 'Hide' : 'Show'} password`}
            id={`${dom_id}-toggle-password`}
        >
            <i className={`bi bi-eye${(showPassword ? '-slash' : '')}`}></i>
        </Button>
    )
}

const PasswordElementInput = (props: Props) => {
    const {
        element,
        group_className,
        label_className,
        actions,
    } = props;

    const [showPassword, setShowPassword] = useState<boolean>(false);

    if (element.floating_label && (element.label || element.label_node)) {
        return (
            <Row>
                {element.tooltip ? (
                    <Col xs={'auto'} className={'pe-0'}>
                        <span className={styles.TooltipIcon} onClick={() => {
                            if (element.tooltip) actions.show_tooltip(element.tooltip)
                        }}>
                            <i className={'bi bi-info fs-5'}/>
                        </span>
                    </Col>
                ) : null}
                <Col>
                    <Form.Group controlId={element.dom_id} className={group_className}>
                        <InputGroup>
                            <Form.Floating>
                                <ControlElement
                                    showPassword={showPassword}
                                    {...props}
                                />
                                <label htmlFor={element.dom_id}>
                                    {element.label_node ? <>{element.label_node}</> : (element.label ?
                                        <span
                                            dangerouslySetInnerHTML={{__html: `${element.label}`}}/> : null)}

                                </label>
                            </Form.Floating>
                            <PasswordToggle
                                {...props}
                                showPassword={showPassword}
                                setShowPassword={setShowPassword}
                            />
                        </InputGroup>
                        <ElementError {...props} />
                    </Form.Group>
                </Col>
            </Row>
        );
    }

    return (
        <Form.Group as={element.label_span ? Row : 'div'} controlId={element.dom_id} className={group_className}>
            {element.label || element.label_node ?
                <Col xs={12} sm={element.label_span || undefined} className={label_className}>
                    {element.label ? <Form.Label
                            dangerouslySetInnerHTML={{__html: `${element.label}`}}/> :
                        <Form.Label>{element.label_node}</Form.Label>}
                    {element.tooltip ?
                        (<span className={styles.TooltipIcon} onClick={() => {
                            if (element.tooltip) actions.show_tooltip(element.tooltip)
                        }}>
                            <i className={'bi bi-info fs-5'}/>
                        </span>)
                        : null}
                </Col> :
                null}
            <InputGroup>
                <ControlElement
                    showPassword={showPassword}
                    {...props}
                />
                <PasswordToggle
                    {...props}
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                />
            </InputGroup>
            <ElementError {...props} />
        </Form.Group>
    )
};

const ElementError = (props: Props) => {
    const {element} = props;
    if (!element.error) return null;
    return (<div className={'text-danger'}>{element.error}</div>);
};

const PasswordElement = (props: Props) => {
    const {element} = props;
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                <PasswordElementInput {...props} />
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            <PasswordElementInput {...props} />
            {element.bottom_info}
        </>
    );
};

export default connector(PasswordElement);
