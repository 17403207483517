import React, { useState } from "react";
import {Col, Form, ToggleButton, ToggleButtonGroup} from "react-bootstrap";

import * as Input from '../../../inputs';

type Props = {
    element: Input.ToggleButtonField<any>;
    blurred?(): void;
    changed(new_value: any): void;
};

const ToggleButtonElement = ({element, changed, blurred}: Props) => {
    const render_form_control = () => (
        <div>
            <ToggleButtonGroup
                type={element.box_type}
                className={element.button_group_class_name}
                name={element.name || element.dom_id}
            >
                {element.options.map((value: any, index) => {
                    const option_string = element.option_to_value_string(value);
                    const selected = element.box_type === 'radio'
                        ? element.value.length > 0 && element.option_to_value_string(element.value[0]) === option_string
                        : element.value.map(element.option_to_value_string).includes(option_string);
                    const on_click = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                        event.preventDefault();
                        if (element.box_type === 'radio') {
                            changed([value]);
                        } else {
                            if (selected) {
                                // deselect
                                changed(element.value.filter(value => element.option_to_value_string(value) !== option_string));
                            } else {
                                const value_with_option = [...element.value.map(element.option_to_value_string), option_string];
                                changed(element.options.filter(value => value_with_option.filter(selected_val => selected_val === element.option_to_value_string(value)).length > 0));
                            }
                        }
                    };
                    return (
                        <ToggleButton
                            className={element.button_class_name}
                            value={element.option_to_value_string(value)}
                            id={`${element.dom_id}_${option_string}`}
                            checked={selected}
                            variant={
                                selected ?
                                    (element.selected_button_variant ?? 'blue') :
                                    (element.unselected_button_variant ?? 'outline-dark')
                            }
                            name={`${value.name || element.dom_id}_${option_string}`}
                            key={element.option_to_value_string(value)}
                            onClick={on_click}
                        >
                            {(element?.option_to_display_html?.(value)) ?? (element?.option_to_display_string?.(value))}
                        </ToggleButton>
                    )
                })}
            </ToggleButtonGroup>
        </div>
    );

    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
}

export default ToggleButtonElement;
