import moment from "moment-timezone";
import {FieldDTO, Func} from "./types";
import {TimeConstraints} from 'react-datetime';
import React from "react";
import {ButtonVariant} from "react-bootstrap/types";

export enum CustomFieldTypes {
    Integer = 1,
    Boolean = 2,
    Text = 3,
    SingleSelect = 4,
    MultiSelect = 5,
    ZipCodeRange = 6,
    Date = 7,
    MultiSelectValueString = 8,
    DynamicMultiSelect = 9,
    DynamicMultiDeselect = 10,
    LinkToMap = 11,
    TextArea = 12,
    Radio
}

export enum FieldDataType {
    CustomField,
    Text,
    Password,
    Checkbox,
    SingleSelect,
    DateTime,
    PhoneNumber,
    Email,
    Boolean,
    MultiSelect,
    TextArea,
    SingleFileSelect,
    TypeAheadSingle,
    ReadOnlyText,
    TimeRange,
    Radio,
    Color,
    ToggleButton,
    DateTimeRange
}

export enum CustomFieldViewSettings {
    Hidden = 1,
    ReadOnly = 2,
    Editable = 3
}

export enum BrokerBaseFields {
    first_name = 1,
    last_name = 2,
    email = 3,
    phone = 4,
    phone_provider = 5,
    preferred_contact_method = 6,
    zip_code = 7,
}

export type LabelSpanType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | "auto";

export interface InputField {
    type: FieldDataType;
    touched: boolean;
    valid: boolean;
    dom_id: string;
    name?: string;
    label?: string;
    label_node?: React.ReactNode;
    label_span?: LabelSpanType;
    value: any;
    disabled?: boolean;
    hide_valid?: boolean;
    error?: React.ReactNode;
    tooltip?: string;
    bottom_info?: React.ReactNode;
    floating_label?: boolean;
    title?: string;

    filter?: Func<[any, Record<string, InputField>], any>;
    validator?: Func<[any, Record<string, InputField>], boolean>;
}

export interface TextField extends InputField {
    type: FieldDataType.Text;
    placeholder?: string;
    value: string;

    filter?: Func<[string, Record<string, InputField>], string>;
    validator?: Func<[string, Record<string, InputField>], boolean>;
}

export interface PasswordField extends InputField {
    type: FieldDataType.Password;
    placeholder?: string;
    value: string;

    filter?: Func<[string, Record<string, InputField>], string>;
    validator?: Func<[string, Record<string, InputField>], boolean>;
}

export interface CheckboxField extends InputField {
    type: FieldDataType.Checkbox;
    value: boolean;
    box_type: 'checkbox' | 'switch';
    inline?: boolean;
    filter?: undefined;
    validator?: undefined;
    label_span?: undefined;
    read_only?: boolean;
}

export interface SingleSelectField<T> extends InputField {
    type: FieldDataType.SingleSelect;
    value: T;
    options: T[];
    option_to_value_string: Func<[T], string>;
    option_to_display_string: Func<[T], string>;
    option_to_option_class?: Func<[T], string | undefined>;
    value_from_option_string: Func<[string, T[]], T>;
    filter?: undefined;
    validator?: Func<[T, Record<string, InputField>], boolean>;
}

export interface MultiSelectField<T> extends InputField {
    type: FieldDataType.MultiSelect;
    value: T[];
    options: T[];
    set_name: string;
    show_select_all: boolean;
    option_to_value_string: Func<[T], string>;
    option_to_display_string: Func<[T], string>;
    option_to_option_class?: Func<[T], string | undefined>;
    value_from_option_string: Func<[string, T[]], T>;

    filter?: undefined;
    validator?: undefined;
}

export interface TypeAheadSingleField<T> extends InputField {
    type: FieldDataType.TypeAheadSingle,
    value: T[],
    options: T[],
    is_loading: boolean;
    use_cache: boolean;
    option_to_label_string: Func<[T], string>;
    on_search: Func<[string], void>;
    filter_by?: (option: T) => boolean;

    filter?: undefined;
    validator?: undefined;
}


export interface TimeRangeFromTo extends Record<string, DateTimeField> {
    from: DateTimeField;
    to: DateTimeField;
}

export interface TimeRangeField extends InputField {
    type: FieldDataType.TimeRange;
    value: TimeRangeFromTo[];

    filter?: Func<[TimeRangeFromTo[], Record<string, InputField>], TimeRangeFromTo[]>;
    validator?: undefined;
}

export interface DateTimeField extends InputField {
    type: FieldDataType.DateTime;
    value: moment.Moment;
    date_format?: string;
    time_format?: string;
    close_on_select?: boolean;
    time_constraints?: TimeConstraints;
    can_be_empty?: boolean;
    is_valid_date?: Func<[moment.Moment], boolean>;
    filter?: Func<[moment.Moment, Record<string, InputField>], moment.Moment>;
    validator?: Func<[moment.Moment, Record<string, InputField>], boolean>;
}

export interface PhoneNumberField extends InputField {
    type: FieldDataType.PhoneNumber;
    placeholder?: string;
    value: string;

    filter?: Func<[string, Record<string, InputField>], string>;
    validator?: Func<[string, Record<string, InputField>], boolean>;
}

export interface EmailField extends InputField {
    type: FieldDataType.Email;
    placeholder?: string;
    value: string;

    filter?: Func<[string, Record<string, InputField>], string>;
    validator?: Func<[string, Record<string, InputField>], boolean>;
}

export interface BooleanField extends InputField {
    type: FieldDataType.Boolean,
    value: boolean;

    filter?: undefined;
    validator?: undefined;
}

export interface TextAreaField extends InputField {
    type: FieldDataType.TextArea;
    placeholder?: string;
    value: string;
    max_length?: number;

    filter?: Func<[string, Record<string, InputField>], string>;
    validator?: Func<[string, Record<string, InputField>], boolean>;
}

export interface SingleFileSelect extends InputField {
    type: FieldDataType.SingleFileSelect;
    value: File | null;
    accept?: string;
    filter?: undefined;
    validator: Func<[File | null, Record<string, InputField>], boolean>;
}

export enum CustomFieldValidationType {
    UNIQUE = 1,
    EmailRequired = 2,
    LimitedZipCode = 3,
}

export interface CustomField extends InputField {
    type: FieldDataType.CustomField;
    value: string;
    field: FieldDTO;

    filter?: undefined;
}

export interface ReadOnlyText extends InputField {
    type: FieldDataType.ReadOnlyText;
    value: string;
    text_class_name?: string;
    filter?: undefined;
    validator?: undefined;

}

export interface RadioField<T> extends InputField {
    type: FieldDataType.Radio,
    value: T;
    options: T[];
    option_to_value_string: Func<[T], string>;
    value_from_option_string: Func<[string, T[]], T>;
    vertical?: boolean;

    filter?: undefined;
    validator?: Func<[T, Record<string, InputField>], boolean>;
}

export interface ColorField extends InputField {
    type: FieldDataType.Color;
    value: string;
    filter?: undefined;
    validator?: (input: string, form: Record<string, InputField>) => boolean;
}

export interface ToggleButtonField<T> extends InputField {
    type: FieldDataType.ToggleButton,
    value: T[];
    options: T[];
    box_type: 'checkbox' | 'radio';
    option_to_value_string: Func<[T], string>;
    option_to_display_string: (val: T) => string;
    option_to_display_html?: (val: T) => React.ReactNode;
    value_from_option_string: Func<[string, T[]], T>;
    vertical?: boolean;
    button_class_name?: string;
    button_group_class_name?: string;
    selected_button_variant?: ButtonVariant;
    unselected_button_variant?: ButtonVariant;

    filter?: undefined;
    validator?: Func<[T, Record<string, InputField>], boolean>;
}

export enum DateTimeRangeModes {
    DateTime,
    Date,
    Time
}

export type DateTimeRange = {
    from?: moment.Moment;
    to?: moment.Moment;
};

type RelativeTimeButton = {
    button_class_name?: string;
};

export type ExactRelativeTimeOption = RelativeTimeButton & {
    type: 'Exact';
    time: number;
    unit: "h" | "d" | "w" | "M" | "y";
};

export type DynamicRelativeTimeOption = RelativeTimeButton & {
    type: 'Dynamic';
    option: 'Today';
}

export type RelativeTimeRangeOption = ExactRelativeTimeOption | DynamicRelativeTimeOption;

export interface DateTimeRangeField extends InputField {
    type: FieldDataType.DateTimeRange;
    value: DateTimeRange;
    mode: DateTimeRangeModes;
    button_variant?: ButtonVariant;
    allow_open_ended?: boolean;
    allow_clear?: boolean;
    relative_options?: RelativeTimeRangeOption[];
    custom_button_content?: React.ReactNode;
    time_field_class_name?: string;
    is_date_selectable?: (date: moment.Moment) => boolean;
    overlay_placement?: 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start';
    filter?: undefined;
    validator?: (range: DateTimeRange, form: Record<string, InputField>) => boolean;
    read_only?: boolean;
}
