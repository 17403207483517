import {BrokerPreferredContactMethodDTO, LeadPreferredContactMethodDTO, TimezoneDTO} from '../../types';
import * as action_types from '../actions/actionTypes';
import {CarrierBrandingDocument} from "../../BrandingTypes";

export type GeneralState = {
    spinning_wheel: boolean;
    spinning_wheel_text: string | null;
    error: string | null;
    success: string | null;
    target_nav: string[];
    timezones: Record<string, TimezoneDTO>;
    selected_timezone: TimezoneDTO | null;
    broker_preferred_contact_methods: BrokerPreferredContactMethodDTO[];
    lead_preferred_contact_methods: LeadPreferredContactMethodDTO[];
    show_mobile_nav: boolean;
    tooltip: string | null;
    carrier_branding: CarrierBrandingDocument | null;
    show_image_library: boolean;
    reload_broker_referrals: boolean;
};

const initial_state: GeneralState = {
    spinning_wheel: false,
    spinning_wheel_text: null,
    error: null,
    target_nav: [],
    timezones: {},
    selected_timezone: null,
    broker_preferred_contact_methods: [],
    lead_preferred_contact_methods: [],
    show_mobile_nav: false,
    tooltip: null,
    success: null,
    carrier_branding: null,
    show_image_library: false,
    reload_broker_referrals: false,
};

const show_spinning_wheel = (state: GeneralState, action: any): GeneralState => ({
    ...state,
    spinning_wheel: true,
    spinning_wheel_text: action.text || null
});

const hide_spinning_wheel = (state: GeneralState): GeneralState => ({
    ...state,
    spinning_wheel: false,
    spinning_wheel_text: null
});

const set_error = (state: GeneralState, action: any): GeneralState => ({
    ...state,
    error: action.error
});

const clear_error = (state: GeneralState): GeneralState => ({
    ...state,
    error: null
});

const nav_to = (state: GeneralState, action: any): GeneralState => ({
    ...state,
    target_nav: [...state.target_nav, action.to]
});

const pop_nav = (state: GeneralState): GeneralState => ({
    ...state,
    target_nav: state.target_nav.slice(0, Math.min(0, state.target_nav.length - 1))
});

const set_timezones = (state: GeneralState, action: any): GeneralState => ({
    ...state,
    timezones: {...action.timezones}
});

const select_timezone = (state: GeneralState, action: any): GeneralState => ({
    ...state,
    selected_timezone: state.timezones[action.timezone] || null
});

const set_broker_preferred_contact_methods = (state: GeneralState, action: any): GeneralState => ({
    ...state,
    broker_preferred_contact_methods: [...action.broker_preferred_contact_methods]
});

const set_lead_preferred_contact_methods = (state: GeneralState, action: any): GeneralState => ({
    ...state,
    lead_preferred_contact_methods: [...action.lead_preferred_contact_methods]
});

const set_mobile_nav = (state: GeneralState, action: any): GeneralState => ({
    ...state,
    show_mobile_nav: action.show_mobile_nav
});

const set_tooltip = (state: GeneralState, action: any): GeneralState => ({
    ...state,
    tooltip: action.tooltip
});

const set_success = (state: GeneralState, action: any): GeneralState => ({
    ...state,
    success: action.success
});

const set_carrier_branding = (state: GeneralState, action: any): GeneralState => ({
    ...state,
    carrier_branding: action.carrier_branding
});

const clear_carrier_branding = (state: GeneralState): GeneralState => ({
    ...state,
    carrier_branding: null
});

const set_show_image_library = (state: GeneralState, show_image_library: boolean): GeneralState => ({
    ...state,
    show_image_library
});

const set_reload_broker_referrals = (state: GeneralState, reload_broker_referrals: boolean): GeneralState => ({
    ...state,
    reload_broker_referrals,
});

const reducer = (state = initial_state, action: any): GeneralState => {
    switch (action.type) {
        case action_types.GENERAL_SHOW_SPINNING_WHEEL:
            return show_spinning_wheel(state, action);
        case action_types.GENERAL_HIDE_SPINNING_WHEEL:
            return hide_spinning_wheel(state);
        case action_types.GENERAL_SET_ERROR:
            return set_error(state, action);
        case action_types.GENERAL_CLEAR_ERROR:
            return clear_error(state);
        case action_types.GENERAL_NAV_TO:
            return nav_to(state, action);
        case action_types.GENERAL_POP_NAV:
            return pop_nav(state);
        case action_types.GENERAL_SET_TIMEZONES:
            return set_timezones(state, action);
        case action_types.GENERAL_SELECT_TIMEZONE:
            return select_timezone(state, action);
        case action_types.GENERAL_SET_BROKER_PREFERRED_CONTACT_METHODS:
            return set_broker_preferred_contact_methods(state, action);
        case action_types.GENERAL_SET_LEAD_PREFERRED_CONTACT_METHODS:
            return set_lead_preferred_contact_methods(state, action);
        case action_types.GENERAL_SET_MOBILE_NAV:
            return set_mobile_nav(state, action);
        case action_types.GENERAL_SET_TOOLTIP:
            return set_tooltip(state, action);
        case action_types.GENERAL_SET_SUCCESS:
            return set_success(state, action);
        case action_types.GENERAL_SET_CARRIER_BRANDING:
            return set_carrier_branding(state, action);
        case action_types.GENERAL_CLEAR_CARRIER_BRANDING:
            return clear_carrier_branding(state);
        case action_types.GENERAL_OPEN_IMAGE_LIBRARY:
            return set_show_image_library(state, true);
        case action_types.GENERAL_CLOSE_IMAGE_LIBRARY:
            return set_show_image_library(state, false);
        case action_types.GENERAL_SET_RELOAD_BROKER_REFERRALS:
            return set_reload_broker_referrals(state, action.reload_broker_referrals);
        default:
            return state;
    }
};

export default reducer;
