import React from "react";

type Props = {
    children: React.ReactNode;
    show: boolean;
};

/**
 * This is for simple components that should render only if a given condition is met. Do not use this if the condition
 * involves checking if a specific value is defined, as the TypeScript compiler will still believe you are trying to
 * access a nullable value inside the children.
 * @param children The JSX that will render if the given condition is true.
 * @param show A boolean that will control whether the children will render.
 */
const ConditionalComponent = ({children, show}: Props) => {
    if (!show) return null;
    return <>{children}</>;
};

export default ConditionalComponent;
