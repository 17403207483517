import React, {useEffect, useRef} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import * as Input from '../../../inputs';

type Props = {
    element: Input.SingleFileSelect;
    blurred?(): void;
    changed(new_value: any): void;
};

const SingleFileSelectElement = ({element, changed, blurred}: Props) => {
    const ref = useRef<HTMLInputElement>(null);

    const browse_on_click = () => {
        ref?.current?.click();
    }

    useEffect(() => {
        if (element.value !== null) return;
        if (!ref?.current) return;
        ref.current.value = '';
    }, [element.value]);

    const render_form_control = () => (
        <Row>
            <Col xs={'auto'}>
                <Form.Control
                    type='file'
                    name={element.name || element.dom_id}
                    title={element.title}
                    onChange={(event: any) => changed(event.target.files.length >= 0 ? event.target.files[0] : null)}
                    onBlur={blurred}
                    isInvalid={!element.valid && element.touched}
                    disabled={!!element.disabled}
                    isValid={!element.hide_valid && element.valid}
                    ref={ref}/>
            </Col>
            <Col xs={'auto'}>
                <Button
                    type={'button'}
                    variant={'hod-secondary'}
                    className={'rounded-pill'}
                    onClick={browse_on_click}
                >
                    Browse Files
                </Button>
            </Col>
        </Row>
    );
    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };

    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
};

export default SingleFileSelectElement;
