import axios from 'axios';
import {
    BaseUpdateLeadStatusBindingModel,
    BrokerAssociationPageDTO,
    BrokerCarrierLeadsAlertDTO,
    BrokerDayScheduleBindingModel,
    BrokerLeadSearchBindingModel,
    BrokerMetricsUICurrentDTO,
    BrokerNotificationStatusDTO,
    BrokerPerformanceSummaryDTO,
    BrokerRegistrationBindingModel,
    BrokerRegistrationDTO,
    BrokersScheduleBindingModel,
    BrokerTimeZoneBindingModel,
    BrokerUpdateAssociationBindingModel,
    BrokerUpdateProfileBindingModel,
    BrokerVacationBindingModel,
    CarrierBrokerBaseFieldTooltipDTO,
    CarrierJustificationsMinDTO,
    LeadAuditTrailDTO,
    LeadDTO,
    LeadsPagingDTO,
    LeadStatusCountDTO,
    MetricsUIDailyDTO,
    TokenValidation,
    UpdateLeadBindingModel,
    UpdateLeadStatusBindingModel,
    ViewMetricsDailyTotalsParams,
    ViewMetricsTotalsParams
} from '../types';
import AuthAPI from './AuthAPI';
import BaseAPI from "./BaseAPI";

export default class BrokerAPI extends BaseAPI {
    static async set_schedule(token: string, schedule_data: BrokersScheduleBindingModel) {
        await axios.post('api/broker/schedule', schedule_data, AuthAPI.bearer_token(token));
    }

    static async get_schedule(token: string): Promise<BrokerDayScheduleBindingModel[]> {
        return (await axios.get('api/broker/schedule', AuthAPI.bearer_token(token))).data;
    }

    static async set_availability(token: string, availability: boolean) {
        const form_data = new URLSearchParams();
        form_data.set('available', availability ? '1' : '0');

        await axios.put('api/broker/available', form_data, AuthAPI.bearer_token(token));
    }

    static async get_availability(token: string): Promise<boolean> {
        type Availability = { available: 0 | 1 };
        return ((await axios.get('api/broker/available', AuthAPI.bearer_token(token))).data as Availability).available === 1;
    }

    static async set_dont_accept_lead(token: string, status: boolean) {
        const form_data = new URLSearchParams();
        form_data.set('doNotAcceptLead', status ? '1' : '0');

        await axios.put('api/broker/dont_accept_lead', form_data, AuthAPI.bearer_token(token));
    }

    static async get_vacation(token: string): Promise<BrokerVacationBindingModel> {
        return await (await axios.get('api/broker/vacation', AuthAPI.bearer_token(token))).data;
    }

    static async set_vacation(token: string, data: BrokerVacationBindingModel) {
        const form_data = new URLSearchParams();
        form_data.set('from', data.from || '');
        form_data.set('to', data.to || '');
        await axios.post('api/broker/vacation', form_data, AuthAPI.bearer_token(token));
    }

    static async get_master_toggle_switch(token: string): Promise<boolean> {
        return (await axios.get('api/broker/master-toggle-switch', AuthAPI.bearer_token(token))).data;
    }

    static async set_manual_override_master_toggle_switch(token: string, availability: boolean) {
        const form_data = new URLSearchParams();
        form_data.set('MasterToggleSwitch', availability ? '1' : '0');

        await axios.put('api/broker/master-toggle-switch', form_data, AuthAPI.bearer_token(token));
    }

    static async get_time_zone(token: string): Promise<BrokerTimeZoneBindingModel> {
        return (await axios.get('api/broker/time-zone', AuthAPI.bearer_token(token))).data;
    }

    static async set_time_zone(token: string, data: BrokerTimeZoneBindingModel) {
        const form_data = new URLSearchParams();
        form_data.set('timeZoneId', `${data.timeZoneId}`);

        await axios.put('api/broker/time-zone', form_data, AuthAPI.bearer_token(token));
    }

    static async test_sms(access_token: string): Promise<string> {
        return (await axios.get('api/broker/test-sms', AuthAPI.bearer_token(access_token))).data;
    }

    static async base_field_tooltips(access_token: string): Promise<CarrierBrokerBaseFieldTooltipDTO[]> {
        return (await axios.get('api/broker/base-field-tooltips', AuthAPI.bearer_token(access_token))).data;
    }

    static async update_broker_profile(model: BrokerUpdateProfileBindingModel, access_token: string): Promise<BrokerNotificationStatusDTO> {
        const params = new URLSearchParams();
        params.append('firstName', model.firstName);
        params.append('lastName', model.lastName);
        params.append('UserName', model.UserName);
        params.append('preferredContactMethodId', `${model.preferredContactMethodId}`);
        params.append('zipCode', model.zipCode);
        model.additionalEmails.forEach((additional_email, index) => {
            params.append(`additionalEmails[${index}][email]`, additional_email.email);
        });
        model.additionalCellPhones.forEach((additional_phone_number, index) => {
            params.append(`additionalCellPhones[${index}][wirelessProviderId]`, `${additional_phone_number.wirelessProviderId}`);
            params.append(`additionalCellPhones[${index}][cellPhone]`, `${additional_phone_number.cellPhone}`);
        });
        params.append('email', model.email);
        params.append('phoneNumber', model.phoneNumber);
        params.append('wirelessProviderId', `${model.wirelessProviderId}`);
        model.carriers.forEach((carrier, carrier_index) => {
            params.append(`carriers[${carrier_index}][carrierGuid]`, carrier.carrierGuid);
            carrier.customFieldValues.forEach((custom_field, custom_field_index) => {
                params.append(`carriers[${carrier_index}][customFieldValues][${custom_field_index}][customFieldId]`, `${custom_field.customFieldId}`);
                params.append(`carriers[${carrier_index}][customFieldValues][${custom_field_index}][value]`, custom_field.value);
            });
        });
        return (await axios.post('api/broker/profile', params, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_metrics_ui_totals(total_params: ViewMetricsTotalsParams, access_token: string): Promise<BrokerMetricsUICurrentDTO> {
        const {carrier_guid} = total_params;
        const params = BrokerAPI.view_metrics_search_params(total_params);
        return (await axios.get(`api/broker/metrics-ui/carriers/${carrier_guid}/totals?${params}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_metrics_ui_daily_totals(daily_params: ViewMetricsDailyTotalsParams, token: string): Promise<MetricsUIDailyDTO> {
        const {carrier_guid, page} = daily_params;
        const params = BrokerAPI.view_metrics_search_params(daily_params);
        return (await axios.get(`api/broker/metrics-ui/carriers/${carrier_guid}/daily-totals/pages/${page}?${params}`, AuthAPI.bearer_token(token))).data;
    }

    static async get_average_response_time(total_params: ViewMetricsTotalsParams, access_token: string): Promise<BrokerPerformanceSummaryDTO> {
        const {carrier_guid} = total_params;
        const params = BrokerAPI.view_metrics_search_params(total_params);
        return (await axios.get(`api/broker/average-response-time/carriers/${carrier_guid}?${params}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_leads_by_page(params: BrokerLeadSearchBindingModel, page: number, access_token: string): Promise<LeadsPagingDTO> {
        return (await axios.post(`api/broker/carriers/${params.carrierguid}/leads/pages/${page}`, params, AuthAPI.bearer_token(access_token))).data;
    }

    static async getReferralSearchStatusCounts(params: BrokerLeadSearchBindingModel, accessToken: string): Promise<LeadStatusCountDTO[]> {
        return (await BaseAPI.authenticatedAxiosInstance(accessToken).post<LeadStatusCountDTO[]>(`api/broker/carriers/${params.carrierguid}/leads/status-counts`, params)).data;
    }

    static async record_reject_attempt(broker_id: number, lead_guid: string, access_token: string) {
        return await axios.put(`api/broker/record-reject/${broker_id}/${lead_guid}`, null, AuthAPI.bearer_token(access_token));
    }

    static async reject_referral(lead_guid: string, model: BaseUpdateLeadStatusBindingModel, access_token: string) {
        return await axios.put(`api/broker/leads/${lead_guid}/reject`, model, AuthAPI.bearer_token(access_token));
    }

    static async accept_referral(lead_guid: string, model: BaseUpdateLeadStatusBindingModel, access_token: string) {
        return await axios.put(`api/broker/leads/${lead_guid}/accept`, model, AuthAPI.bearer_token(access_token));
    }

    static async get_carrier_justifications(carrier_guid: string, access_token: string): Promise<CarrierJustificationsMinDTO[]> {
        return (await axios.get(`api/broker/carriers/${carrier_guid}/justifications`, AuthAPI.bearer_token(access_token))).data;
    }

    static async update_lead_status(model: UpdateLeadStatusBindingModel, access_token: string) {
        await axios.post('api/broker/leads/status', model, AuthAPI.bearer_token(access_token));
    }

    static async get_lead_by_guid(guid: string, access_token: string): Promise<LeadDTO> {
        return (await axios.get(`api/broker/leads/${guid}`, AuthAPI.bearer_token(access_token))).data;
    }

    static async get_lead_audit_trail(lead_guid: string, access_token: string): Promise<LeadAuditTrailDTO[]> {
        return (await axios.get(`api/broker/leads/${lead_guid}/audit/3`, AuthAPI.bearer_token(access_token))).data;
    }

    static async update_lead(update_lead_data: UpdateLeadBindingModel, lead_guid: string, access_token: string) {
        const params = BaseAPI.update_lead_binding_model_to_url_search_params(update_lead_data);
        await axios.put(`api/broker/leads/${lead_guid}`, params, AuthAPI.bearer_token(access_token));
    }

    static async hide_metrics_export_record(id: number, token: string): Promise<boolean> {
        return (await axios.put(`api/broker/hide-metrics-export-record/${id}`, null, AuthAPI.bearer_token(token))).data;
    }

    static async broker_registration(token: TokenValidation): Promise<BrokerRegistrationDTO> {
        return (await axios.post('api/broker/registration', new URLSearchParams(token))).data;
    }

    static async register_broker(model: BrokerRegistrationBindingModel) {
        await axios.post(`api/broker/register`, new URLSearchParams({
            ...model,
            wirelessProviderId: `${model.wirelessProviderId}`,
            preferredContactMethodId: `${model.preferredContactMethodId}`
        }));
    }

    static async load_broker_association(carrier_guid: string, token: string): Promise<BrokerAssociationPageDTO> {
        return (await axios.get(`api/broker/carriers/${carrier_guid}/association`, AuthAPI.bearer_token(token))).data;
    }

    static async decline_broker_association(carrier_guid: string, token: string) {
        await axios.put(`api/broker/carriers/${carrier_guid}/decline`, null, AuthAPI.bearer_token(token));
    };

    static async associate_broker(model: BrokerUpdateAssociationBindingModel, token: string) {
        const params = new URLSearchParams();
        params.append('carrierGuid', model.carrierGuid);
        for (let i = 0; i < model.customFieldValues.length; i++) {
            params.append(`customFieldValues[${i}][customFieldId]`, `${model.customFieldValues[i].customFieldId}`);
            params.append(`customFieldValues[${i}][value]`, model.customFieldValues[i].value);
        }
        await axios.post('api/broker/associate', params, AuthAPI.bearer_token(token));
    }

    static async leads_notification(access_token: string): Promise<BrokerCarrierLeadsAlertDTO[]> {
        return (await axios.get('api/broker/leads-notification', AuthAPI.bearer_token(access_token))).data;
    }

}
