import React from "react";

import * as Input from '../../inputs';
import {FieldDTO} from "../../types";
import CheckboxElement from "./CheckboxElement/CheckboxElement";
import CustomFieldElement from "./CustomFieldElement/CustomFIeldElement";
import FormGroupElement from "./FormGroupElement/FormGroupElement";
import PasswordElement from './PasswordElement/PasswordElement';

export type InputElementProps = {
    element: Input.InputField;
    changed(new_value: any): void;
    blurred?(): void;
    group_className?: string;
    label_className?: string;
    form_control_class_name?: string;
    custom_field_view_settings_params?: { role: string; action: string };
    custom_field_extra_view_setting?: {
        should_hide?: (custom_field: FieldDTO) => boolean;
        should_be_read_only?: (custom_field: FieldDTO) => boolean;
    };
    mark_field_null?: () => void;
    lang?: 'en' | 'sp';
}

const InputElement = (props: InputElementProps) => {
    if (!props.element) {
        return null;
    }

    const {element, ...otherProps} = props;

    switch (props.element.type) {
        case Input.FieldDataType.Text:
        case Input.FieldDataType.SingleSelect:
        case Input.FieldDataType.DateTime:
        case Input.FieldDataType.PhoneNumber:
        case Input.FieldDataType.Email:
        case Input.FieldDataType.Boolean:
        case Input.FieldDataType.MultiSelect:
        case Input.FieldDataType.SingleFileSelect:
        case Input.FieldDataType.TextArea:
        case Input.FieldDataType.TypeAheadSingle:
        case Input.FieldDataType.ReadOnlyText:
        case Input.FieldDataType.TimeRange:
        case Input.FieldDataType.Radio:
        case Input.FieldDataType.Color:
        case Input.FieldDataType.ToggleButton:
        case Input.FieldDataType.DateTimeRange:
            return <FormGroupElement {...props} />;
        case Input.FieldDataType.Password:
            return <PasswordElement element={element as Input.PasswordField} {...otherProps} />;
        case Input.FieldDataType.Checkbox:
            return <CheckboxElement
                element={props.element as Input.CheckboxField}
                changed={props.changed}
                blurred={props.blurred}
                label_className={props.label_className}
                group_className={props.group_className}/>;
        case Input.FieldDataType.CustomField:
            return <CustomFieldElement
                element={props.element as Input.CustomField}
                changed={props.changed}
                blurred={props.blurred}
                label_className={props.label_className}
                group_className={props.group_className}
                custom_field_view_settings_params={props.custom_field_view_settings_params}
                custom_field_extra_view_setting={props.custom_field_extra_view_setting}
                mark_field_null={props.mark_field_null}
                lang={props.lang}
            />;
        default:
            return null;
    }
};

export default InputElement;
