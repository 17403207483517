import {Notification} from "../../../../types";
import {ListGroup} from "react-bootstrap";
import NotificationItem from "../NotificationItem/NotificationItem";

type Props = {
    notification: Notification
    on_action: (notification: Notification) => void;
};

const NotificationListGroupItem = ({notification, on_action}: Props) => {
    return (
        <>
            <ListGroup.Item onClick={() => on_action(notification)}>
                <NotificationItem notification={notification}/>
            </ListGroup.Item>
        </>
    )
};

export default NotificationListGroupItem;
