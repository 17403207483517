import React from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router";
import Navigation from "../../components/Navigation/Navigation";

type Props = {
    children: React.ReactNode
};

const TwoColumnLayout = ({ children }: Props) => {
    return (
        <Col>
            <Row>
                <Navigation/>
                {children}
            </Row>
        </Col>
    )
};

export default TwoColumnLayout;
