import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {CookiesProvider} from 'react-cookie';
import thunk from 'redux-thunk';
import axios from 'axios';
import {createRoot} from 'react-dom/client';

import App from './containers/App/App';
import reportWebVitals from './reportWebVitals';

import general_reducer from './store/reducers/general';
import account_reducer from './store/reducers/account';
import admin_reducer from './store/reducers/admin';
import broker_reducer from './store/reducers/broker';
import carrier_user_reducer from './store/reducers/carrier_user';
import branding_reducer from './store/reducers/branding';
import notification_reducer from './store/reducers/notifications';

import './styles/main.scss';
import "react-datetime/css/react-datetime.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import 'react-toastify/dist/ReactToastify.css';
import AccountActions from "./store/actions/AccountActions";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const root_reducer = combineReducers({
    general: general_reducer,
    account: account_reducer,
    admin: admin_reducer,
    broker: broker_reducer,
    carrier_user: carrier_user_reducer,
    branding: branding_reducer,
    notification_center: notification_reducer,
});

const composeEnhancers = window.location.hostname === 'localhost' ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;

const store = createStore(root_reducer, composeEnhancers(applyMiddleware(thunk)));

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.interceptors.response.use((response) => {
    if (response.headers['x-sessionexpiresin']) {
        store.dispatch(AccountActions.set_session_expires_in_seconds(Number(response.headers['x-sessionexpiresin'])));
    }
    if (/^.*api\/accounts\/token.*$/i.test(response.request.responseURL)) {
        store.dispatch(AccountActions.set_last_account_action_to_now());
        return response;
    }
    if (!response.config.headers?.Authorization) return response;
    if (store.getState().account.is_guest) return response;
    const excluded_actions = [
        /^.*api\/accounts\/me.*$/i,
        /^.*api\/broker\/leads-notification.*$/i,
        /^.*api\/general\/get-latest-export-files.*$/i,
        /^.*api\/broker\/available.*$/i,
    ];
    if (excluded_actions.some(regex => regex.test(response.request.responseURL))) return response;
    store.dispatch(AccountActions.set_last_account_action_to_now());
    return response;
});

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root')!);


root.render(
    <React.StrictMode>
        <CookiesProvider>
            <BrowserRouter>
                <Provider store={store}>
                    <QueryClientProvider client={queryClient}>
                        <App/>
                    </QueryClientProvider>
                </Provider>
            </BrowserRouter>
        </CookiesProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
