import axios, {AxiosRequestConfig} from "axios";
import {
    UserProfile,
    TokenResponseData,
    IconWithTextRecord,
    ChangePasswordBindingModel,
    ResetPasswordBindingModel,
    ResetValidationPasswordBindingModel,
    BrokerUserDTO,
    CarrierUserDTO, BrokerRegisterValidationPasswordBindingModel, NotificationStatusDTO
} from "../types";
import BaseAPI from "./BaseAPI";

export default class AuthAPI extends BaseAPI {
    static async login(username: string, password: string): Promise<TokenResponseData> {
        const formData = new URLSearchParams();
        formData.append('username', username.trim());
        formData.append('password', password);
        formData.append('grant_type', 'password');
        return (await axios.post('api/token', formData)).data;
    }

    static async me(token: string): Promise<UserProfile | BrokerUserDTO | CarrierUserDTO> {
        return (await axios.get('api/accounts/me', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })).data;
    }

    static async forgot_username(email: string) {
        const form_data = new URLSearchParams();
        form_data.append('email', email);
        await axios.post('api/accounts/forgot-username', form_data);
    }

    static async forgot_password(email: string) {
        const form_data = new URLSearchParams();
        form_data.append('email', email);
        await axios.post('api/accounts/lost-password', form_data);
    }

    static async get_password_validation_list(current: boolean): Promise<IconWithTextRecord[]> {
        return (await axios.get(`api/accounts/password-validation-list/${current}`)).data;
    }

    static async change_password(model: ChangePasswordBindingModel, token: string) {
        const form_data = new URLSearchParams();
        form_data.append('oldPassword', model.oldPassword);
        form_data.append('newPassword', model.newPassword);
        form_data.append('confirmPassword', model.confirmPassword);
        return await axios.post('api/accounts/change-password', form_data, AuthAPI.bearer_token(token));
    }

    static async updated_password_validation_list(model: ChangePasswordBindingModel, token: string): Promise<IconWithTextRecord[]> {
        return (await axios.post('api/accounts/updated-password-validation-list', model, AuthAPI.bearer_token(token))).data;
    };

    static bearer_token(token: string): AxiosRequestConfig {
        return {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };
    }

    static async reset_password(model: ResetPasswordBindingModel) {
        const form_data = new URLSearchParams();
        form_data.append('UserId', model.userId);
        form_data.append('Code', model.code);
        form_data.append('Password', model.password);
        await axios.post('api/accounts/reset-password', model);
    }

    static async updated_reset_password_validation_list(model: ResetValidationPasswordBindingModel): Promise<IconWithTextRecord[]> {
        return (await axios.post('api/accounts/updated-reset-password-validation-list', model)).data;
    }

    static async updated_register_password_validation_list(model: BrokerRegisterValidationPasswordBindingModel): Promise<IconWithTextRecord[]> {
        return (await axios.post('api/accounts/updated-register-password-validation-list', new URLSearchParams(model))).data;
    }

    static async get_status(token: string) : Promise<NotificationStatusDTO>{
        return (await axios.get('api/accounts/me/status', AuthAPI.bearer_token(token))).data;
    }

    static async logout(token: string): Promise<void> {
        await this.authenticatedAxiosInstance(token).get('api/accounts/log-out');
    }

}
