import moment from "moment-timezone";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { Col, InputGroup } from "react-bootstrap";
import DateTime from 'react-datetime';

import * as Input from '../../../inputs';
import ReadOnlyTextElement from "../ReadOnlyTextElement/ReadOnlyTextElement";
import TextElement from "../TextElement/TextElement";
import styles from './DateTimeElement.module.scss';

type Props = {
    element: Input.DateTimeField;
    changed(new_value: any): void;
};

const DateTimeElement = ({ element, changed }: Props) => {
    const dateTimeRef = useRef<DateTime>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [input_state, set_input_state] = useState<string | null>(null);
    const [should_submit, set_should_submit] = useState<boolean>(false);
    const update_from_input_state = () => {
        if (!(element.can_be_empty ?? true)) set_input_state(null);
        
        const value_moment = moment(input_state);
        if (value_moment.isValid()) {
            changed(value_moment);
            set_input_state(null);
        }
    };
    const on_blur = () => {
        update_from_input_state();
    };
    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            if (should_submit) {
                set_should_submit(false);
                return;
            }
            event.preventDefault();
            update_from_input_state();
            set_should_submit(true);
        }
    };
    useEffect(() => {
        if (should_submit) {
            inputRef.current?.dispatchEvent(new KeyboardEvent('keyDown', { key: 'Enter' }))
        }
    }, [should_submit])

    const renderInput = (props: Input.DateTimeField, openCalendar: Function, closeCalendar: Function) => (
        <>
            {
                element.disabled ? <ReadOnlyTextElement element={{value: element.value.format('h:mm A')} as Input.ReadOnlyText}/> :
                <InputGroup onClick={dateTimeRef.current?.state.open ? closeCalendar as MouseEventHandler : openCalendar as MouseEventHandler}>
                    <TextElement
                        element={{ value: input_state !== null ? input_state : props.value.toString() } as Input.TextField}
                        changed={set_input_state}
                        blurred={on_blur}
                        keyDown={onKeyDown}
                        inputRef={inputRef} />
                    <InputGroup.Text className={styles.CalendarButton}><i className="bi bi-calendar3"></i></InputGroup.Text>
                </InputGroup>
            }
        </>
    );

    const render_form_control = () => (
        <DateTime
            value={element.value}
            dateFormat={element.date_format || false}
            timeFormat={element.time_format || false}
            closeOnSelect={!!element.close_on_select}
            onChange={(value) => changed(value)}
            timeConstraints={element.time_constraints}
            isValidDate={element.is_valid_date}
            renderInput={renderInput}
            ref={dateTimeRef}
        />
    );
    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
};

export default DateTimeElement;
