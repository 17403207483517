import React from "react";
import { Image } from 'react-bootstrap';

import styles from './SpinningWheel.module.scss';

type Props = {
    text?: string | null;
};

const SpinningWheel = ({ text }: Props) => {
    return (
        <div className={`${styles.SpinningWheel} position-fixed top-0 bottom-0 start-0 end-0`}>
            <Image
                src="/images/ajax-loader.gif"
                alt='Loading Spinner'
                className={`position-absolute top-0 bottom-0 start-0 end-0 m-auto ${styles.SpinningWheelImage}`} />
            <div className={`${styles.SpinningWheelText}`}>{text || null}</div>
        </div>
    )
};

export default SpinningWheel;
