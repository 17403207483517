import React from "react";
import { Col, Form } from "react-bootstrap";

import * as Input from '../../../inputs';

type Props = {
    element: Input.ReadOnlyText;
    group_className?: string;
    label_className?: string;
};

const ReadOnlyTextElement = ({ element, group_className, label_className }: Props) => {
    const render_form_control = () => (
        <Form.Text id={element.dom_id} className={`text-black${element.text_class_name ? ` ${element.text_class_name}` : ''}`} >{element.value}</Form.Text>
    );
    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
};

export default ReadOnlyTextElement;
