import * as Inputs from '../../../inputs';
import {CustomFieldSetValueDTO, FieldDTO, FieldWithSetDTO} from '../../../types';
import InputElement, {InputElementProps} from '../InputElement';
import {useEffect} from "react";

type Props = {
    element: Inputs.CustomField;
    changed(value: any): void;
    blurred?(): void;
    group_className?: string;
    label_className?: string;
    custom_field_view_settings_params?: { role: string; action: string };
    custom_field_extra_view_setting?: {
        should_hide?: (custom_field: FieldDTO) => boolean;
        should_be_read_only?: (custom_field: FieldDTO) => boolean;
    };
    mark_field_null?: () => void;
    lang?: 'en' | 'sp';
};

const map_to_text_field = (props: Props): InputElementProps => ({
    ...props,
    element: {
        ...props.element,
        type: Inputs.FieldDataType.Text,
    } as Inputs.TextField,
    changed: (value: string) => props.changed(value)
});

const map_to_textarea_field = (props: Props): InputElementProps => ({
    ...props,
    element: {
        ...props.element,
        type: Inputs.FieldDataType.TextArea
    } as Inputs.TextAreaField,
    changed: (value: string) => props.changed(value)
});

const map_to_date_field = (props: Props): InputElementProps => ({
    ...props,
    element: {
        ...props.element,
        type: Inputs.FieldDataType.Text,
        bottom_info: <div>Enter a date (MM/dd/yyyy)</div>
    } as Inputs.TextField,
    changed: (value: string) => props.changed(value)
});

const map_to_boolean_field = (props: Props): InputElementProps => ({
    ...props,
    element: {
        ...props.element,
        type: Inputs.FieldDataType.Boolean,
        value: props.element.value.trim().toLowerCase() === 'true'
    } as Inputs.BooleanField,
    changed: (value: boolean) => props.changed(value ? 'True' : 'False')
});

const map_to_single_select = (props: Props): InputElementProps => {
    const field_with_set = props.element.field as FieldWithSetDTO;
    return {
        ...props,
        element: {
            ...props.element,
            type: Inputs.FieldDataType.SingleSelect,
            options: [...field_with_set.fieldSet.values],
            value: field_with_set.fieldSet.values.find(val => val.value === props.element.value) || '',
            option_to_display_string: option => option.text,
            option_to_value_string: option => option.value,
            value_from_option_string: (value) => field_with_set.fieldSet.values.find(val => val.value === value)
        } as Inputs.SingleSelectField<CustomFieldSetValueDTO>,
        changed: (value: CustomFieldSetValueDTO) => props.changed(value.value)
    }
};

const map_to_multi_select = (props: Props): InputElementProps => {
    const field_with_set = props.element.field as FieldWithSetDTO;
    const selected_values = props.element.value.length > 0 ? props.element.value.split(',').map(val => Number(val)) : [];
    return {
        ...props,
        element: {
            ...props.element,
            type: Inputs.FieldDataType.MultiSelect,
            options: [...field_with_set.fieldSet.values],
            value: field_with_set.fieldSet.values.filter(val => selected_values.filter(val_id => Number(val.value) === val_id).length > 0),
            set_name: field_with_set.fieldSet.name,
            show_select_all: true,
            option_to_display_string: option => option.text,
            option_to_value_string: option => option.value,
            value_from_option_string: (value) => field_with_set.fieldSet.values.find(val => val.value === value)
        } as Inputs.MultiSelectField<CustomFieldSetValueDTO>,
        changed: (value: CustomFieldSetValueDTO[]) => props.changed(value.map(val => val.value).join(','))
    }
}

const map_to_dynamic_multi_deselect = (props: Props): InputElementProps => {
    const field_with_set = props.element.field as FieldWithSetDTO;
    const selected_values = props.element.value.length > 0 ? props.element.value.split(',').map(val => Number(val)) : [];
    return {
        ...props,
        element: {
            ...props.element,
            type: Inputs.FieldDataType.MultiSelect,
            options: [...field_with_set.fieldSet.values],
            value: field_with_set.fieldSet.values.filter(val => selected_values.filter(val_id => Number(val.value) === val_id).length === 0),
            set_name: field_with_set.fieldSet.name,
            show_select_all: false,
            option_to_display_string: option => option.text,
            option_to_value_string: option => option.value,
            value_from_option_string: (value) => field_with_set.fieldSet.values.find(val => val.value === value)
        } as Inputs.MultiSelectField<CustomFieldSetValueDTO>,
        changed: (value: CustomFieldSetValueDTO[]) => props.changed(
            field_with_set.fieldSet.values
                .filter(field_value => !value.find(selected_value => selected_value.value === field_value.value))
                .map(field_value => field_value.value)
                .join(',')
        )
    }
}

const map_to_zip_code_range = (props: Props): InputElementProps => {
    return {
        ...props,
        element: {
            ...props.element,
            type: Inputs.FieldDataType.Text,
            bottom_info: 'Enter individual Zip Codes comma separated or a Zip Code range dash separated.'
        } as Inputs.TextField,
        changed: (value: string) => props.changed(value)
    }
}

const get_text_value = (element: Inputs.CustomField) => {
    if (element.field.fieldType === 'MultiSelect') {
        const selected_values = element.value.split(',').map(val => val.trim());
        return (element.field as FieldWithSetDTO).fieldSet.values.filter(value => !!selected_values.find(val => val === value.value))
            .map(val => val.text).join(', ')
    } else if (element.field.fieldType === 'SingleSelect') {
        return (element.field as FieldWithSetDTO).fieldSet.values.find(value => value.value === element.value)?.text;
    } else {
        return element.value;
    }
};

const CustomFieldElement = (props: Props) => {

    const map_to_input_element_props = (): InputElementProps | null => {
        switch (props.element.field.fieldType) {
            case 'Integer':
            case 'Text':
                return map_to_text_field(props);
            case 'Boolean':
                return map_to_boolean_field(props);
            case 'Date':
                return map_to_date_field(props);
            case 'MultiSelect':
                return map_to_multi_select(props);
            case 'SingleSelect':
                return map_to_single_select(props);
            case 'TextArea':
                return map_to_textarea_field(props);
            case 'DynamicMultiDeselect':
                return map_to_dynamic_multi_deselect(props);
            case 'ZipCodeRange':
                return map_to_zip_code_range(props);
            default:
                return null;
        }
    };
    const render_field = () => {
        const input_element_props = map_to_input_element_props();
        if (!input_element_props) {
            return null;
        }
        return <InputElement {...input_element_props} />
    };
    const read_only_element = () => {
        const read_only_props: InputElementProps = {
            ...props,
            element: {
                ...props.element,
                type: Inputs.FieldDataType.ReadOnlyText,
                value: get_text_value(props.element)?.trim().toLowerCase() === 'true' ? 'Yes' : get_text_value(props.element)?.trim().toLowerCase() === 'false' ? 'No' : get_text_value(props.element)
            } as Inputs.ReadOnlyText
        }
        return <InputElement {...read_only_props} />;
    };

    enum ViewSettingsResult {RENDER_NULL, RENDER_READ_ONLY, RENDER_NORMAL}

    const evaluate_view_settings = (): ViewSettingsResult => {
        // handles view settings, it would be best if all custom fields used this functionality exclusively
        if (props.custom_field_view_settings_params && props.element.field.customFieldViewSettings && props.element.field.customFieldViewSettings.length > 0) {
            const {action: search_action, role: search_role} = props.custom_field_view_settings_params;
            const view_setting = props.element.field.customFieldViewSettings
                .find(setting => setting.actions.trim().toLowerCase() === search_action && setting.role.trim().toLowerCase() === search_role);
            if (view_setting) {
                switch (view_setting.viewSetting) {
                    case Inputs.CustomFieldViewSettings.Hidden:
                        return ViewSettingsResult.RENDER_NULL;
                    case Inputs.CustomFieldViewSettings.ReadOnly:
                        return ViewSettingsResult.RENDER_READ_ONLY;
                    case Inputs.CustomFieldViewSettings.Editable:
                    default:
                        // otherwise, continue as normal
                        break;
                }
            }
        }
        // some custom fields use fields such as notViewableByReferral and viewableByBroker, those can be handled here until that is replaced with the view settings
        // THIS IS A BAD SOLUTION!!! REMOVE ASAP
        if (props.custom_field_extra_view_setting) {
            if (props.custom_field_extra_view_setting.should_hide && props.custom_field_extra_view_setting.should_hide(props.element.field)) {
                return ViewSettingsResult.RENDER_NULL;
            }
            if (props.custom_field_extra_view_setting.should_be_read_only && props.custom_field_extra_view_setting.should_be_read_only(props.element.field)) {
                return ViewSettingsResult.RENDER_READ_ONLY;
            }
        }
        return ViewSettingsResult.RENDER_NORMAL;
    }

    useEffect(() => {
        const view_settings = evaluate_view_settings();
        if (view_settings === ViewSettingsResult.RENDER_NULL || !map_to_input_element_props()) {
            if (props.mark_field_null) props.mark_field_null();
        }
    }, []);

    const view_settings = evaluate_view_settings();
    if (view_settings === ViewSettingsResult.RENDER_NULL) {
        return <></>;
    } else if (view_settings === ViewSettingsResult.RENDER_READ_ONLY) {
        return read_only_element();
    }
    return (
        <>
            {render_field()}
        </>
    );
};


export default CustomFieldElement;
