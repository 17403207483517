export const USER_TOKEN_COOKIE_NAME = "user_token";
export const USER_NAME_COOKIE_NAME = 'username';
export const HIDE_LEAD_PROFILE_CHANGES_BUTTON = false;

export const PRIVILEGED_ADMINS: string[] = [
    'heidi.windbiel@warnerpacific.com'.toLowerCase(),
    'polina.dimitrova@bigwavesystems.com'.toLowerCase(),
    'jose.rivas@helpondemand.com'.toLowerCase(),
    'aaron.lanahan@helpondemand.com'.toLowerCase(),
    'lee.solomon@helpondemand.com'.toLowerCase(),
    'wesley.thompson@helpondemand.com'.toLowerCase(),
    'david.lam@helpondemand.com'.toLowerCase(),
];
