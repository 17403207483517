import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import * as Input from '../../../inputs';
import HorizontalInputElementFormRow from "../../HorizontalInputElementFormRow/HorizontalInputElementFormRow";
import InputElement from "../InputElement";


type Props = {
    element: Input.TimeRangeField;
    blurred?(): void;
    changed(new_value: any): void;
    keyDown?(event: any): void;
    inputRef?: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined;
};


const TimeRangeElement = ({ element, changed, blurred, keyDown, inputRef }: Props) => {
    
    const on_change = (key: string, value: any, index: number) => {
        let time_ranges: Input.TimeRangeFromTo[] = deep_copy(element.value);

        time_ranges[index][key].value = moment(value);
        changed(time_ranges);
    };

    const deep_copy = (time_ranges: Input.TimeRangeFromTo[]) => {
        return time_ranges.map(range => {
            return {
                from: {
                    type: range.from.type,
                    value: moment(range.from.value),
                    touched: range.from.touched,
                    valid: range.from.valid,
                    dom_id: range.from.dom_id,
                    time_format: range.from.time_format,
                    time_constraints: range.from.time_constraints,
                    disabled: element.disabled,
                    can_be_empty: false
                },
                to: {
                    label: range.to.label,
                    label_span: range.to.label_span,
                    type: range.to.type,
                    value: moment(range.to.value),
                    touched: range.to.touched,
                    valid: range.to.valid,
                    dom_id: range.to.dom_id,
                    time_format: range.to.time_format,
                    time_constraints: range.to.time_constraints,
                    disabled: element.disabled,
                    can_be_empty: false
                }
            };
        })
    }

    const add = () => {
        changed([
            ...element.value, 
            {
                from: {
                    type: Input.FieldDataType.DateTime,
                    value: moment('8:00 AM', 'hh:mm a'),
                    time_format: 'hh:mm a',
                    time_constraints: {minutes: {min: 0, max: 59, step: 30}},
                    touched: false,
                    valid: true,
                    close_on_select: true,
                    dom_id: `availability-form-${element.label}-${element.value.length}-from`,
                    disabled: element.disabled
                },
                to: {
                    type: Input.FieldDataType.DateTime,
                    label: 'To:',
                    label_span: 2,
                    value: moment('5:00 PM', 'hh:mm a'),
                    time_format: 'hh:mm a',
                    time_constraints: {minutes: {min: 0, max: 59, step: 30}},
                    touched: false,
                    valid: true,
                    close_on_select: true,
                    dom_id: `availability-form-${element.label}-${element.value.length}-to`,
                    disabled: element.disabled
                }
            }
        ]);
    };

    const remove = (index: number) => {
        let dates = [...element.value];
        dates.splice(index, 1);
        changed(dates);
    };

    const set_closed = () => {
        if (element.value.length > 0) {
            changed([]);
        } else {
            add();
        }
    }

    const render_form_control = () => (
        <Row>
        {
            !element.disabled ? 
            <>
                <Col lg={'10'}>
                    {
                        element.value
                            .map((time_range: Input.TimeRangeFromTo, index) => 
                                {
                                    const keys = Object.keys(time_range);
                                    return <Row key={index}>
                                        {
                                            keys.map(key => {
                                                return (
                                                    <Col xs={'12'} lg={'5'} key={time_range[key].dom_id}>
                                                        <InputElement
                                                            changed={(value) => on_change(key, value, index)}
                                                            element={time_range[key]}/>
                                                    </Col>
                                                );
                                            })
                                        }
                                        {
                                            index > 0 ? 
                                            <Col lg={'2'} key={`${time_range.from.dom_id}-${index}-trash`}>
                                                { index > 0 ? <i onClick={() => remove(index)} className={'bi bi-trash'} style={{color: 'red', fontSize: '20px', fontWeight: 'bold'}}></i> : null }
                                            </Col> : 
                                            <Col lg={'2'} key={`${time_range.from.dom_id}-${index}-plus`}>
                                                {element.value.length > 0 ? <i onClick={add} className={'bi bi-plus'} style={{color: 'green', fontSize: '30px', fontWeight: 'bold'}}></i> : null}
                                            </Col>
                                        }
                                    </Row>
                                }
                            )
                    }
                </Col>
                <Col lg={'2'}>
                    <label><input type={'checkbox'} onChange={set_closed} checked={element.value.length === 0}></input> Closed</label>
                </Col>
            </> : 
            <>
                <Col>
                    {
                        element.value
                            .map((time_range: Input.TimeRangeFromTo, index) => 
                                {
                                    const keys = Object.keys(time_range);
                                    return <Row key={index}>
                                        {
                                            keys.map((key, index2) => {
                                                return (
                                                    <Col key={index2}>
                                                        <InputElement
                                                        changed={(value) => on_change(key, value, index)}
                                                        element={time_range[key]}
                                                        key={time_range[key].dom_id}/>
                                                    </Col>
                                                );
                                            })
                                        }
                                    </Row>
                                }
                            )
                    }
                </Col>
                <Col>
                    <label><input type={'checkbox'} disabled={true} checked={element.value.length === 0}></input> Closed</label>
                </Col>
            </>
        }
        </Row>
        
    );

    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
};

export default TimeRangeElement;
