import React from 'react';
import * as Inputs from '../../inputs';
import InputElement from '../InputElement/InputElement';

type FormKeys<T extends Record<keyof T, Inputs.InputField>> = (keyof T)[];

type Props<T extends Record<keyof T, Inputs.InputField>> = {
    fields: T;
    changed(key: keyof T, value: any): void;
    group_className?: string;
    label_className?: string;
    included_keys?: FormKeys<T>;
    blurred?(key: keyof T): void;
    form_control_class_name?: string;
};

const VerticalInputElementForm = <T extends Record<keyof T, Inputs.InputField>>(props: Props<T>) => {
    const {
        fields,
        changed,
        group_className,
        included_keys,
        blurred,
        label_className,
        form_control_class_name
    } = props;
    const keys = included_keys || (Object.keys(fields) as FormKeys<T>);
    return (
        <>
            {keys.map(key => {
                const field = fields[key];
                return <InputElement
                    key={field.dom_id}
                    changed={(value) => changed(key, value)}
                    element={field}
                    group_className={group_className}
                    label_className={label_className}
                    form_control_class_name={form_control_class_name}
                    blurred={blurred ? () => blurred(key) : undefined}/>
            })}
        </>
    )
};

export default VerticalInputElementForm;
