import React from "react";
import {Button, Dropdown, ListGroup, Offcanvas} from "react-bootstrap";

import styles from './NotificationCenter.module.scss';
import NotificationDropdownItem from "./NotificationDropwdownItem/NotificationDropdownItem";
import StateType from "../../../store/store";
import {connect, ConnectedProps} from "react-redux";
import NotificationActions from "../../../store/actions/NotificationActions";
import {useLocation, useNavigate} from "react-router-dom";
import {Notification} from "../../../types";
import NotificationListGroupItem from "./NotificationListGroupItem/NotificationListGroupItem";
import BrokerActions from "../../../store/actions/BrokerActions";
import AdminActions from "../../../store/actions/AdminActions";
import {GeneralActions} from "../../../store/actions/GeneralActions";

const map_state_to_props = (state: StateType) => ({
    state: {
        is_guest: state.account.is_guest,
        show_notification_center: state.notification_center.show,
        notifications: state.notification_center.notifications,
        is_broker: state.account.is_broker,
    }
});

const map_dispatch_to_props = (dispatch: any) => ({
    actions: {
        set_notification_center_show: (show: boolean) => dispatch(NotificationActions.set_show_notification_center(show)),
        mark_notification_read: (notification: Notification) => dispatch(NotificationActions.mark_notification_read(notification)),
        set_selected_carrier_guid: (is_broker: boolean, carrier_guid: string) => {
            if (is_broker) dispatch(BrokerActions.set_selected_carrier_guid(carrier_guid));
            dispatch(AdminActions.set_selected_carrier_guid(carrier_guid));
        },
        set_reload_broker_referrals: (reload: boolean) => dispatch(GeneralActions.set_reload_broker_referrals(reload)),
    }
});

const connector = connect(map_state_to_props, map_dispatch_to_props)

type TempProps = {
    children: React.ReactNode;
    onClick: (e: React.MouseEvent) => void;
}
const notification_center_toggle = React.forwardRef<any, TempProps>(({children, onClick}, ref) => (
    <Button
        type={'button'}
        variant={'NavigationCenterToggle'}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        title={'Notifications'}
        ref={ref}>
        {children}
    </Button>
));

const NotificationCenter = ({state, actions}: ConnectedProps<typeof connector>) => {

    const navigate = useNavigate();
    const location = useLocation();

    const on_notification_action = (notification: Notification) => {
        if (notification.carrier) {
            const current_path = location.pathname.replaceAll(/^\s*(.*?)\/?\s*$/gi, '$1').toLowerCase();
            const action_path = notification.action.replaceAll(/^\s*(.*?)\/?\s*$/gi, '$1').toLowerCase();
            actions.set_selected_carrier_guid(state.is_broker, notification.carrier.guid);
            if (current_path === action_path) {
                switch (notification.carrier.type) {
                    case 'NewReferral':
                    case 'BrokerTimeoutAlert':
                        actions.set_reload_broker_referrals(true);
                        break;
                    default:
                        break;
                }
            }
        }
        navigate(notification.action);
        actions.set_notification_center_show(false);
        actions.mark_notification_read(notification);
    };

    const unread_notifications = state.notifications.filter(notification => !notification.read);

    const render_notification_bell = () => (
        <div
            className={`${unread_notifications.length > 0 && !state.show_notification_center ? styles.NotificationBellRing : ''}`}>
            <i className={`bi bi-bell${unread_notifications.length > 0 ? `-fill` : ''} fs-3`}></i>
        </div>
    );

    if (state.is_guest) return null;

    return (
        <>
            <Dropdown className={`d-none d-lg-block`}
                      show={state.show_notification_center}
                      onToggle={(next_show) => actions.set_notification_center_show(next_show)}>
                <Dropdown.Toggle as={notification_center_toggle}>
                    {render_notification_bell()}
                </Dropdown.Toggle>
                <Dropdown.Menu align={'end'} className={`${styles.NavigationCenterMenu}`}>
                    <Dropdown.Header>Notifications</Dropdown.Header>
                    {unread_notifications.map(notification => (
                        <NotificationDropdownItem
                            key={notification.id}
                            on_action={notification => on_notification_action(notification)}
                            notification={notification}
                        />
                    ))}
                    {unread_notifications.length > 0 ? null : (
                        <>
                            <Dropdown.Divider/>
                            <Dropdown.ItemText className={'text-center py-2 fs-6 text-muted'}>
                                You have <strong>no</strong> unread notifications.
                            </Dropdown.ItemText>
                        </>
                    )}
                </Dropdown.Menu>
            </Dropdown>
            <Button type={'button'}
                    variant={'NavigationCenterToggle'}
                    className={'d-block d-lg-none'}
                    onClick={() => actions.set_notification_center_show(true)}
                    title={'Notifications'}
            >
                {render_notification_bell()}
            </Button>
            <Offcanvas className={'d-lg-none'}
                       show={state.show_notification_center}
                       onHide={() => actions.set_notification_center_show(false)}
                       placement={'end'}
                       backdrop={false}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Notifications</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ListGroup>
                        {unread_notifications.map(notification => (
                            <NotificationListGroupItem
                                key={notification.id}
                                on_action={notification => on_notification_action(notification)}
                                notification={notification}
                            />
                        ))}
                        {unread_notifications.length > 0 ? null : (
                            <ListGroup.Item className={'text-center py-3 fs-6 text-muted'}>
                                You have <strong>no</strong> unread notifications.
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
};

export default connector(NotificationCenter);
