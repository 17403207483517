import {UpdateLeadBindingModel, ViewMetricsTotalsParams} from "../types";
import moment from "moment-timezone";
import axios, {AxiosError} from "axios";

export default class BaseAPI {
    protected static view_metrics_search_params(totals_params: ViewMetricsTotalsParams): URLSearchParams {
        const { from_date, to_date, timezone } = totals_params;
        const params = new URLSearchParams();
        params.append('fromDate', from_date.format('YYYY-MM-DD HH:mm:ss'));
        params.append('toDate', to_date.format('YYYY-MM-DD HH:mm:ss'));
        params.append('offset', `${Number(moment.tz.zone(timezone.timeZoneName)?.utcOffset(new Date().getTime())) / 60}`);
        return params;
    }



    protected static update_lead_binding_model_to_url_search_params(update_lead_data: UpdateLeadBindingModel) {
        const params = new URLSearchParams();
        params.append('firstName', update_lead_data.firstName);
        params.append('lastName', update_lead_data.LastName);
        params.append('preferredContactMethodId', `${update_lead_data.preferredContactMethodId}`);
        params.append('email', update_lead_data.email);
        params.append('phone', update_lead_data.Phone);
        params.append('cellphone', update_lead_data.CellPhone);
        params.append('zipCode', update_lead_data.ZipCode);
        params.append('statusId', `${update_lead_data.statusId || ''}`);
        params.append('timeStamp', update_lead_data.timeStamp);
        params.append('justificationId', `${update_lead_data.justificationId || ''}`);
        params.append('justificationNote', update_lead_data.justificationNote || '');
        params.append('note', update_lead_data.note);
        params.append('city', update_lead_data.City);
        params.append('postalCodeId', update_lead_data.PostalCodeId || '');
        update_lead_data.customFieldValues.forEach((custom_field_value, index) => {
            params.append(`customFieldValues[${index}][customFieldId]`, `${custom_field_value.customFieldId}`);
            params.append(`customFieldValues[${index}][value]`, `${custom_field_value.value}`);
        });
        return params;
    }

    static authenticatedAxiosInstance(token: string) {
        return axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    }

    static errorMessage(error: any, fallback?: string) {
        const fallbackMessage = fallback ?? 'Unknown error.';
        if (!error) return fallbackMessage;
        if (!(error as AxiosError).isAxiosError) {
            return (error.message && typeof error.message === 'string') ? error.message : fallbackMessage;
        }
        const axiosError = error as AxiosError<any>;
        if (!axiosError.response?.data) return fallbackMessage;
        if (typeof axiosError.response.data === 'string') return axiosError.response.data;
        if (axiosError.response.data.message) return axiosError.response.data.message;
        return fallbackMessage;
    }

}
