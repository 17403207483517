import { act } from 'react-dom/test-utils';
import { AdminBrokerSearchBindingModel, AdminLeadSearchBindingModel, BrokerUserDTO, CarrierIdGuidNameDTO, LeadDetailAdminDTO, NonBrokerUserDTO, UserTypeDTO } from '../../types';
import * as action_types from '../actions/actionTypes';

export type AdminState = {
    loading_admin_data: boolean;
    min_carriers: CarrierIdGuidNameDTO[];
    selected_carrier_guid: string | null;
    reassign_lead: LeadDetailAdminDTO | null;
    saved_lead_search: AdminLeadSearchBindingModel | null;
    saved_broker_search: AdminBrokerSearchBindingModel | null;
    edit_user: NonBrokerUserDTO | null;
    user_types: UserTypeDTO[];
    selected_broker: BrokerUserDTO | null;
    selected_user_type_guid: string | null;
};

const initial_state: AdminState = {
    loading_admin_data: true,
    min_carriers: [],
    selected_carrier_guid: null,
    reassign_lead: null,
    saved_lead_search: null,
    saved_broker_search: null,
    edit_user: null,
    user_types: [],
    selected_broker: null,
    selected_user_type_guid: null
};

const set_loading_admin_data = (state: AdminState, action: any): AdminState => ({
    ...state,
    loading_admin_data: action.loading_admin_data
});

const set_min_carriers = (state: AdminState, action: any): AdminState => ({
    ...state,
    min_carriers: [...action.min_carriers]
});

const set_selected_carrier_guid = (state: AdminState, action: any): AdminState => ({
    ...state,
    selected_carrier_guid: action.selected_carrier_guid
});

const set_selected_user_type = (state: AdminState, action: any): AdminState => ({
    ...state,
    selected_user_type_guid: action.selected_user_type
});

const set_reassign_lead = (state: AdminState, action: any): AdminState => ({
    ...state,
    reassign_lead: action.reassign_lead
});

const set_saved_lead_search = (state: AdminState, action: any): AdminState => ({
    ...state,
    saved_lead_search: action.saved_lead_search
});

const set_saved_broker_search = (state: AdminState, action: any): AdminState => ({
    ...state,
    saved_broker_search: action.saved_broker_search
});

const set_edit_user = (state: AdminState, action: any): AdminState => ({
    ...state,
    edit_user: {...action.edit_user}
});

const set_user_types = (state: AdminState, actions: any): AdminState => ({
    ...state,
    user_types: actions.user_types
});

const set_selected_broker = (state: AdminState, actions: any): AdminState => ({
    ...state,
    selected_broker: actions.selected_broker
});

const set_availability = (state: AdminState, actions: any): AdminState => ({
    ...state,
    selected_broker: {
        ...state.selected_broker,
        brokerData: {
            ...state.selected_broker?.brokerData,
            available: actions.availability
        }
    } as BrokerUserDTO
})

const reducer = (state = initial_state, action: any) => {
    switch (action.type) {
        case action_types.ADMIN_SET_LOADING_ADMIN_DATA:
            return set_loading_admin_data(state, action);
        case action_types.ADMIN_SET_MIN_CARRIERS:
            return set_min_carriers(state, action);
        case action_types.ADMIN_SET_SELECTED_CARRIER_GUID:
            return set_selected_carrier_guid(state, action);
        case action_types.ADMIN_SET_REASSIGN_LEAD:
            return set_reassign_lead(state, action);
        case action_types.ADMIN_SET_SAVED_LEAD_SEARCH:
            return set_saved_lead_search(state, action);
        case action_types.ADMIN_SET_SAVED_BROKER_SEARCH:
            return set_saved_broker_search(state, action);
        case action_types.ADMIN_SET_EDIT_USER:
            return set_edit_user(state, action);
        case action_types.ADMIN_SET_USER_TYPES:
            return set_user_types(state, action);
        case action_types.ADMIN_SET_SELECTED_BROKER:
            return set_selected_broker(state, action);
        case action_types.ADMIN_SET_BROKER_AVAILABILITY:
            return set_availability(state, action);
        case action_types.ADMIN_SET_SELECTED_USER_TYPE:
            return set_selected_user_type(state,action);
        default:
            return state;
    }
};

export default reducer;
