export const GENERAL_SHOW_SPINNING_WHEEL = 'GENERAL_SHOW_SPINNING_WHEEL';
export const GENERAL_HIDE_SPINNING_WHEEL = 'GENERAL_HIDE_SPINNING_WHEEL';
export const GENERAL_SET_ERROR = 'GENERAL_SET_ERROR';
export const GENERAL_CLEAR_ERROR = 'GENERAL_CLEAR_ERROR';
export const GENERAL_NAV_TO = 'GENERAL_NAV_TO';
export const GENERAL_POP_NAV = 'GENERAL_POP_NAV';
export const GENERAL_SET_TIMEZONES = 'GENERAL_SET_TIMEZONES';
export const GENERAL_SELECT_TIMEZONE = 'GENERAL_SELECT_TIMEZONE';
export const GENERAL_SET_BROKER_PREFERRED_CONTACT_METHODS = 'GENERAL_SET_BROKER_PREFERRED_CONTACT_METHODS';
export const GENERAL_SET_LEAD_PREFERRED_CONTACT_METHODS = 'GENERAL_SET_LEAD_PREFERRED_CONTACT_METHODS';
export const GENERAL_SET_MOBILE_NAV = 'GENERAL_SET_MOBILE_NAV';
export const GENERAL_SET_TOOLTIP = 'GENERAL_SET_TOOLTIP';
export const GENERAL_SET_SUCCESS = 'GENERAL_SET_SUCCESS';
export const GENERAL_SET_CARRIER_BRANDING = 'GENERAL_SET_CARRIER_BRANDING';
export const GENERAL_CLEAR_CARRIER_BRANDING = 'GENERAL_CLEAR_CARRIER_BRANDING';
export const GENERAL_OPEN_IMAGE_LIBRARY = 'GENERAL_OPEN_IMAGE_LIBRARY';
export const GENERAL_CLOSE_IMAGE_LIBRARY = 'GENERAL_CLOSE_IMAGE_LIBRARY';
export const GENERAL_SET_RELOAD_BROKER_REFERRALS = 'GENERAL_SET_RELOAD_BROKER_REFERRALS';

export const ACCOUNT_SET_IS_GUEST = 'ACCOUNT_SET_IS_GUEST';
export const ACCOUNT_SET_USER = 'ACCOUNT_SET_USER';
export const ACCOUNT_SET_LAST_ACCOUNT_ACTION = 'ACCOUNT_SET_LAST_ACCOUNT_ACTION';
export const ACCOUNT_SET_SESSION_EXPIRES_IN_SECONDS = 'ACCOUNT_SET_SESSION_EXPIRES_IN_SECONDS';
export const ACCOUNT_SET_IS_LOADING_PROFILE = 'ACCOUNT_SET_IS_LOADING_PROFILE';

export const ADMIN_SET_LOADING_ADMIN_DATA = 'ADMIN_SET_LOADING_ADMIN_DATA';
export const ADMIN_SET_MIN_CARRIERS = 'ADMIN_SET_MIN_CARRIERS';
export const ADMIN_SET_SELECTED_CARRIER_GUID = 'ADMIN_SET_SELECTED_CARRIER_GUID';
export const ADMIN_SET_REASSIGN_LEAD = 'ADMIN_SET_REASSIGN_LEAD';
export const ADMIN_SET_SAVED_LEAD_SEARCH = 'ADMIN_SET_SAVED_LEAD_SEARCH';
export const ADMIN_SET_SAVED_BROKER_SEARCH = 'ADMIN_SET_SAVED_BROKER_SEARCH';
export const ADMIN_SET_EDIT_USER = 'ADMIN_SET_EDIT_USER';
export const ADMIN_SET_USER_TYPES = 'ADMIN_SET_USER_TYPES';
export const ADMIN_SET_SELECTED_BROKER = 'ADMIN_SET_SELECTED_BROKER';
export const ADMIN_SET_BROKER_AVAILABILITY = 'ADMIN_SET_BROKER_AVAILABILITY';
export const ADMIN_SET_MANUAL_OVERRIDE_MASTER_TOGGLE_SWITCH = 'ADMIN_SET_MANUAL_OVERRIDE_MASTER_TOGGLE_SWITCH';
export const ADMIN_SET_SELECTED_USER_TYPE = 'ADMIN_SET_SELECTED_USER_TYPE';
export const EXPERT_SET_MANUAL_OVERRIDE_MASTER_TOGGLE_SWITCH = 'EXPERT_SET_MANUAL_OVERRIDE_MASTER_TOGGLE_SWITCH';
export const EXPERT_SET_AVAILABILITY = 'EXPERT_SET_AVAILABILITY';
export const EXPERT_SET_DONT_ACCEPT_LEAD = 'EXPERT_SET_DONT_ACCEPT_LEAD';

export const BROKER_SET_LOADING_BROKER_DATA = 'BROKER_SET_LOADING_BROKER_DATA';
export const BROKER_SET_MIN_CARRIERS = 'BROKER_SET_MIN_CARRIERS';
export const BROKER_SET_SELECTED_CARRIER_GUID = 'BROKER_SET_SELECTED_CARRIER_GUID';
export const BROKER_SET_AVAILABILITY_FORM_CACHE = 'BROKER_SET_AVAILABILITY_FORM_CACHE';
export const BROKER_SET_ASSOCIATION_CARRIER = 'BROKER_SET_ASSOCIATION_CARRIER';

export const CARRIER_USER_SET_LOADING_CARRIER_USER_DATA = 'CARRIER_USER_SET_LOADING_CARRIER_USER_DATA';
export const CARRIER_USER_SET_MIN_CARRIER = 'CARRIER_USER_SET_MIN_CARRIER';
export const CARRIER_USER_SET_REASSIGN_LEAD = 'CARRIER_USER_SET_REASSIGN_LEAD';
export const CARRIER_USER_SET_EDIT_USER = 'CARRIER_USER_SET_EDIT_USER';
export const CARRIER_USER_SET_USER_TYPES = 'CARRIER_USER_SET_USER_TYPES';


export const BRANDING_SET_BRANDING_DOCUMENT_IS_LOADING = 'BRANDING_SET_BRANDING_DOCUMENT_IS_LOADING';
export const BRANDING_SET_BRANDING_DOCUMENT = 'BRANDING_SET_BRANDING_DOCUMENT';
export const BRANDING_SET_BRANDING_LANGUAGE = 'BRANDING_SET_BRANDING_LANGUAGE';

export const NOTIFICATIONS_SET_SHOW = 'NOTIFICATIONS_SET_SHOW';
export const NOTIFICATIONS_PUSH_NOTIFICATION = 'NOTIFICATIONS_PUSH_NOTIFICATION';
export const NOTIFICATIONS_MARK_READ = 'NOTIFICATIONS_MARK_READ';
export const NOTIFICATIONS_CLEAR = 'NOTIFICATIONS_CLEAR';
