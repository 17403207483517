import React, {useEffect} from 'react';
import {Container, Row} from 'react-bootstrap';
import {useCookies} from 'react-cookie';
import {connect, ConnectedProps} from 'react-redux';
import {useLocation, useNavigate, Location} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import Layout from '../../hoc/Layout/Layout';
import MainRouter from '../../routers/MainRouter/MainRouter';
import AccountActions from '../../store/actions/AccountActions';

import styles from './App.module.scss';

const map_dispatch_to_props = (dispatch: any) => ({
    actions: {
        load_user_data: (cookies: Record<string, any>, location: Location) => dispatch(AccountActions.load_user_data(cookies, location))
    }
});

const connector = connect(null, map_dispatch_to_props);

function App({actions}: ConnectedProps<typeof connector>) {

    const navigate = useNavigate();
    const location = useLocation();
    const [cookies] = useCookies();

    useEffect(() => {
        actions.load_user_data(cookies, location);
        // eslint-disable-next-line
    }, [])

    const non_fluid_routes = [
        '/login',
        '/forgot_username',
        '/forgot_password',
        '/expired',
        '/forgot',
        '/registration',
        '/lp',
        '/select_profile',
        '/disclosure.html',
        '/association',
    ];

    const white_routes = [
        '/login',
        '/forgot_password',
        '/forgot_username',
        '/expired',
        '/forgot',
        '/registration',
        '/select_profile',
        '/association',
        '/lp',
        '/404',
    ];

    const fluid_container = non_fluid_routes.filter(route => location.pathname.startsWith(route)).length === 0;

    return (
        <Layout>
            <Helmet>
                {white_routes.filter(route => location.pathname.startsWith(route)).length === 0 ? null : (
                    <style>{"body { background-color: white; }"}</style>)}
            </Helmet>
            <Container fluid={fluid_container} className={styles.ContainerMain}>
                <Row>
                    <MainRouter/>
                </Row>
            </Container>
        </Layout>
    );
}

export default connector(App);
