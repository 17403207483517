import React, {CSSProperties} from 'react';
import moment from 'moment';
import {Link, useLocation} from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';

import styles from './Footer.module.scss';
import {connect, ConnectedProps} from "react-redux";
import StateType from "../../store/store";
import {CarrierFooterBrandingDocument, TextAlignedColorUrl} from "../../BrandingTypes";

const map_state_to_props = (state: StateType) => ({
    state: {
        footer_branding: state.general.carrier_branding?.footer,
        branding_language: state.branding.branding_language,
    }
});

const connector = connect(map_state_to_props);

const DefaultFooter = ({tou_href}: { tou_href?: string }) => (
    <>
        <Col xs={12} className={`${styles.Footer} text-center`}>
            &copy; 2015-{moment().format("YYYY")} Big Wave Systems LLC dba Help On Demand
            |
            {tou_href ? (
                <>&nbsp;<a href={tou_href} target={'_blank'}>Privacy Policy/Terms of Use</a></>
            ) : (
                <>&nbsp;<a href="/disclosure.html" target={'_blank'}>Privacy Policy/Terms of Use</a></>
            )}
        </Col>
    </>
);

const CustomFooter = ({footer_branding, branding_language}: { footer_branding: CarrierFooterBrandingDocument, branding_language: "en" | "es" }) => {
    const render_text_col = (texts: TextAlignedColorUrl[]) => {
        if (texts.length === 0) return null;
        const render_text = (text: TextAlignedColorUrl, index: number) => {
            const process_variables = (value: string) => {
                return value
                    .replaceAll(/\$CUR_YEAR/g, moment().format("YYYY"));
            };
            const render_actual_text = () => (
                <span style={text.color ? {color: text.color} : undefined}>
                    {process_variables(text.text)}
                </span>
            );
            const render_link = () => {
                if (!text.href) return render_actual_text();
                if (/^https?:\/\/.*$/.test(text.href)) return (
                    <a href={text.href}
                       target={'_blank'}
                       className={!text.color ? styles.FooterText : undefined}>
                        {render_actual_text()}
                    </a>
                );
                return (
                    <Link to={text.href}
                          className={!text.color ? styles.FooterText : undefined}>
                        {render_actual_text()}
                    </Link>
                )
            };
            const render_pipe = () => {
                if (index === 0) return null;
                return (
                    <span
                        style={text.color ? {color: text.color} : undefined}
                        className={`me-1 ms-1${!text.color ? ` ${styles.FooterText}` : ""}`}>|</span>
                )
            };
            return (
                <React.Fragment key={index}>
                    {render_pipe()}{render_link()}
                </React.Fragment>
            )
        };
        return texts.map((text, index) => render_text(text, index));
    };

    const branding_text: TextAlignedColorUrl[] = (() => {
        if(branding_language === 'en') return [...footer_branding.text].filter(t => (t.lang ?? 'en') === branding_language);
        const es_text = [...footer_branding.text].filter(t => (t.lang ?? 'en') === branding_language);
        if (es_text.length > 0) return es_text;
        return [...footer_branding.text];
    })();

    if (footer_branding.tou_href) {
        const text_colors = branding_text.filter(text => !!text.color).map(text => text.color);
        const tou_text: TextAlignedColorUrl = {
            alignment: 'right',
            text: 'Privacy Policy/Terms of Use',
            href: footer_branding.tou_href
        };
        if (text_colors.length > 0) {
            tou_text.color = text_colors.at(-1);
        }
        branding_text.push(tou_text);
    }

    const branding_text_ordered: TextAlignedColorUrl[] = [];
    branding_text_ordered.push(...branding_text.filter(text => text.alignment === 'left'));
    branding_text_ordered.push(...branding_text.filter(text => text.alignment === 'right'));

    return (
        <Col xs={12} className={"text-center"}>
            {render_text_col(branding_text_ordered)}
        </Col>
    );
};

const Footer = ({state}: ConnectedProps<typeof connector>) => {
    const footer_styles: CSSProperties = {};
    const location = useLocation();

    const default_footer_paths = [
        '/association',
        '/expired',
        '/disclosure'
    ];

    const default_footer_paths_found = (default_footer_paths.findIndex(path => location.pathname.indexOf(path) >= 0) >= 0)

    if (!default_footer_paths_found && state.footer_branding) {
        if (state.footer_branding.bg_color) {
            footer_styles.backgroundColor = state.footer_branding.bg_color;
        }
    }

    return (
        <footer className={`position-absolute bottom-0 pt-3 ${styles.Footer}`} style={footer_styles}>
            <Container fluid>
                <Row>
                    {default_footer_paths_found || !state.footer_branding || state.footer_branding.text.length === 0 ?
                        <DefaultFooter tou_href={state.footer_branding?.tou_href}/> :
                        <CustomFooter
                            footer_branding={state.footer_branding}
                            branding_language={state.branding_language}
                        />}
                </Row>
            </Container>
        </footer>
    );
};

export default connector(Footer);
