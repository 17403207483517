import React, {useEffect} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {connect, ConnectedProps} from 'react-redux';
import StateType from '../../store/store';
import {GeneralActions} from '../../store/actions/GeneralActions';
import AccountActions from '../../store/actions/AccountActions';
import AccountRouter from '../AccountRouter/AccountRouter';


const Login = React.lazy(() => import('../../containers/Login/Login'));
const Logout = React.lazy(() => import('../../containers/Logout/Logout'));
const ForgotUsername = React.lazy(() => import('../../containers/ForgotUsername/ForgotUsername'));
const ForgotPassword = React.lazy(() => import('../../containers/ForgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../../containers/ResetPassword/ResetPassword'));
const MobileLogin = React.lazy(() => import('../../containers/MobileLogin/MobileLogin'));
const BrokerRegistration = React.lazy(() => import('../../containers/BrokerRegistration/BrokerRegistration'));
const LeadLandingPage = React.lazy(() => import('../../containers/LeadLandingPage/LeadLandingPage'));
const Disclosure = React.lazy(() => import('../../containers/Disclosure/Disclosure'));
const ExpiredPassword = React.lazy(() => import('../../containers/ExpiredPassword/ExpiredPassword'));
const PageNotFound = React.lazy(() => import('../../containers/PageNotFound/PageNotFound'));

const map_state_to_props = (state: StateType) => ({
    nav_to: state.general.target_nav,
    account: {
        is_admin: state.account.is_admin,
        is_carrier: state.account.is_carrier,
        is_broker: state.account.is_broker,
        is_guest: state.account.is_guest,
        is_loading_profile: state.account.is_loading_profile,
        is_enter_referrals: state.account.is_enter_referrals
    }
});

const map_dispatch_to_props = (dispatch: any) => ({
    actions: {
        pop_nav: () => dispatch(GeneralActions.pop_nav()),
        go_home: () => dispatch(AccountActions.go_home()),
        hide_mobile_nav: () => dispatch(GeneralActions.hide_mobile_nav())
    }
});

const connector = connect(map_state_to_props, map_dispatch_to_props);

const MainRouter = ({nav_to, actions, account}: ConnectedProps<typeof connector>) => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (nav_to.length > 0) {
            const to = nav_to[nav_to.length - 1];
            navigate(to);
            actions.pop_nav();
        } else if (location.pathname === '/') {
            actions.go_home();
        }
    }, [nav_to, navigate, actions]); //eslint-disable-line

    useEffect(() => {
        actions.hide_mobile_nav();
    }, [location.pathname]);  //eslint-disable-line

    const page_not_found_route = () => {
        if (account.is_loading_profile) return null;
        if (account.is_guest) return <React.Suspense fallback={<></>}><PageNotFound/></React.Suspense>;
        return <AccountRouter/>;
    };

    return (
        <Routes>
            <Route path={'/login'} element={
                <React.Suspense fallback={<></>}>
                    <Login/>
                </React.Suspense>
            }/>
            <Route path={'/logout'} element={
                <React.Suspense fallback={<></>}>
                    <Logout/>
                </React.Suspense>
            }/>
            <Route path={'/forgot_username'}
                   element={<React.Suspense fallback={<></>}><ForgotUsername/></React.Suspense>}/>
            <Route path={'/forgot_password'}
                   element={<React.Suspense fallback={<></>}><ForgotPassword/></React.Suspense>}/>
            <Route path={'/forgot/:guid/*'}
                   element={<React.Suspense fallback={<></>}><ResetPassword/></React.Suspense>}/>
            <Route path={'/mobilelogin*'} element={<React.Suspense fallback={<></>}><MobileLogin/></React.Suspense>}/>
            <Route path={'/registration/broker/:user_guid/carrier/:carrier_guid/:token'}
                   element={<React.Suspense fallback={<></>}><BrokerRegistration/></React.Suspense>}/>
            <Route path={'/lp/:carrier_guid'}
                   element={<React.Suspense fallback={<></>}><LeadLandingPage/></React.Suspense>}/>
            <Route path={'/disclosure.html'}
                   element={<React.Suspense fallback={<></>}><Disclosure/></React.Suspense>}/>
            <Route path={'/expired'}
                   element={<React.Suspense fallback={<></>}><ExpiredPassword/></React.Suspense>}/>
            <Route path='*' element={page_not_found_route()}/>
        </Routes>
    );
};

export default connector(MainRouter);
