import React, {useEffect, useState} from "react";
import StateType from "../../store/store";
import {connect, ConnectedProps} from "react-redux";
import useAccessToken from "../../hooks/useAccessToken";
import {BrokerNotPickedUpAlert, BrokerUserDTO, NewNotification, UserProfile} from "../../types";
import {useNavigate} from "react-router-dom";
import AuthAPI from "../../api/AuthAPI";
import NotificationActions from "../../store/actions/NotificationActions";
import moment from "moment-timezone";
import {useInterval} from "usehooks-ts";
import CarrierAPI from "../../api/CarrierAPI";
import GeneralAPI from "../../api/GeneralAPI";
import {BrandingElement} from "../../BrandingTypes";
import BrokerAPI from "../../api/BrokerAPI";

const map_state_to_props = (state: StateType) => ({
    state: {
        is_broker: state.account.is_broker,
        last_account_action: state.account.last_account_action,
        session_expires_in_seconds: state.account.session_expires_in_seconds,
    }
});

const map_dispatch_to_props = (dispatch: any) => ({
    actions: {
        push_notification: (notification: NewNotification) => dispatch(NotificationActions.push_notification(notification))
    }
});

const connector = connect(map_state_to_props, map_dispatch_to_props);

type Props = ConnectedProps<typeof connector> & {
    children: React.ReactNode;
};

const AccountTimeoutContainer = ({children, actions, state}: Props) => {

    const [loading, set_loading] = useState<boolean>(false);
    const [has_checked_password_expiration, set_has_checked_password_expiration] = useState<boolean>(false);
    const [has_checked_not_picked_up_alert, set_has_checked_not_picked_up_alert] = useState<boolean>(false);
    const [has_checked_broker_alert_timeout, set_has_checked_broker_alert_timeout] = useState<boolean>(false);
    const access_token = useAccessToken();
    const navigate = useNavigate();

    const check_password_expiration = (me: UserProfile) => {
        if (has_checked_password_expiration) return;
        const password_expiration_utc = moment.utc(me.passwordExpirationUTC);
        const now = moment.utc();
        const time_until_expiration = moment.duration(password_expiration_utc.diff(now));
        if (time_until_expiration.asDays() <= 15) {
            const rounded_days = Math.ceil(time_until_expiration.asDays());
            actions.push_notification({
                title: 'Your Password is Expiring Soon',
                action: state.is_broker ? '/broker/change_password' : '/admin/settings',
                count: 0,
                sub_title: `Your password will expire in ${rounded_days} day${rounded_days !== 1 ? 's' : ''}. We recommend changing it soon.`
            })
        }
        set_has_checked_password_expiration(true);
    };

    const get_broker_carrier = (me: BrokerUserDTO, carrier_guid: string) => (
        me.brokerData.carriers.find(carrier => carrier.carrier.guid.trim().toLowerCase() === carrier_guid.trim().toLowerCase())
    );

    const not_picked_up_alert_to_notification = async (me: BrokerUserDTO, not_picked_up_alert: BrokerNotPickedUpAlert): Promise<NewNotification | null> => {
        const carrier = get_broker_carrier(me, not_picked_up_alert.carrierGuid);
        if (!carrier) return null;
        const branding = await CarrierAPI.get_carrier_branding_configuration(carrier.carrier.guid);
        if (!branding?.other?.en?.missed_leads_alert) return null;
        const message = await GeneralAPI.get_branding_document(branding.other.en.missed_leads_alert);
        if ((message.banner_content as BrandingElement[]).length) return null;
        const message_banner_content = message.banner_content as BrandingElement;
        if (message_banner_content.tag !== 'text') return null;
        const notification_text = message_banner_content.text
            ?.replaceAll(/\{MissedLeadCount}/gi, `${not_picked_up_alert.leadsCount}`)
            .replaceAll(/\{LeadTerm}/gi, (branding.terms.lead ?? 'Referral').toLowerCase())
            .replaceAll(/\{LeadPlural}/gi, not_picked_up_alert.leadsCount === 1 ? '' : 's')
            .replaceAll(/\{DaysForNotPickedUpAlert}/gi, `${not_picked_up_alert.daysForNotPickedUpAlert}`)
            .replaceAll(/\{BrokerAcceptanceTimeOut}/gi, `${carrier.carrier.brokerAcceptanceMinutesTimeout}`)
            .replaceAll(/\{CarrierName}/gi, carrier.carrier.name)
            .replaceAll(/\{BrokerTerm}/gi, (branding.terms.broker ?? 'Broker').toLowerCase());
        if (!notification_text) return null;
        return {
            title: `ALERT! Missed ${branding.terms.lead ?? 'Referral'}${not_picked_up_alert.leadsCount === 1 ? '' : 's'}`,
            action: `/broker/view_metrics`,
            count: 0,
            sub_title: notification_text,
            carrier: {
                type: 'MissedAlert',
                guid: carrier.carrier.guid,
            },
        };
    };

    const check_not_picked_up_alert = async (me: BrokerUserDTO) => {
        if (has_checked_not_picked_up_alert) return;
        if (!me.notPickedUpAlerts || me.notPickedUpAlerts.length === 0) return;
        for (const not_picked_up_alert of me.notPickedUpAlerts) {
            const notification = await not_picked_up_alert_to_notification(me, not_picked_up_alert);
            if (notification) actions.push_notification(notification);
        }
        set_has_checked_not_picked_up_alert(true);
    }

    const generate_new_lead_notification = async (me: BrokerUserDTO, carrier_name: string, carrier_guid: string) : Promise<NewNotification | null> => {
        const carrier = get_broker_carrier(me, carrier_guid);
        if (!carrier) return null;
        const branding = await CarrierAPI.get_carrier_branding_configuration(carrier.carrier.guid);
        const referral_term = branding?.terms?.lead ?? 'Referral';
        return {
            title: `New ${referral_term}`,
            action: '/broker/',
            sub_title: `You have new ${referral_term}s from ${carrier_name}`,
            count: 0,
            carrier: {
                type: 'NewReferral',
                guid: carrier_guid,
            }
        };
    };

    const check_lead_notification = async (me: BrokerUserDTO) => {
        const leads_notifications = await BrokerAPI.leads_notification(access_token);
        if (leads_notifications.length === 0) return;
        const carriers_with_notification = leads_notifications
            .filter(notification => notification.leadsCount > 0)
            .map(notification => ({guid: notification.carrierGuid, name: notification.carrierName}));
        if (carriers_with_notification.length === 0) return;
        for (const carrier of carriers_with_notification) {
            const {name, guid} = carrier;
            const notification = await generate_new_lead_notification(me, name, guid.trim().toLowerCase());
            if (notification) actions.push_notification(notification);
        }
    };

    const generate_carrier_timeout_alert_notification = async (me: BrokerUserDTO, carrier_name: string, carrier_guid: string) : Promise<NewNotification | null> => {
        const carrier = get_broker_carrier(me, carrier_guid);
        if (!carrier) return null;
        const branding = await CarrierAPI.get_carrier_branding_configuration(carrier.carrier.guid);
        const referral_term = branding?.terms?.lead ?? 'Referral';
        return {
            title: `${carrier_name} ${referral_term}s Not Updated`,
            action: '/broker/',
            sub_title: `URGENT! You have not updated your ${referral_term} status recently, please update immediately to avoid suspension.`,
            count: 0,
            carrier: {
                type: 'BrokerTimeoutAlert',
                guid: carrier_guid,
            }
        };
    };

    const check_broker_alert_timeout = async (me: BrokerUserDTO) => {
        if (has_checked_broker_alert_timeout) return;
        const carriers_with_alert = (me.alerts ?? [])
            .filter(alert => alert.leadsCount > 0)
            .map(alert => ({guid: alert.carrierGuid, name: alert.carrierName}));
        if (carriers_with_alert.length === 0) return;
        for (const carrier of carriers_with_alert) {
            const {name, guid} = carrier;
            const notification = await generate_carrier_timeout_alert_notification(me, name, guid.trim().toLowerCase());
            if (notification) actions.push_notification(notification);
        }
        set_has_checked_broker_alert_timeout(true);
    }

    const check_broker_notifications = async (me: BrokerUserDTO) => {
        await Promise.all([
            check_not_picked_up_alert(me),
            check_lead_notification(me),
            check_broker_alert_timeout(me),
        ]);
    }

    const user_is_broker = (me: UserProfile) => {
        return new Set<string>((me.roles ?? []).map(role => role.toLowerCase().trim())).has('broker');
    };


    const load_notifications = async () => {
        if (loading) return;
        set_loading(true);
        let me: UserProfile;
        try {
            me = await AuthAPI.me(access_token);
        } catch (e) {
            navigate('/logout');
            return;
        }
        check_password_expiration(me);
        if (user_is_broker(me)) await check_broker_notifications(me as BrokerUserDTO);
        set_loading(false);
    };

    useEffect(() => {
        load_notifications().then();
    }, []);

    useInterval(() => {
        load_notifications().then();
    }, loading ? null : 25 * 1000);

    useInterval(() => {
        const last_account_action = moment(state.last_account_action);
        if (last_account_action.isValid()) {
            const seconds_since_last_action = moment().diff(last_account_action, 'seconds');
            const session_expires_in_seconds = state.session_expires_in_seconds;
            if (seconds_since_last_action > session_expires_in_seconds) {
                navigate('/logout');
                return;
            }
        }
    }, 1000)

    return (
        <React.Suspense fallback={<></>}>
            {children}
        </React.Suspense>
    );
};

export default connector(AccountTimeoutContainer);
