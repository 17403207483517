import * as action_types from '../actions/actionTypes';
import {Notification, NewNotification} from "../../types";
import {v4 as uuidV4} from 'uuid';

export type NotificationCenterState = {
    show: boolean;
    notifications: Notification[];
};

const initial_state: NotificationCenterState = {
    show: false,
    notifications: []
};

const set_show = (state: NotificationCenterState, action: any): NotificationCenterState => ({
    ...state,
    show: action.show
});

const generate_id = (state: NotificationCenterState) => {
    let id: string;
    do {
        id = uuidV4();
    } while (state.notifications.filter(notif => notif.id.trim().toLowerCase() === id.trim().toLowerCase()).length > 0);
    return id.trim().toLowerCase();
};

const push_notification = (state: NotificationCenterState, action: any): NotificationCenterState => {
    const new_notification = action.notification as NewNotification;
    const notification: Notification = {
        id: generate_id(state),
        read: false,
        ...new_notification
    };
    return {
        ...state,
        notifications: [...state.notifications, notification]
    }
}

const clear_notifications = (state: NotificationCenterState): NotificationCenterState => ({
    ...state,
    notifications: []
});

const mark_notification_read = (state: NotificationCenterState, action: any): NotificationCenterState => {
    const notification_index = state.notifications.findIndex(notification => notification.id.trim().toLowerCase() === action.id.trim().toLowerCase());
    if (notification_index === -1) return {...state};
    const notifications = [...state.notifications];
    notifications[notification_index] = {
        ...notifications[notification_index],
        read: true
    };
    return {
        ...state,
        notifications: notifications
    }
};

const reducer = (state = initial_state, action: any) => {
    switch (action.type) {
        case action_types.NOTIFICATIONS_SET_SHOW:
            return set_show(state, action);
        case action_types.NOTIFICATIONS_PUSH_NOTIFICATION:
            return push_notification(state, action);
        case action_types.NOTIFICATIONS_MARK_READ:
            return mark_notification_read(state, action);
        case action_types.NOTIFICATIONS_CLEAR:
            return clear_notifications(state);
        default:
            return state;
    }
};

export default reducer;
