import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { GeneralActions } from '../../store/actions/GeneralActions';
import StateType from '../../store/store';

const map_state_to_props = (state: StateType) => ({
    state: {
        tooltip: state.general.tooltip
    }
});

const map_dispatch_to_props = (dispatch: any) => ({
    actions: {
        clear_tooltip: () => dispatch(GeneralActions.clear_tooltip())
    }
});

const connector = connect(map_state_to_props, map_dispatch_to_props);

const TooltipModal = ({ state, actions }: ConnectedProps<typeof connector>) => {
    const { tooltip } = state;
    return (
        <Modal show={tooltip !== null} onHide={actions.clear_tooltip} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className={'me-auto ms-auto'} dangerouslySetInnerHTML={{ __html: tooltip || '' }} />
            </Modal.Header>
        </Modal>
    );
};

export default connector(TooltipModal);
