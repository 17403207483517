import BrokerAPI from "../../api/BrokerAPI";
import GeneralAPI from "../../api/GeneralAPI";
import {BrokerNotificationStatusDTO, BrokerUserDTO, CarrierIdGuidNameDTO, CarrierMinDTO} from "../../types";
import * as action_types from './actionTypes';
import {GeneralActions} from "./GeneralActions";
import AuthAPI from "../../api/AuthAPI";
import CarrierAPI from "../../api/CarrierAPI";
import BrokerStatusIds from "../../util/BrokerStatusIds";

export default class BrokerActions {

    static set_loading_broker_data(loading_broker_data: boolean) {
        return (dispatch: any) => {
            dispatch({type: action_types.BROKER_SET_LOADING_BROKER_DATA, loading_broker_data});
        };
    }

    static load_broker_data(broker_user: BrokerUserDTO, token: string) {
        return async (dispatch: any) => {
            dispatch(BrokerActions.set_loading_broker_data(true));
            const [time_zones, lead_preferred_contact_methods, status] = await Promise.all([
                GeneralAPI.get_time_zones(),
                GeneralAPI.get_lead_preferred_contact_methods(),
                AuthAPI.get_status(token)
            ]);
            const broker_zone = time_zones.find(time_zone => time_zone.name === broker_user.timeZone);
            dispatch(GeneralActions.set_timezones(time_zones, broker_zone));
            dispatch(GeneralActions.set_lead_preferred_contact_methods(lead_preferred_contact_methods));
            const broker_notification_status = status as BrokerNotificationStatusDTO;
            if (broker_notification_status.associationRequests.length > 0) {
                dispatch(BrokerActions.go_to_association(broker_notification_status.associationRequests[0].carrier));
            }
            dispatch(BrokerActions.set_loading_broker_data(false));
        };
    };

    static go_to_association(carrier: CarrierMinDTO) {
        return (dispatch: any) => {
            dispatch({type: action_types.BROKER_SET_ASSOCIATION_CARRIER, association_carrier: carrier});
            dispatch(GeneralActions.nav_to('/association'));
        }
    }

    static clear_association() {
        return (dispatch: any) => {
            dispatch({type: action_types.BROKER_SET_ASSOCIATION_CARRIER, association_carrier: null});
        };
    }

    static set_broker_profile(broker_user: BrokerUserDTO) {
        const excluded_statuses = new Set<number>([
            BrokerStatusIds.INACTIVE,
            BrokerStatusIds.DECLINED,
        ]);
        return (dispatch: any) => {
            const min_carriers = broker_user.brokerData.carriers
                .filter((broker_carrier): boolean => !excluded_statuses.has(broker_carrier.statusId))
                .map((broker_carrier): CarrierIdGuidNameDTO => ({
                    alias: broker_carrier.carrier.alias,
                    guid: broker_carrier.carrier.guid,
                    id: broker_carrier.carrier.id,
                    name: broker_carrier.carrier.name
                }));
            dispatch({type: action_types.BROKER_SET_MIN_CARRIERS, min_carriers});
            if (min_carriers.length > 0) {
                const selected_carrier_guid = sessionStorage.getItem('selected_carrier_guid');
                dispatch(BrokerActions.set_selected_carrier_guid(selected_carrier_guid ?? min_carriers[0].guid));
            }
        };
    }

    static load_carrier_branding(guid: string) {
        return async (dispatch: any) => {
            if (!guid || guid.length === 0) {
                dispatch(GeneralActions.clear_carrier_branding());
                return;
            }
            const carrier_branding = await CarrierAPI.get_carrier_branding_configuration(guid);
            if (!carrier_branding.show_broker_branding) {
                dispatch(GeneralActions.clear_carrier_branding());
                return;
            }
            dispatch(GeneralActions.set_carrier_branding(carrier_branding));
        };
    }

    static set_selected_carrier_guid(guid: string) {
        return (dispatch: any) => {
            sessionStorage.setItem('selected_carrier_guid', guid);
            dispatch(this.load_carrier_branding(guid));
            dispatch({type: action_types.BROKER_SET_SELECTED_CARRIER_GUID, selected_carrier_guid: guid});
        };
    }

    static set_manual_override_master_toggle_switch(token: string, manual_override_master_toggle_switch: boolean) {
        return async (dispatch: any) => {
            dispatch(GeneralActions.show_spinning_wheel());
            try {
                await BrokerAPI.set_manual_override_master_toggle_switch(token, manual_override_master_toggle_switch);
                dispatch({
                    type: action_types.EXPERT_SET_MANUAL_OVERRIDE_MASTER_TOGGLE_SWITCH,
                    manual_override_master_toggle_switch
                });
            } catch (e) {
                dispatch(GeneralActions.logout_if_unauthorized(e));
            } finally {
                dispatch(GeneralActions.hide_spinning_wheel());
            }
        };
    }

    static set_availability(token: string, availability: boolean) {
        return async (dispatch: any) => {
            dispatch(GeneralActions.show_spinning_wheel());
            try {
                await BrokerAPI.set_availability(token, availability);
                dispatch({type: action_types.EXPERT_SET_AVAILABILITY, availability});
            } catch (e) {
                dispatch(GeneralActions.logout_if_unauthorized(e));
            } finally {
                dispatch(GeneralActions.hide_spinning_wheel());
            }
        }
    }

    static set_availibility_form_cache(availability_form: any) {
        return (dispatch: any) => {
            dispatch({type: action_types.BROKER_SET_AVAILABILITY_FORM_CACHE, availability_form});
        }
    }

    static reload_availability(access_token: string) {
        return async (dispatch: any) => {
            try {
                const availability = await BrokerAPI.get_availability(access_token);
                dispatch({type: action_types.EXPERT_SET_AVAILABILITY, availability});
            } catch (e) {

            }
        }
    }

}
