import React from "react";
import * as Input from "../../inputs";
import {Row, Col} from "react-bootstrap";
import InputElement from "../InputElement/InputElement";
import * as Inputs from "../../inputs";

type FormKeys<T extends Record<keyof T, Inputs.InputField>> = (keyof T)[];

type Props<T extends Record<keyof T, Inputs.InputField>> = {
    fields: T;
    changed(key: keyof T, value: any): void;
    group_className?: string;
    label_className?: string;
    included_keys?: FormKeys<T>;
    blurred?(key: keyof T): void;
    form_control_class_name?: string;
    columns: 1 | 2 | 3 | 4;
};

type InputElementRowProps<T extends Record<keyof T, Inputs.InputField>> = Props<T> & {
    row_keys: FormKeys<T>;
}

const InputElementRow = <T extends Record<keyof T, Inputs.InputField>>({
                             form_control_class_name,
                             label_className,
                             group_className,
                             blurred,
                             fields,
                             columns,
                             row_keys,
                             changed
                         }: InputElementRowProps<T>) => {
    return (
        <Row>
            {row_keys.map(key => {
                const field = fields[key];
                return (
                    <Col xs={12} md={Math.floor(12 / columns)} key={field.dom_id}>
                        <InputElement
                            changed={(value) => changed(key, value)}
                            element={field}
                            group_className={group_className}
                            label_className={label_className}
                            form_control_class_name={form_control_class_name}
                            blurred={blurred ? () => blurred(key) : undefined}/>
                    </Col>
                )
            })}
        </Row>
    )
};

const MultiColumnInputElementForm = <T extends Record<keyof T, Inputs.InputField>>(props: Props<T>) => {
    const {included_keys, fields, columns} = props;
    const keys = [...(included_keys || Object.keys(fields) as FormKeys<T>)];
    const chunked_keys: FormKeys<T>[] = [];
    while (keys.length > 0) {
        chunked_keys.push(keys.splice(0, columns));
    }
    return (
        <>{chunked_keys.map(key_row => <InputElementRow key={key_row.join('_')} row_keys={key_row} {...props} />)}</>
    );
}

export default MultiColumnInputElementForm;
