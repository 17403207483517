import {Col, Form, Row} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import * as Input from "../../../inputs";
import {GeneralActions} from "../../../store/actions/GeneralActions";
import BooleanElement from "../BooleanElement/BooleanElement";
import DateTimeElement from "../DateTimeElement/DateTimeElement";
import EmailElement from "../EmailElement/EmailElement";
import {InputElementProps} from '../InputElement';
import MultiSelectElement from "../MultiSelectElement/MultiSelectElement";
import PhoneNumberElement from "../PhoneNumberElement/PhoneNumberElement";
import ReadOnlyTextElement from "../ReadOnlyTextElement/ReadOnlyTextElement";
import SingleFileSelectElement from "../SingleFileSelectElement/SingleFileSelectElement";
import SingleSelectElement from "../SingleSelectElement/SingleSelectElement";
import TextAreaElement from "../TextAreaElement/TextAreaElement";
import TextElement from "../TextElement/TextElement";
import TimeRangeElement from "../TimeRangeElement/TimeRangeElement";
import TypeAheadSingleElement from "../TypeAheadSingleElement/TypeAheadSingleElement";
import RadioElement from "../RadioElement/RadioElement";
import ColorElement from "../ColorElement/ColorElement";

import styles from './FormGroupElement.module.scss';
import ToggleButtonElement from "../ToggleButtonElement/ToggleButtonElement";
import DateTimeRangeElement from "../DateTimeRangeElement/DateTimeRangeElement";
import {FC} from "react";

const map_dispatch_to_props = (dispatch: any) => ({
    actions: {
        show_tooltip: (tooltip: string) => dispatch(GeneralActions.show_tooltip(tooltip))
    }
});

const connector = connect(null, map_dispatch_to_props);

type Props = InputElementProps & ConnectedProps<typeof connector>;

const character_count = ({element}: { element: Input.InputField }) => {
    const values = ((): { text_value: string; max_length: number; } | undefined => {
        if (element.type === Input.FieldDataType.TextArea) {
            const {value, max_length} = element as Input.TextAreaField;
            if (!max_length && max_length !== 0) return;
            return {text_value: value, max_length};
        }
        return;
    })();
    if (!values) return null;
    const {text_value, max_length} = values;
    return `(${text_value.length}/${max_length})`;
};

const ControlElement: FC<Props> = (props: Props) => {
    const {
        element,
        changed,
        blurred,
        form_control_class_name,
        lang,
    } = props;

    switch (element.type) {
        case Input.FieldDataType.Text:
            return <TextElement element={element as Input.TextField}
                                changed={changed}
                                blurred={blurred}
                                form_control_class_name={form_control_class_name}/>;
        case Input.FieldDataType.SingleSelect:
            return <SingleSelectElement element={element as Input.SingleSelectField<any>}
                                        changed={changed}
                                        blurred={blurred}
                                        form_control_class_name={form_control_class_name}/>;
        case Input.FieldDataType.DateTime:
            return <DateTimeElement element={element as Input.DateTimeField} changed={changed}/>;
        case Input.FieldDataType.PhoneNumber:
            return <PhoneNumberElement element={element as Input.PhoneNumberField}
                                       changed={changed}
                                       blurred={blurred}
                                       form_control_class_name={form_control_class_name}/>;
        case Input.FieldDataType.Email:
            return <EmailElement element={element as Input.EmailField} changed={changed} blurred={blurred}/>;
        case Input.FieldDataType.Boolean:
            return <BooleanElement element={element as Input.BooleanField} changed={changed} blurred={blurred}
                                   lang={lang}/>;
        case Input.FieldDataType.MultiSelect:
            return <MultiSelectElement element={element as Input.MultiSelectField<any>} changed={changed}
                                       blurred={blurred}/>;
        case Input.FieldDataType.TextArea:
            return <TextAreaElement element={element as Input.TextAreaField} changed={changed} blurred={blurred}/>;
        case Input.FieldDataType.TypeAheadSingle:
            return <TypeAheadSingleElement element={element as Input.TypeAheadSingleField<any>} changed={changed}
                                           blurred={blurred}/>;
        case Input.FieldDataType.SingleFileSelect:
            return <SingleFileSelectElement element={element as Input.SingleFileSelect} changed={changed}
                                            blurred={blurred}/>;
        case Input.FieldDataType.ReadOnlyText:
            return <ReadOnlyTextElement element={element as Input.ReadOnlyText}/>;
        case Input.FieldDataType.TimeRange:
            return <TimeRangeElement element={element as Input.TimeRangeField} changed={changed}
                                     blurred={blurred}/>;
        case Input.FieldDataType.Radio:
            return <RadioElement element={element as Input.RadioField<any>} changed={changed} blurred={blurred}/>;
        case Input.FieldDataType.Color:
            return <ColorElement element={element as Input.ColorField} changed={changed}/>;
        case Input.FieldDataType.ToggleButton:
            return <ToggleButtonElement element={element as Input.ToggleButtonField<any>} changed={changed}
                                        blurred={blurred}/>;
        case Input.FieldDataType.DateTimeRange:
            return <DateTimeRangeElement element={element as Input.DateTimeRangeField} changed={changed}
                                         blurred={blurred} form_control_class_name={form_control_class_name}/>;
    }
    return null;
}

const FormGroupElement = (props: Props) => {
    const {
        element,
        group_className,
        label_className,
        actions,
    } = props;
    const char_count = character_count({element});

    if (element.floating_label && (element.label || element.label_node)) {
        return (
            <Row>
                {element.tooltip ? (
                    <Col xs={'auto'} className={'pe-0'}>
                        <span className={styles.TooltipIcon} onClick={() => {
                            if (element.tooltip) actions.show_tooltip(element.tooltip)
                        }}>
                            <i className={'bi bi-info fs-5'}/>
                        </span>
                    </Col>
                ) : null}
                <Col>
                    <Form.Group controlId={element.dom_id} className={group_className}>
                        <Form.Floating>
                            <ControlElement {...props}/>
                            <label htmlFor={element.dom_id}>
                                {element.label_node ? <>{element.label_node}{char_count ? ` ${char_count}` : ''}</> : (element.label ?
                                    <span
                                        dangerouslySetInnerHTML={{__html: `${element.label}${char_count ? ` ${char_count}` : ''}`}}/> : null)}

                            </label>
                        </Form.Floating>
                    </Form.Group>
                </Col>
            </Row>
        );
    }

    return (
        <Form.Group as={element.label_span ? Row : 'div'} controlId={element.dom_id} className={group_className}>
            {element.label || element.label_node ?
                <Col xs={12} sm={element.label_span || undefined} className={label_className}>
                    {element.label ? <Form.Label
                            dangerouslySetInnerHTML={{__html: `${element.label}${char_count ? ` ${char_count}` : ''}`}}/> :
                        <Form.Label>{element.label_node}{char_count ? ` ${char_count}` : ''}</Form.Label>}
                    {element.tooltip ?
                        (<span className={styles.TooltipIcon} onClick={() => {
                            if (element.tooltip) actions.show_tooltip(element.tooltip)
                        }}>
                            <i className={'bi bi-info fs-5'}/>
                        </span>)
                        : null}
                </Col> :
                null}
            <ControlElement {...props}/>
        </Form.Group>
    )
};

export default connector(FormGroupElement);
