import {Button, ButtonGroup, Card, CloseButton, Col, Image, Row} from "react-bootstrap";
import * as Input from '../../inputs';
import VerticalInputElementForm from "../VerticalInputElementForm/VerticalInputElementForm";
import {SimpleNumberSelectOption} from "../../types";
import MultiColumnInputElementForm from "../MultiColumnInputElementForm/MultiColumnInputElementForm";

export enum LogoVisibilityTypes {
    SYSTEM = 1,
    LLP = 2,
    SYSTEM_AND_LLP = 3
}

export interface HeaderLogoElementForm extends Record<string, Input.InputField> {
    is_image: Input.CheckboxField;
    include_spanish: Input.CheckboxField;
    src: Input.TextField;
    alt: Input.TextField;
    src_es: Input.TextField;
    alt_es: Input.TextField;
    target: Input.TextField;
    visibility: Input.SingleSelectField<SimpleNumberSelectOption>;
}

type Props = {
    form: HeaderLogoElementForm;
    on_form_change: (key: string, value: any) => void;
    on_remove: () => void;
    spanish_cols: 1 | 2;
};

const HeaderLogoElementCard = ({
                                   form,
                                   on_form_change,
                                   on_remove,
                                   spanish_cols,
                               }: Props) => {
    const render_image_preview = () => {
        if (!form.is_image.value) return null;
        return (
            <Image src={form.src.value} alt={form.alt.value} fluid/>
        );
    };

    return (
        <Card className={'h-100'}>
            <Card.Body>
                <CloseButton onClick={() => on_remove()} className={'position-absolute end-0 top-0 mt-2 me-2'}/>
                <Row className={'mb-2 justify-content-between mt-2'}>
                    <Col xs={12} sm={'auto'}>
                        <ButtonGroup>
                            <Button
                                type={'button'}
                                variant={'outline-secondary'}
                                active={form.is_image.value}
                                onClick={() => on_form_change('is_image', true)}
                            >Image</Button>
                            <Button
                                type={'button'}
                                variant={'outline-secondary'}
                                active={!form.is_image.value}
                                onClick={() => on_form_change('is_image', false)}
                            >Text</Button>
                        </ButtonGroup>
                    </Col>
                    <Col xs={12} sm={'auto'}>
                        <Button
                            type={'button'}
                            variant={'outline-secondary'}
                            active={form.include_spanish.value}
                            onClick={() => on_form_change('include_spanish', !form.include_spanish.value)}
                        >Spanish?</Button>
                    </Col>
                </Row>
                <MultiColumnInputElementForm
                    fields={form}
                    changed={on_form_change}
                    group_className={'mb-2'}
                    included_keys={form.include_spanish.value ? ['src', 'src_es', 'alt', 'alt_es'] : ['src', 'alt']}
                    columns={form.include_spanish.value ? spanish_cols : 1}
                />
                <VerticalInputElementForm
                    fields={form}
                    changed={on_form_change}
                    group_className={'mb-2'}
                    included_keys={['target', 'visibility',]}
                />
                {render_image_preview()}
            </Card.Body>
        </Card>
    )
};

export const header_logo_element_form_src_label = (is_image: boolean, lang: 'EN' | 'ES' = 'EN') => {
    if (lang === 'ES') return is_image ? 'Spanish Image URL (Optional)' : 'Spanish Text (Optional)';
    return is_image ? 'Image URL' : 'Text';
};

export const header_logo_element_form_alt_text_label = (is_image: boolean, lang: 'EN' | 'ES' = 'EN') => {
    if (lang === 'ES') return is_image ? 'Spanish Image Alt Text (Optional)' : 'Spanish Link Title (Optional)';
    return is_image ? 'Image Alt Text (Optional)' : 'Link Title (Optional)';
};

export default HeaderLogoElementCard;
