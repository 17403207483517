import React from "react";
import {Col, Form} from "react-bootstrap";
import * as Inputs from '../../../inputs';

type Props = {
    element: Inputs.BooleanField;
    changed(value: any): void;
    blurred?(): void;
    group_className?: string;
    label_className?: string;
    lang?: 'en' | 'sp';
};

const BooleanElement = ({element, changed, blurred, label_className, lang,}: Props) => {
    const render_form_control = () => (
        <div>
            <Form.Check
                type={'radio'}
                inline
                id={element.dom_id + '_true'}
                checked={element.value}
                label={lang === 'sp' ? 'Sí' : 'Yes'}
                name={element.name || element.dom_id}
                disabled={!!element.disabled}
                onBlur={blurred}
                onChange={(event) => changed(true)}
            />
            <Form.Check
                type={'radio'}
                inline
                id={element.dom_id + '_false'}
                checked={!element.value}
                label={'No'} // No need to check for spanish here since it's the same in both languages.
                name={element.name || element.dom_id}
                disabled={!!element.disabled}
                onBlur={blurred}
                onChange={(event) => changed(false)}
            />
        </div>
    );
    const render_error = () => {
        if (!element.error) return null;
        return (<span className={'text-danger'}>{element.error}</span>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
};

export default BooleanElement;
