import {ContactBannerDocument, TextAlignedColorUrl} from "../../BrandingTypes";
import {Col, Container, Row} from "react-bootstrap";

import styles from './ContactBanner.module.scss';

type Props = {
    banner: ContactBannerDocument;
    branding_language: "en" | "es";
};

const ContactBanner = ({banner, branding_language}: Props) => {
    const {color: bg_color, text: all_text} = banner;
    const text: TextAlignedColorUrl[] = (() => {
        if (branding_language === 'en') return all_text.filter(t => (t.lang ?? 'en') === branding_language);
        const es_text = all_text.filter(t => (t.lang ?? 'en') === branding_language);
        if (es_text.length > 0) return es_text;
        return [...all_text];
    })();
    const render_contact_banner_text = () => {
        if (text.length === 0) return null;
        const left_text = text.filter(t => t.alignment === 'left');
        const right_text = text.filter(t => t.alignment === 'right');
        const render_text_col = (texts: TextAlignedColorUrl[], is_right?: boolean) => {
            if (texts.length === 0) return null;
            const render_text = (t: TextAlignedColorUrl, index: number, is_last: boolean) => {
                return (
                    <span key={index} className={is_last ? 'me-0' : 'me-1'} style={{color: t.color ?? '#FFF'}}>
                        {t.href ? <a href={t.href} style={{color: t.color ?? '#FFF'}}>{t.text}</a> : t.text}
                    </span>
                )
            };
            return (
                <Col xs={'auto'} className={is_right ? 'ms-auto text-end' : undefined}>
                    {texts.map((t, index) => render_text(t, index, index === texts.length - 1))}
                </Col>
            )
        };
        return (
            <Container fluid>
                <Row>
                    {render_text_col(left_text)}
                    {render_text_col(right_text, true)}
                </Row>
            </Container>
        )
    };
    return (
        <div className={styles.ContactBanner} style={{backgroundColor: bg_color}}>
            {render_contact_banner_text()}
        </div>
    )
};

export default ContactBanner;
