import React, {useRef, useState} from "react";
import {Col, Form, InputGroup, Overlay, OverlayTrigger, Popover} from "react-bootstrap";
import {SketchPicker} from 'react-color';

import * as Input from '../../../inputs';

type Props = {
    element: Input.ColorField;
    changed(new_value: any): void;
};

const ColorElement = ({element, changed}: Props) => {
    const target = useRef(null);
    const [show_color_picker, set_show_color_picker] = useState(false);

    const interpret_color = (val: string): string => {
        const ctx = document.createElement('canvas').getContext('2d');
        if (ctx) ctx.fillStyle = val;
        return `${ctx?.fillStyle ?? ''}`;
    };

    const on_focus = () => {
        const interpreted_color = interpret_color(element.value);
        if (interpreted_color.toLowerCase() !== element.value.toLowerCase()) {
            changed(interpreted_color)
        }
        set_show_color_picker(true);
    };

    const render_form_control = () => {
        return (
            <>
                <InputGroup ref={target}>
                    <InputGroup.Text
                        style={{backgroundColor: element.value}}
                    >&nbsp;</InputGroup.Text>
                    <Form.Control
                        type='text'
                        name={element.name || element.dom_id}
                        value={element.value}
                        onChange={event => changed(event.target.value)}
                        isInvalid={!element.valid && element.touched}
                        disabled={!!element.disabled}
                        isValid={!element.hide_valid && element.valid}
                        onFocus={on_focus}/>
                </InputGroup>
                <Overlay target={target.current} show={show_color_picker} placement={'bottom-start'}>
                    {({placement, arrowProps, show: _show, popper, ...props}) => {
                        if (!_show) return <></>;
                        return (
                            <>
                                <div
                                    className={'position-fixed top-0 start-0 bottom-0 end-0'}
                                    style={{zIndex: '1500'}}
                                    onClick={() => set_show_color_picker(false)}/>
                                <div
                                    {...props}
                                    style={{
                                        position: 'absolute',
                                        zIndex: '2000',
                                        ...props.style
                                    }}>
                                    <SketchPicker
                                        color={element.value}
                                        onChange={(color) => changed(color.hex)}

                                    />
                                </div>
                            </>
                        );
                    }}
                </Overlay>
            </>
        );
    };
    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
};

export default ColorElement;
