import React, { useState } from "react";
import { Col, Dropdown } from "react-bootstrap";

import * as Input from '../../../inputs';

import styles from './MultiSelectElement.module.scss';

type Props = {
    element: Input.MultiSelectField<any>;
    blurred?(): void;
    changed(new_value: any): void;
};

const MultiSelectElement = ({ element, changed}: Props) => {
    const [selected_all, set_selected_all] = useState<boolean>(false);
    const select_all = () => {
        set_selected_all(!selected_all);
        changed(selected_all ? [] : element.options);
    };

    const render_form_control = () => (
        <Dropdown className={`d-grid ${styles.EqualWidthGrid}`} autoClose={'outside'}>
            <Dropdown.Toggle
                id={element.dom_id + '-toggle'}
                variant={'multiselect'}
                className={'text-start'}
                title={element.value.map(option => element.option_to_display_string(option)).join(', ')}
            >{element.value.length === 0 ? `Select ${element.set_name}` : (
                <span className={styles.DropDownTextOverFlow}>{element.value.map(option => element.option_to_display_string(option)).join(', ')}</span>
            )}
            </Dropdown.Toggle>

            <Dropdown.Menu className={styles.MutliSelectDropdownMenu}>
                {element.show_select_all ? (
                    <Dropdown.Item key={`${element.dom_id}_SELECT_ALL`} onClick={select_all} className={styles.MultiSelectItem}>
                        <span className={`${styles.MultiSelectCheck}${selected_all ? ' ' + styles.Active : ''}`} />SELECT ALL
                    </Dropdown.Item>
                ) : null}
                {element.options.map((option, index) => {
                    const option_string = element.option_to_value_string(option);
                    const selected = element.value.map(element.option_to_value_string).filter(val => option_string === val).length > 0;
                    const on_click = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                        event.preventDefault();
                        if (selected) {
                            // deselect
                            changed(element.value.filter(value => element.option_to_value_string(value) !== option_string));
                        } else {
                            const value_with_option = [...element.value.map(element.option_to_value_string), option_string];
                            changed(element.options.filter(value => value_with_option.filter(selected_val => selected_val === element.option_to_value_string(value)).length > 0));
                        }
                    };
                    return (
                        <Dropdown.Item key={`${element.dom_id}_${index}`} onClick={on_click} className={styles.MultiSelectItem}>
                            <span className={`${styles.MultiSelectCheck}${selected ? ' ' + styles.Active : ''}`} />{element.option_to_display_string(option)}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
};

export default MultiSelectElement;
