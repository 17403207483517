import * as Input from "../../../inputs";
import React, {useEffect, useState} from "react";
import {Button, Col, Form, InputGroup, OverlayTrigger, Popover, Row, Table} from "react-bootstrap";
import moment from "moment-timezone";

import styles from './DateTimeRangeElement.module.scss';
import ConditionalComponent from "../../../hoc/ConditionalComponent/ConditionalComponent";
import {ButtonVariant} from "react-bootstrap/types";

type Props = {
    element: Input.DateTimeRangeField;
    blurred?(): void;
    changed(new_value: any): void;
    form_control_class_name?: string;
};

const Error = ({element}: Props) => {
    if (!element.error) return null;
    return (<div className={'text-danger'}>{element.error}</div>);
};

type InputGroupProps = Props & {
    show_popover: boolean;
    set_show_popover(show: boolean): void;
};

type DateTableSelectorRange = 'month' | 'year' | 'decade';

type DateTableSelectorProps = {
    element: Input.DateTimeRangeField;
    month_start: moment.Moment;
    set_month_start: (month_start: moment.Moment) => void;
    set_from_date: (from_date: moment.Moment) => void;
    set_to_date: (to_date: moment.Moment) => void;
    from: moment.Moment | null;
    to: moment.Moment | null;
    has_end_date: boolean;
    readonly?: boolean;
}

type DateTableProps = DateTableSelectorProps & {
    set_selector_range: (range: DateTableSelectorRange) => void;
};

const MonthTable = ({
                        month_start,
                        set_month_start,
                        set_to_date,
                        set_from_date,
                        to,
                        from,
                        has_end_date,
                        set_selector_range,
                        element,
                    }: DateTableProps) => {
    const {is_date_selectable} = element;
    const first_day = (() => {
        if (month_start.day() === 0) return moment(month_start).subtract(1, 'week');
        return moment(month_start).startOf('week');
    })();
    const last_day = moment(first_day).add(5, 'weeks').endOf('week');
    const weeks: moment.Moment[][] = [];
    const current_day = moment(first_day);
    while (current_day.isBefore(last_day)) {
        const week: moment.Moment[] = [];
        for (let i = 0; i < 7; i++) {
            week.push(moment(current_day));
            current_day.add(1, 'day');
        }
        weeks.push(week);
    }
    const selected_from = from ? moment(from).startOf('day') : null;
    const selected_to = to ? moment(to).endOf('day') : null;
    const prev = () => set_month_start(moment(month_start).subtract(1, 'month'));
    const next = () => set_month_start(moment(month_start).add(1, 'month'));

    const on_day_click = (day: moment.Moment) => {
        const clicked_day = moment(day).startOf('day');
        if (is_date_selectable && !is_date_selectable(clicked_day)) return;
        if (!has_end_date) {
            set_from_date(clicked_day);
            return;
        }
        if (!selected_from && !selected_to) {
            set_from_date(clicked_day);
            return;
        }
        if (selected_from && selected_to) {
            if (clicked_day.isBefore(selected_from, 'day')) {
                set_from_date(clicked_day);
                return;
            }
            if (clicked_day.isAfter(selected_to, 'day')) {
                set_to_date(clicked_day.endOf('day'));
                return;
            }
            set_from_date(clicked_day);
            set_to_date(clicked_day.endOf('day'));
            return;
        }
        if (selected_from) {
            if (clicked_day.isBefore(selected_from, 'day')) {
                set_from_date(clicked_day);
                return;
            }
            set_to_date(clicked_day.endOf('day'));
            return;
        }
        if (clicked_day.isSameOrBefore(selected_to, 'day')) {
            set_from_date(clicked_day);
            return;
        }
        set_to_date(clicked_day.endOf('day'));
    }
    const is_day_selected = (day: moment.Moment) => {
        if (!selected_from) return false;
        if (!has_end_date) {
            return day.isSameOrAfter(selected_from, 'day');
        }
        if (!selected_to) return false;
        return day.isBetween(selected_from, selected_to, 'day', '[]');
    }
    return (
        <Table borderless>
            <thead>
            <tr>
                <th
                    className={`text-center ${styles.ClickableTableColumn}`}
                    onClick={prev}
                >&lt;</th>
                <th
                    colSpan={5}
                    className={`text-center ${styles.ClickableTableColumn}`}
                    onClick={() => set_selector_range('year')}
                >{month_start.format('MMMM YYYY')}</th>
                <th
                    className={`text-center ${styles.ClickableTableColumn}`}
                    onClick={next}
                >&gt;</th>
            </tr>
            <tr>
                {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
                    <th
                        key={day}
                        className={'text-center'}
                    >{day}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {weeks.map((week, week_index) => (
                <tr key={week_index}>
                    {week.map((day) => {
                        const classes: string[] = [...new Set<string>([
                            'text-center',
                            'py-1',
                            ...(is_day_selected(day) ? [styles.SelectedDate] : []),
                            ...(is_date_selectable && !is_date_selectable(day) ? ['text-muted', styles.DisabledClickableTableColumn] : []),
                            styles.ClickableTableColumn,
                            ...(day.isSame(month_start, 'month') ? [] : ['text-muted', styles.DifferentMonth]),
                            ...(day.isSame(moment(), 'day') ? [styles.Today] : []),
                        ])];
                        return (
                            <td key={day.format('YYYY-MM-DD')}
                                onClick={() => on_day_click(day)}
                                className={classes.join(' ')}>
                                {day.format('D')}
                            </td>
                        );
                    })}
                </tr>
            ))}
            </tbody>
        </Table>
    )
};

const YearTable = ({month_start, set_month_start, set_selector_range}: DateTableProps) => {
    const year_start = moment(month_start).startOf('year');
    const months = (() => {
        const current_month = moment(year_start);
        const months: moment.Moment[][] = [];
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 4; j++) {
                if (!months[i]) months[i] = [];
                months[i].push(moment(current_month));
                current_month.add(1, 'month');
            }
        }
        return months;
    })();
    const on_prev_year = () => set_month_start(moment(month_start).subtract(1, 'year'));
    const on_next_year = () => set_month_start(moment(month_start).add(1, 'year'));
    const on_month_click = (month: moment.Moment) => {
        set_month_start(moment(month).startOf('month'));
        set_selector_range('month');
    }
    return (
        <Table borderless>
            <thead>
            <tr>
                <th
                    className={`text-center ${styles.ClickableTableColumn}`}
                    onClick={on_prev_year}
                >&lt;</th>
                <th
                    colSpan={2}
                    className={`text-center ${styles.ClickableTableColumn}`}
                    onClick={() => set_selector_range('decade')}
                >{year_start.format('YYYY')}
                </th>
                <th
                    className={`text-center ${styles.ClickableTableColumn}`}
                    onClick={on_next_year}
                >&gt;</th>
            </tr>
            </thead>
            <tbody>
            {months.map((month_row, month_row_index) => (
                <tr key={month_row_index}>
                    {month_row.map((month) => {
                        const classes: string[] = [
                            'text-center',
                            'py-3',
                            styles.ClickableTableColumn,
                        ];
                        return (
                            <td key={month.format('YYYY-MM')}
                                onClick={() => on_month_click(month)}
                                className={classes.join(' ')}>
                                {month.format('MMM')}
                            </td>
                        );
                    })}
                </tr>
            ))}
            </tbody>
        </Table>
    )
};

const DecadeTable = ({month_start, set_month_start, set_selector_range}: DateTableProps) => {
    const decade_start = (() => {
        const year = moment(month_start).year();
        return moment(month_start).year((year - (year % 10)) - 1).startOf('year');
    })();
    const decade_end = moment(decade_start).add(11, 'year');
    const years = (() => {
        const current_year = moment(decade_start);
        const years: moment.Moment[][] = [];
        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 4; j++) {
                if (!years[i]) years[i] = [];
                years[i].push(moment(current_year));
                current_year.add(1, 'year');
            }
        }
        return years;
    })();
    console.log(years.map(year_row => year_row.map(year => year.format('YYYY'))));
    const on_prev_decade = () => set_month_start(moment(decade_start).subtract(1, 'year'));
    const on_next_decade = () => set_month_start(moment(decade_end).add(1, 'year'));

    const on_year_click = (year: moment.Moment) => {
        set_month_start(moment(year).startOf('year'));
        set_selector_range('year');
    }

    return (
        <Table borderless>
            <thead>
            <tr>
                <th
                    className={`text-center ${styles.ClickableTableColumn}`}
                    onClick={on_prev_decade}
                >&lt;</th>
                <th
                    colSpan={2}
                    className={`text-center ${styles.ClickableTableColumn}`}
                >{decade_start.format('YYYY')} - {decade_end.format('YYYY')}
                </th>
                <th
                    className={`text-center ${styles.ClickableTableColumn}`}
                    onClick={on_next_decade}
                >&gt;</th>
            </tr>
            </thead>
            <tbody>
            {years.map((year_row, year_row_index) => (
                <tr key={year_row_index}>
                    {year_row.map((year) => {
                        const classes: string[] = [
                            'text-center',
                            'py-3',
                            styles.ClickableTableColumn,
                        ];
                        return (
                            <td key={year.format('YYYY')}
                                onClick={() => on_year_click(year)}
                                className={classes.join(' ')}>
                                {year.format('YYYY')}
                            </td>
                        );
                    })}
                </tr>
            ))}
            </tbody>
        </Table>
    )
}

const DateTableSelector = (props: DateTableSelectorProps) => {
    const [current_range, set_current_range] = useState<DateTableSelectorRange>('month');
    switch (current_range) {
        case 'month':
            return <MonthTable {...props} set_selector_range={set_current_range}/>;
        case 'year':
            return <YearTable {...props} set_selector_range={set_current_range}/>;
        case 'decade':
            return <DecadeTable {...props} set_selector_range={set_current_range}/>;
    }
};

type DateTimeRangePopoverBodyProps = InputGroupProps & {
    hide_popover: () => void;
    clear_relative_option: () => void;
};

type DateTimeRangePopoverTextFieldsProps = DateTimeRangePopoverBodyProps & {
    label: string;
    show_time_field: boolean;
    date_string: string;
    set_date_string(date_string: string): void;
    time_string: string;
    set_time_string(time_string: string): void;
    on_next_moment: (next_moment: moment.Moment) => void;
    dom_id: string;
    checkbox_label?: React.ReactNode;
    checkbox_value: boolean;
    show_checkbox: boolean;
    checkbox_on_change?: (next_value: boolean) => void;
    title?: string;
    is_am: boolean;
    invalid?: boolean;
    set_is_am: React.Dispatch<React.SetStateAction<boolean>>;
};

const DateTimeRangePopoverTextFields = (props: DateTimeRangePopoverTextFieldsProps) => {
    const {
        label,
        show_time_field,
        date_string,
        time_string,
        set_date_string,
        set_time_string,
        on_next_moment,
        element,
        dom_id,
        checkbox_label,
        checkbox_value,
        show_checkbox,
        checkbox_on_change,
        title,
        is_am,
        set_is_am,
        invalid,
    } = props;
    const {is_date_selectable} = element;

    useEffect(() => {
        if (!show_time_field) return;
        set_time_next_value(time_string);
    }, [is_am, show_time_field]);

    const on_valid_moment = (next_date_string: string, next_time_string: string) => {
        const next_moment = moment(`${next_date_string}${show_time_field ? ` ${next_time_string}` : ''}`, `MM/DD/YYYY${show_time_field ? ' hh:mm A' : ''}`);
        if (!next_moment.isValid()) return;
        if (is_date_selectable && !is_date_selectable(next_moment)) return;
        on_next_moment(next_moment);
    };

    const set_date_next_value = (next_value: string) => {
        set_date_string(next_value);
        const next_moment = moment(next_value, 'MM/DD/YYYY');
        if (next_moment.isValid()) {
            on_valid_moment(next_value, time_string);
        }
    };

    const set_time_next_value = (next_value: string) => {
        set_time_string(next_value);
        const next_time_string_value = `${next_value} ${is_am ? 'AM' : 'PM'}`;
        const next_moment = moment(next_time_string_value, 'hh:mm A');
        if (next_moment.isValid()) {
            on_valid_moment(date_string, next_time_string_value);
        }
    };

    const on_date_string_change = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_date_next_value(event.target.value.replaceAll(/[^0-9\/]/g, ''));
    }

    const on_time_string_change = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_time_next_value(event.target.value.replaceAll(/[^0-9:]/g, ''));
    }

    const on_checkbox_change = (event: React.ChangeEvent<HTMLInputElement>) => {
        checkbox_on_change && checkbox_on_change(event.target.checked);
    };

    return (
        <Form.Group>
            <Row className={'justify-content-between'}>
                <Col xs={'auto'}>
                    <Form.Label>{label}</Form.Label>
                </Col>
                <ConditionalComponent show={show_checkbox}>
                    <Col xs={'auto'}>
                        <Form.Check
                            label={checkbox_label}
                            id={`${element.dom_id}_popover_${dom_id}_checkbox`}
                            checked={checkbox_value}
                            onChange={on_checkbox_change}
                        />
                    </Col>
                </ConditionalComponent>
            </Row>
            <ConditionalComponent show={!checkbox_value}>
                <InputGroup>
                    <Form.Control
                        id={`${element.dom_id}_popover_${dom_id}_date`}
                        placeholder={'MM/DD/YYYY'}
                        value={date_string}
                        onChange={on_date_string_change}
                        className={show_time_field ? styles.PopoverDateField : undefined}
                        isInvalid={invalid === true}
                        title={title ? `${title} Date` : undefined}
                        autoComplete={'off'}
                    />
                    <ConditionalComponent show={show_time_field}>
                        <Form.Control
                            id={`${element.dom_id}_popover_${dom_id}_time`}
                            placeholder={'00:00'}
                            value={time_string}
                            onChange={on_time_string_change}
                            isInvalid={invalid === true}
                            title={title ? `${title} Time` : undefined}
                            autoComplete={'off'}
                        />
                        <Button
                            id={`${element.dom_id}_popover_${dom_id}_time_am_pm`}
                            title={title ? `${title} Time AM/PM` : undefined}
                            variant={'outline-secondary'}
                            onClick={() => set_is_am(current => !current)}
                        >{is_am ? 'AM' : 'PM'}</Button>
                    </ConditionalComponent>
                </InputGroup>
            </ConditionalComponent>
        </Form.Group>
    )
}

const DateTimeRangePopoverBody = (props: DateTimeRangePopoverBodyProps) => {
    const {element, hide_popover, changed, clear_relative_option} = props;
    const [current_month, set_current_month] = useState<moment.Moment>(moment(element.value.from ?? moment()).startOf('month'));
    const [from_date_string, set_from_date_string] = useState<string>(element.value.from ? element.value.from.format('MM/DD/YYYY') : '');
    const [from_time_string, set_from_time_string] = useState<string>(element.value.from ? element.value.from.format('hh:mm') : '');
    const [is_from_am, set_is_from_am] = useState<boolean>(element.value.from ? element.value.from.format('A') === 'AM' : true);
    const [to_date_string, set_to_date_string] = useState<string>(element.value.to ? element.value.to.format('MM/DD/YYYY') : '');
    const [to_time_string, set_to_time_string] = useState<string>(element.value.to ? element.value.to.format('hh:mm') : '');
    const [is_to_am, set_is_to_am] = useState<boolean>(element.value.to ? element.value.to.format('A') === 'AM' : true);
    const [from, set_from] = useState<moment.Moment | null>(element.value.from ?? null);
    const [to, set_to] = useState<moment.Moment | null>(element.value.to ?? null);
    const [to_no_end_checkbox_value, set_to_no_end_checkbox_value] = useState<boolean>(!!element.allow_open_ended && !!element.value.from && !element.value.to);
    const [from_invalid, set_from_invalid] = useState<boolean>(false);
    const [to_invalid, set_to_invalid] = useState<boolean>(false);
    const {
        title,
    } = element;
    useEffect(() => {
        if (to_no_end_checkbox_value) {
            set_to(null);
            set_to_date_string('');
            set_to_time_string('');
        }
    }, [to_no_end_checkbox_value])

    const set_from_date = (date: moment.Moment, update_strings: boolean) => {
        if (to && date.isAfter(to)) {
            set_from_invalid(true);
            return;
        }
        if (to) set_to_invalid(false);
        set_from_invalid(false);
        set_from(moment(date));
        if (update_strings) {
            set_from_date_string(date.format('MM/DD/YYYY'));
            if (element.mode === Input.DateTimeRangeModes.DateTime) {
                set_from_time_string(date.format('hh:mm'));
                set_is_from_am(date.format('A') === 'AM');
            }
        }
    }

    const set_to_date = (date: moment.Moment, update_strings: boolean) => {
        if (from && date.isBefore(from)) {
            set_to_invalid(true);
            return;
        }
        if (from) set_from_invalid(false);
        set_to_invalid(false);
        set_to(moment(date));
        if (update_strings) {
            set_to_date_string(date.format('MM/DD/YYYY'));
            if (element.mode === Input.DateTimeRangeModes.DateTime) {
                set_to_time_string(date.format('hh:mm'));
                set_is_to_am(date.format('A') === 'AM');
            }
        }
    }

    const apply_enabled = (() => {
        if (!element.allow_open_ended) return from && to && from.isValid() && to.isValid() && from.isSameOrBefore(to);
        if (!from || !from.isValid()) return false;
        if (!to_no_end_checkbox_value) return to && to.isValid() && from.isSameOrBefore(to);
        if (!to) return true;
        if (!to.isValid()) return false;
        return from.isSameOrBefore(to);
    })();

    const on_apply = () => {
        if (!apply_enabled) return;
        const should_set_to_date_on_apply = (() => {
            if (!element.allow_open_ended) return true;
            if (to_no_end_checkbox_value) return false;
            return to && to.isValid() && to.isSameOrAfter(from);
        })();
        changed({
            from: moment(from),
            ...(should_set_to_date_on_apply ? {to: moment(to)} : {}),
        });
        clear_relative_option();
        hide_popover();
    };

    return (
        <Popover.Body className={'p-2'}>
            <DateTableSelector
                element={element}
                month_start={current_month}
                set_month_start={set_current_month}
                set_from_date={(date: moment.Moment) => set_from_date(date, true)}
                set_to_date={(date: moment.Moment) => set_to_date(date, true)}
                from={from}
                to={to}
                has_end_date={!to_no_end_checkbox_value}
                readonly={element.read_only}
            />
            <Row className={'mb-2'}>
                <Col xs={12} className={'mb-2'}>
                    <DateTimeRangePopoverTextFields
                        {...props}
                        label={'From'}
                        show_time_field={element.mode === Input.DateTimeRangeModes.DateTime}
                        date_string={from_date_string}
                        set_date_string={set_from_date_string}
                        time_string={from_time_string}
                        set_time_string={set_from_time_string}
                        on_next_moment={(next_moment) => set_from_date(next_moment, false)}
                        dom_id={'from'}
                        checkbox_value={false}
                        show_checkbox={false}
                        title={title ? `${title} From` : undefined}
                        set_is_am={set_is_from_am}
                        is_am={is_from_am}
                        invalid={from_invalid}
                    />
                </Col>
                <Col xs={12}>
                    <DateTimeRangePopoverTextFields
                        {...props}
                        label={'To'}
                        show_time_field={element.mode === Input.DateTimeRangeModes.DateTime}
                        date_string={to_date_string}
                        set_date_string={set_to_date_string}
                        time_string={to_time_string}
                        set_time_string={set_to_time_string}
                        on_next_moment={(next_moment) => set_to_date(next_moment, false)}
                        dom_id={'to'}
                        show_checkbox={!!element.allow_open_ended}
                        checkbox_label={'No End Date'}
                        checkbox_value={!!element.allow_open_ended && to_no_end_checkbox_value}
                        checkbox_on_change={next_value => set_to_no_end_checkbox_value(!!element.allow_open_ended && next_value)}
                        title={title ? `${title} To` : undefined}
                        set_is_am={set_is_to_am}
                        is_am={is_to_am}
                        invalid={to_invalid}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={6} className={'d-grid'}>
                    <Button variant={'gray'} type={'button'} onClick={hide_popover}>Cancel</Button>
                </Col>
                <Col xs={6} className={'d-grid'}>
                    <Button variant={'green'} type={'button'} onClick={on_apply}
                            disabled={!apply_enabled}>Apply</Button>
                </Col>
            </Row>
        </Popover.Body>
    )
}

const relative_time_option_to_hours = (relative_option: Input.ExactRelativeTimeOption) => {
    switch (relative_option.unit) {
        case 'h':
            return relative_option.time;
        case 'd':
            return relative_option.time * 24;
        case 'w':
            return relative_option.time * 24 * 7;
        case 'M':
            return relative_option.time * 24 * 30;
        case 'y':
            return relative_option.time * 24 * 365;
    }
    return 0;
};

type RelativeOptionProps = InputGroupProps & {
    option: Input.RelativeTimeRangeOption,
    selected_relative_time: Input.RelativeTimeRangeOption | null,
    set_selected_relative_time: (option: Input.RelativeTimeRangeOption | null) => void,
};

const RelativeOption = (props: RelativeOptionProps) => {
    const {option, selected_relative_time, set_selected_relative_time} = props;
    const {element: {button_variant, disabled}} = props;
    const is_active = (() => {
        if (option.type === 'Exact' && selected_relative_time?.type === 'Exact') return selected_relative_time?.time === option.time && selected_relative_time?.unit === option.unit;
        if (option.type === 'Dynamic' && selected_relative_time?.type === 'Dynamic') return selected_relative_time?.option === option.option;
        return false;
    })();
    const option_text = (() => {
        switch (option.type) {
            case 'Exact':
                return `${option.time}${option.unit.toLowerCase()}`
            case 'Dynamic':
                return option.option;
        }
    })();
    return (
        <Button variant={button_variant}
                type={'button'}
                onClick={() => set_selected_relative_time(option)}
                className={option.button_class_name}
                disabled={disabled}
                active={is_active}
        >{option_text}</Button>
    )
}

const compare_dynamic_options = (a: Input.DynamicRelativeTimeOption, b: Input.DynamicRelativeTimeOption) => {
    return 0; // Right now there is only one option type, so we don't need to compare
};

const compare_relative_options = (a: Input.RelativeTimeRangeOption, b: Input.RelativeTimeRangeOption) => {
    if (a.type === 'Exact' && b.type === 'Exact') return relative_time_option_to_hours(a) - relative_time_option_to_hours(b);
    if (a.type === 'Dynamic' && b.type === 'Dynamic') return compare_dynamic_options(a, b);
    if (a.type === 'Exact' && b.type === 'Dynamic') {
        if (b.option === 'Today') return 1;
        return -1;
    }
    if (a.type === 'Dynamic' && b.type === 'Exact') {
        if (a.option === 'Today') return -1;
        return 1;
    }
    return 0;
};

const DateTimeRangeInputGroup = (props: InputGroupProps) => {
    const {element, set_show_popover, show_popover} = props;
    const {
        button_variant,
        dom_id,
        allow_open_ended,
        mode,
        disabled,
        custom_button_content,
        overlay_placement,
    } = element;

    const [selected_relative_time, set_selected_relative_time] = useState<Input.RelativeTimeRangeOption | null>(null);

    const relative_options = (element.relative_options ?? []).sort(compare_relative_options);

    useEffect(() => {
        if (!selected_relative_time) return;
        const to = (() => {
            if (selected_relative_time.type === 'Dynamic') {
                switch (selected_relative_time.option) {
                    case 'Today':
                        return moment().endOf('day');
                    default:
                        return moment();
                }
            }
            return selected_relative_time.unit === 'h' ? moment() : moment().endOf('day')
        })();
        const from = (() => {
            if (selected_relative_time.type === 'Dynamic') {
                switch (selected_relative_time.option) {
                    case 'Today':
                        return moment().startOf('day');
                    default:
                        return moment();
                }
            }
            switch (selected_relative_time.unit) {
                case 'h':
                    return moment().subtract(selected_relative_time.time, 'hours');
                case 'd':
                    return moment().subtract(selected_relative_time.time, 'days');
                case 'w':
                    return moment().subtract(selected_relative_time.time, 'weeks');
                case 'M':
                    return moment().subtract(selected_relative_time.time, 'months');
                case 'y':
                    return moment().subtract(selected_relative_time.time, 'years');
            }
        })();
        props.changed({
            from: (selected_relative_time.type === 'Dynamic' || selected_relative_time.unit === 'h') ? moment(from) : moment(from).startOf('day'),
            to: moment(to)
        });
        set_show_popover(false);
    }, [selected_relative_time]);

    const on_clear = () => {
        set_selected_relative_time(null);
        props.changed({});
    };

    const toggle_is_active = (() => {
        if (show_popover) return true;
        if (selected_relative_time) return false;
        return !!element.value.to || !!element.value.from;
    })();

    const trigger_text = (() => {
        const default_custom_content = custom_button_content ?? 'Custom';
        if (selected_relative_time || !element.value.from || !element.value.from.isValid()) return default_custom_content;
        if (!allow_open_ended && (!element.value.to || !element.value.to.isValid())) return default_custom_content;
        const date_format = mode === Input.DateTimeRangeModes.DateTime ? 'M/D/YYYY h:mm A' : 'M/D/YYYY';
        const same_granularity = mode === Input.DateTimeRangeModes.DateTime ? 'minute' : 'day';
        const formatted_from = element.value.from.format(date_format);
        if (!element.value.to || !element.value.to.isValid()) return `${formatted_from}${allow_open_ended ? ' - No End Date' : ''}`;
        if (element.value.from.isSame(element.value.to, same_granularity)) return formatted_from;
        const formatted_to = element.value.to?.format(date_format);
        return `${formatted_from} - ${formatted_to}`;
    })();

    return (
        <InputGroup>
            <ConditionalComponent show={!!element.allow_clear}>
                <Button variant={button_variant}
                        type={'button'}
                        onClick={on_clear}
                        disabled={show_popover || disabled}
                >Clear</Button>
            </ConditionalComponent>
            {relative_options.map((option, index) => (
                <RelativeOption
                    key={index}
                    {...props}
                    option={option}
                    selected_relative_time={selected_relative_time}
                    set_selected_relative_time={set_selected_relative_time}
                />
            ))}
            <OverlayTrigger
                trigger="click"
                placement={overlay_placement ?? 'auto'}
                onToggle={set_show_popover}
                show={show_popover}

                overlay={(
                    <Popover id={`${dom_id}-popover`}
                             className={styles.DateTimeRangePopover}>
                        <DateTimeRangePopoverBody
                            {...props}
                            hide_popover={() => set_show_popover(false)}
                            clear_relative_option={() => set_selected_relative_time(null)}
                        />
                    </Popover>
                )}>
                <Button
                    variant={button_variant}
                    type={'button'}
                    disabled={disabled}
                    active={toggle_is_active}>{trigger_text}</Button>
            </OverlayTrigger>
        </InputGroup>
    )
};

const useTimeInput = (initial_time?: moment.Moment) => {
    const [hours_text, set_hours_text] = useState<string>(initial_time?.format('h') ?? '');
    const [minutes_text, set_minutes_text] = useState<string>(initial_time?.format('mm') ?? '');
    const [am_pm_text, set_am_pm_text] = useState<'AM' | 'PM'>((initial_time?.format('A') as 'AM' | 'PM') ?? 'AM');

    const update_hours_text = (text: string) => {
        const strip_non_digits = text.replaceAll(/[^0-9]/g, '');
        const next_hours = Math.abs(Number(strip_non_digits));
        if (next_hours > 12) return;
        set_hours_text(`${strip_non_digits}`);
    }

    const update_minutes_text = (text: string) => {
        const strip_non_digits = text.replaceAll(/[^0-9]/g, '');
        const next_minutes = Math.abs(Number(strip_non_digits));
        if (next_minutes > 59) return;
        set_minutes_text(`${strip_non_digits}`);
    }

    const toggle_am_pm = () => set_am_pm_text((prev) => prev === 'AM' ? 'PM' : 'AM');

    return {hours_text, update_hours_text, minutes_text, update_minutes_text, am_pm_text, toggle_am_pm};
};

type TimeFieldsInputGroupProps = {
    hours_text: string;
    update_hours_text: (text: string) => void;
    minutes_text: string;
    update_minuets_text: (text: string) => void;
    am_pm_text: "AM" | "PM";
    toggle_am_pm: () => void;
    button_variant: ButtonVariant | undefined;
    disabled: boolean | undefined;
    time_field_class_name: string | undefined;
    title?: string;
};

const TimeFieldsInputGroup = ({
                                  hours_text,
                                  update_hours_text,
                                  disabled,
                                  minutes_text,
                                  update_minuets_text,
                                  button_variant,
                                  toggle_am_pm,
                                  am_pm_text,
                                  time_field_class_name,
                                  title,
                              }: TimeFieldsInputGroupProps) => (
    <>
        <Form.Control
            type={'text'}
            placeholder={'hh'}
            value={hours_text}
            onChange={(e) => update_hours_text(e.target.value)}
            disabled={disabled}
            className={time_field_class_name}
            title={title ? `${title} Hours` : undefined}
        />
        <Form.Control
            type={'text'}
            placeholder={'mm'}
            value={minutes_text}
            onChange={(e) => update_minuets_text(e.target.value)}
            disabled={disabled}
            className={time_field_class_name}
            title={title ? `${title} Minutes` : undefined}
        />
        <Button
            variant={button_variant}
            type={'button'}
            className={`px-1 px-sm-2${time_field_class_name ? ` ${time_field_class_name}` : ''}`}
            onClick={toggle_am_pm}
            disabled={disabled}
            title={title ? `${title} AM/PM` : undefined}
        >{am_pm_text}</Button>
    </>
);

const TimeRangeInputGroup = (props: Props) => {
    const {element} = props;
    const {
        button_variant,
        value,
        disabled,
        time_field_class_name,
        title,
    } = element;
    const {
        hours_text: from_hours_text, update_hours_text: update_from_hours_text,
        minutes_text: from_minutes_text, update_minutes_text: update_from_minutes_text,
        am_pm_text: from_am_pm_text, toggle_am_pm: toggle_from_am_pm
    } = useTimeInput(value.from);
    const {
        hours_text: to_hours_text, update_hours_text: update_to_hours_text,
        minutes_text: to_minutes_text, update_minutes_text: update_to_minutes_text,
        am_pm_text: to_am_pm_text, toggle_am_pm: toggle_to_am_pm
    } = useTimeInput(value.to);

    useEffect(() => {
        const from_moment = moment(`${from_hours_text}:${from_minutes_text} ${from_am_pm_text}`, 'hh:mm A');
        const updated_value: Input.DateTimeRange = {...(element.value.to ? {to: moment(element.value.to)} : {})};
        if (from_moment.isValid() && (!element.value.to || (element.value.to.isSameOrAfter(from_moment)))) {
            updated_value.from = from_moment;
        }
        props.changed(updated_value);
    }, [from_hours_text, from_minutes_text, from_am_pm_text]);

    useEffect(() => {
        const to_moment = moment(`${to_hours_text}:${to_minutes_text} ${to_am_pm_text}`, 'hh:mm A');
        const updated_value: Input.DateTimeRange = {...(element.value.from ? {from: moment(element.value.from)} : {})};
        if (to_moment.isValid() && (!element.value.from || (element.value.from.isSameOrBefore(to_moment)))) {
            updated_value.to = to_moment;
        }
        props.changed(updated_value);
    }, [to_hours_text, to_minutes_text, to_am_pm_text]);

    return (
        <InputGroup>
            <TimeFieldsInputGroup
                hours_text={from_hours_text}
                update_hours_text={update_from_hours_text}
                minutes_text={from_minutes_text}
                update_minuets_text={update_from_minutes_text}
                am_pm_text={from_am_pm_text}
                toggle_am_pm={toggle_from_am_pm}
                button_variant={button_variant}
                disabled={disabled}
                time_field_class_name={time_field_class_name}
                title={title ? `${title} From` : undefined}
            />
            <InputGroup.Text className={'px-1 px-md-2'}>-</InputGroup.Text>
            <TimeFieldsInputGroup
                hours_text={to_hours_text}
                update_hours_text={update_to_hours_text}
                minutes_text={to_minutes_text}
                update_minuets_text={update_to_minutes_text}
                am_pm_text={to_am_pm_text}
                toggle_am_pm={toggle_to_am_pm}
                button_variant={button_variant}
                disabled={disabled}
                time_field_class_name={time_field_class_name}
                title={title ? `${title} To` : undefined}
            />
        </InputGroup>
    )
};

const DateTimeRangeElement = (props: Props) => {
    const {element} = props;

    const [show_popover, set_show_popover] = useState(false);

    const on_show_popover = (next_value: boolean) => {
        set_show_popover(next_value && !(!!element.disabled || !!element.read_only));
    }

    const input_group = (() => {
        switch (element.mode) {
            case Input.DateTimeRangeModes.Date:
            case Input.DateTimeRangeModes.DateTime:
                return <DateTimeRangeInputGroup {...props}
                                                show_popover={show_popover}
                                                set_show_popover={on_show_popover}/>;
            case Input.DateTimeRangeModes.Time:
                return <TimeRangeInputGroup {...props}/>;
        }
        return null;
    })()

    const error = <Error {...props}/>;

    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {input_group}
                {error}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {input_group}
            {error}
            {element.bottom_info}
        </>
    )
};

export default DateTimeRangeElement;
