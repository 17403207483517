import React from "react";
import {connect, ConnectedProps} from "react-redux";

import Header from '../../components/Header/Header';
import Footer from "../../components/Footer/Footer";
import SpinningWheel from "../../components/SpinningWheel/SpinningWheel";
import StateType from '../../store/store';
import ErrorBar from "../../components/ErrorBar/ErrorBar";
import {GeneralActions} from "../../store/actions/GeneralActions";
import SuccessBar from "../../components/SuccessBar/SuccessBar";
import TooltipModal from "../../components/TooltipModal/TooltipModal";
import HODImageLibraryModal from "../../containers/HODImageLibrary/HODImageLibraryModal/HODImageLibraryModal";
import ExpertSuspendedBanner from "../../components/ExpertSuspendedBanner/ExpertSuspendedBanner";
import {ToastContainer} from "react-toastify";

const map_state_to_props = (state: StateType) => ({
    spinning_wheel: state.general.spinning_wheel,
    spinning_wheel_text: state.general.spinning_wheel_text,
    error_text: state.general.error,
    success_text: state.general.success,
});

const map_dispatch_to_props = (dispatch: any) => ({
    actions: {
        clear_error: () => dispatch(GeneralActions.clear_error()),
        clear_success: () => dispatch(GeneralActions.clear_success())
    }
});

const connector = connect(map_state_to_props, map_dispatch_to_props);

interface Props extends ConnectedProps<typeof connector> {
    children: React.ReactNode
}

const Layout = ({children, spinning_wheel, spinning_wheel_text, error_text, success_text, actions}: Props) => {
    return (
        <>
            <Header/>
            {error_text ? <ErrorBar on_close_click={actions.clear_error} text={error_text}/> : null}
            {success_text ? <SuccessBar on_close_click={actions.clear_success} text={success_text}/> : null}
            <ExpertSuspendedBanner/>
            {children}
            <Footer/>
            {spinning_wheel ? <SpinningWheel text={spinning_wheel_text}/> : null}
            <TooltipModal/>
            <HODImageLibraryModal/>
            <ToastContainer
                position={'top-center'}
                pauseOnFocusLoss={false}
                pauseOnHover={false}

            />
        </>
    );
};

export default connector(Layout);
