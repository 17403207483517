import {BrokerUserDTO, CarrierUserDTO, UserProfile} from '../../types';
import * as action_types from '../actions/actionTypes';

export type AccountState = {
    is_guest: boolean;
    is_admin: boolean;
    is_broker: boolean;
    is_carrier: boolean;
    is_carrier_power_user: boolean;
    is_carrier_advanced_power_user: boolean;
    is_enter_referrals: boolean;
    carrier_type: string[];
    user_data: UserProfile | BrokerUserDTO | CarrierUserDTO | null;
    last_account_action: number;
    session_expires_in_seconds: number;
    is_loading_profile: boolean;
}


const initial_state: AccountState = {
    is_guest: true,
    is_admin: false,
    is_broker: false,
    is_carrier: false,
    is_carrier_power_user: false,
    is_carrier_advanced_power_user: false,
    is_enter_referrals: false,
    carrier_type: [],
    user_data: null,
    last_account_action: Number.MAX_SAFE_INTEGER,
    session_expires_in_seconds: Number.MAX_SAFE_INTEGER,
    is_loading_profile: true,
};

const set_is_guest = (state: AccountState, action: any): AccountState => ({
    ...state,
    is_guest: action.is_guest
});

const set_profile = (state: AccountState, action: any): AccountState => ({
    ...state,
    is_admin: action.is_admin,
    is_broker: action.is_broker,
    is_carrier: action.is_carrier,
    is_carrier_power_user: action.is_carrier_power_user,
    is_carrier_advanced_power_user: action.is_carrier_advanced_power_user,
    is_enter_referrals: action.is_enter_referrals,
    carrier_type: [...action.carrier_type],
    user_data: action.user && action.user !== null ? {...action.user} : null,
    is_loading_profile: false,
});

const set_availability = (state: AccountState, action: any): AccountState => {
    const broker_user = state.user_data as BrokerUserDTO;
    if (broker_user.brokerData) {
        broker_user.brokerData.available = action.availability;
        state = {
            ...state,
            user_data: {...broker_user}
        };
    }

    return state;
}

const set_master_toggle_switch = (state: AccountState, action: any): AccountState => {
    const broker_user = state.user_data as BrokerUserDTO;
    if (broker_user.brokerData) {
        broker_user.brokerData.masterToggleSwitch = action.master_toggle_switch;
        state = {
            ...state,
            user_data: {...broker_user}
        };
    }

    return state;
}

const set_last_account_action = (state: AccountState, action: any): AccountState => ({
    ...state,
    last_account_action: action.last_account_action,
});

const set_session_expires_in_seconds = (state: AccountState, action: any): AccountState => ({
    ...state,
    session_expires_in_seconds: action.session_expires_in_seconds,
});

const set_is_loading_profile = (state: AccountState, action: any): AccountState => ({
    ...state,
    is_loading_profile: action.is_loading_profile,
})

const reducer = (state = initial_state, action: any) => {
    switch (action.type) {
        case action_types.ACCOUNT_SET_IS_GUEST:
            return set_is_guest(state, action);
        case action_types.ACCOUNT_SET_USER:
            return set_profile(state, action);
        case action_types.EXPERT_SET_AVAILABILITY:
            return set_availability(state, action);
        case action_types.EXPERT_SET_MANUAL_OVERRIDE_MASTER_TOGGLE_SWITCH:
            return set_master_toggle_switch(state, action);
        case action_types.ACCOUNT_SET_LAST_ACCOUNT_ACTION:
            return set_last_account_action(state, action);
        case action_types.ACCOUNT_SET_SESSION_EXPIRES_IN_SECONDS:
            return set_session_expires_in_seconds(state, action);
        case action_types.ACCOUNT_SET_IS_LOADING_PROFILE:
            return set_is_loading_profile(state, action);
        default:
            return state;
    }
}

export default reducer;
