import React from "react";
import NotificationBar from "../NotificationBar/NotificationBar";

type Props = {
    on_close_click(): void;
    text: string;
};

const ErrorBar = ({on_close_click, text}: Props) => {
    const split_text = text.split('\n');
    const elements = split_text.flatMap((str, index) => index < split_text.length - 1 ? [str, <br/>] : [str])
        .map((element, index) => <React.Fragment key={index}>{element}</React.Fragment>);
    return (
        <NotificationBar variant={'danger'} on_close_click={() => on_close_click()}>
            {elements}
        </NotificationBar>
    )
};

export default ErrorBar;
