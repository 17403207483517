import {BrokerUserDTO, CarrierUserDTO, Func, TokenResponseData, UserProfile} from "../../types";
import {GeneralActions} from "./GeneralActions";
import * as action_types from './actionTypes';
import StateType from "../store";
import * as config from '../../config';
import AuthAPI from "../../api/AuthAPI";
import {Location} from "react-router-dom";
import GeneralAPI from "../../api/GeneralAPI";
import BrokerActions from "./BrokerActions";
import AdminActions from "./AdminActions";
import NotificationActions from "./NotificationActions";
import BrokerStatusIds from "../../util/BrokerStatusIds";
import moment from "moment/moment";

export default class AccountActions {

    static set_is_guest(is_guest: boolean) {
        return (dispatch: any) => {
            dispatch({type: action_types.ACCOUNT_SET_IS_GUEST, is_guest});
        };
    }

    static set_profile(user: UserProfile | BrokerUserDTO | CarrierUserDTO) {
        return (dispatch: any) => {
            dispatch(AccountActions.set_is_guest(false));
            dispatch(AccountActions.set_last_account_action_to_now());
            const is_admin = user.roles.indexOf('Admin') > -1;
            const is_broker = user.roles.indexOf('Broker') > -1;
            let is_carrier = false;
            let is_carrier_power_user = false;
            let is_carrier_advanced_power_user = false;
            let is_enter_referrals = false;

            let carrier_type: string[] = [];
            if (user.roles.indexOf('Carrier-Metrics') > -1) {
                is_carrier = true;
                carrier_type.push('metric');
            }
            if (user.roles.indexOf('Carrier-Leads') > -1) {
                is_carrier = true;
                is_enter_referrals = true;
                carrier_type.push('leads');
            }
            if (user.roles.indexOf('Carrier-PowerUser') > -1) {
                is_carrier = true;
                is_carrier_power_user = true;
                carrier_type.push('power');
            }
            if (user.roles.indexOf('Carrier-AdvancedPowerUser') > -1) {
                is_carrier = true;
                is_carrier_advanced_power_user = true;
                carrier_type.push('advancedPower');
            }
            if (is_broker) {
                dispatch(BrokerActions.set_broker_profile(user as BrokerUserDTO));
            }
            dispatch({
                type: action_types.ACCOUNT_SET_USER,
                is_admin,
                is_broker,
                is_carrier,
                is_carrier_power_user,
                is_carrier_advanced_power_user,
                is_enter_referrals,
                carrier_type,
                user
            });
        };
    }

    static clear_profile() {
        return (dispatch: any) => {
            dispatch({
                type: action_types.ACCOUNT_SET_USER,
                is_admin: false,
                is_broker: false,
                is_carrier: false,
                is_carrier_power_user: false,
                is_carrier_advanced_power_user: false,
                is_enter_referrals: false,
                carrier_type: [],
                user: null
            });
            dispatch(AccountActions.set_is_guest(true));
            dispatch(AdminActions.clear_admin_data());
            dispatch(GeneralActions.clear_carrier_branding());
            dispatch(NotificationActions.clear_notifications());
            dispatch(AccountActions.set_last_account_action_to_now());
            dispatch(AccountActions.set_session_expires_in_seconds(Number.MAX_SAFE_INTEGER));
            sessionStorage.clear();
        };
    }

    static go_home(location?: Location, skip_select_profile?: boolean) {
        return (dispatch: any, getState: Func<[], StateType>) => {
            const state = getState();
            const dont_go_home_routes = [
                '/disclosure.html'
            ];
            if (location && dont_go_home_routes.some(route => location.pathname.startsWith(route))) return;
            if (state.account.is_guest) {
                dispatch(GeneralActions.nav_to('/login'));
            } else if (state.account.user_data?.hasExpiredPassword) {
                dispatch(GeneralActions.nav_to('/expired'));
            } else if (state.account.is_broker) {
                if (!location || !location.pathname.startsWith('/broker')) {
                    dispatch(GeneralActions.nav_to(this.should_broker_go_to_select_profile(skip_select_profile, state) ? '/select_profile'
                        : state.broker.min_carriers.length === 0 ? '/logout' : '/broker'));
                }
            } else if (state.account.is_admin || state.account.is_carrier_advanced_power_user || state.account.is_carrier_power_user) {
                if (!location || !location.pathname.startsWith('/admin')) {
                    dispatch(GeneralActions.nav_to('/admin'));
                }
             } else if(state.account.is_enter_referrals){
                 dispatch(GeneralActions.nav_to('/admin/leads/enter-single-lead'))
             }
            else if (state.account.is_carrier) {
                if (state.account.carrier_type.indexOf('leads') > -1) {
                    if (!location || !location.pathname.startsWith('')) {
                        dispatch(GeneralActions.nav_to('/admin/leads/enter-single-lead'));
                    }
                } else if (state.account.carrier_type.indexOf('metric') > -1) {
                    if (!location || !location.pathname.startsWith('/lead/metrics')) {
                        dispatch(GeneralActions.nav_to('/lead/metrics'));
                    }
                } else {
                    if (!location || !location.pathname.startsWith('/lead')) {
                        dispatch(GeneralActions.nav_to('/lead'));
                    }
                }
            }
        };
    }

    private static should_broker_go_to_select_profile(skip_select_profile: boolean | undefined, state: StateType) {
        if (skip_select_profile === true || state.broker.min_carriers.length <= 1) return false;
        return (state.account.user_data as BrokerUserDTO).brokerData.carriers.filter(c => c.statusId === BrokerStatusIds.PROFILE_PENDING).length === 0
    }

    static logout() {
        return (dispatch: any) => {
            dispatch(GeneralActions.nav_to('/logout'));
        }
    }

    private static is_location_protected(location: Location) {
        return ['admin', 'broker', 'lead_leads', 'lead'].filter(path => location.pathname.startsWith(`/${path}`)).length > 0
    }


    static load_user_data(cookies: Record<string, any>, location: Location) {
        return async (dispatch: any) => {
            if (cookies[config.USER_TOKEN_COOKIE_NAME]) {
                const token = cookies[config.USER_TOKEN_COOKIE_NAME] as TokenResponseData;
                try {
                    const profile = await AuthAPI.me(token.access_token);
                    const time_zones = await GeneralAPI.get_time_zones();
                    dispatch(AccountActions.set_profile(profile));
                    dispatch(AccountActions.go_home(location));
                    dispatch(GeneralActions.set_timezones(time_zones))
                } catch (e: any) {
                    dispatch(GeneralActions.logout_if_unauthorized(e));
                }
            } else {
                dispatch(AccountActions.set_is_guest(true));
                dispatch({type: action_types.ACCOUNT_SET_IS_LOADING_PROFILE, is_loading_profile: false});
                if (AccountActions.is_location_protected(location) || location.pathname === '/') {
                    dispatch(GeneralActions.nav_to('/login'));
                }
            }
        };
    }

    static set_last_account_action_to_now() {
        return {type: action_types.ACCOUNT_SET_LAST_ACCOUNT_ACTION, last_account_action: moment().valueOf()};
    }

    static set_session_expires_in_seconds(seconds: number) {
        return {type: action_types.ACCOUNT_SET_SESSION_EXPIRES_IN_SECONDS, session_expires_in_seconds: seconds};
    }

}
