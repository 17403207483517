import React from "react";
import BrandingTerm from "./BrandingTerm";

export const ReferralTerm = () => {
    return <BrandingTerm get_term={carrier_branding => carrier_branding.terms.lead} default_term={'Referral'}/>
}

export const ExpertTerm = () => {
    return <BrandingTerm get_term={carrier_branding => carrier_branding.terms.broker} default_term={'Expert'}/>
}
