import * as Inputs from "../inputs";
import { Func } from "../types";

export const required_text: Func<[string, Record<string, Inputs.InputField>], boolean> = (input, current_form) => {
    return input.trim().length > 0;
};

export const min_length = (min_length: number): Func<[string, Record<string, Inputs.InputField>], boolean> => {
    return (input, current_form) => {
        return input.trim().length >= min_length;
    }
};

export const max_length = (max_length: number): Func<[string, Record<string, Inputs.InputField>], boolean> => {
    return (input, current_form) => {
        return input.trim().length <= max_length;
    }
};

export const is_email: Func<[string, Record<string, Inputs.InputField>], boolean> = (input, current_form) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(input);
}

export const is_integer: Func<[string, Record<string, Inputs.InputField>], boolean> = (input, current_form) => {
    return /^(\s*|[0-9]+)$/.test(input);
};

export const combine_and = <T>(validators: Func<[T, Record<string, Inputs.InputField>], boolean>[]): Func<[T, Record<string, Inputs.InputField>], boolean> => {
    return (input, current_form) => {
        let result = true;
        for (let i = 0; i < validators.length && result; i++) {
            result = result && validators[i](input, current_form);
        }
        return result;
    };
}

export const is_valid_zip_code_range: Func<[string, Record<string, Inputs.InputField>], boolean> = (input: string, current_form) => {
    if (input.trim().length === 0) return true;
    const zipCodes = input.split(/[,|-]/);
    for (let i = 0; i < zipCodes.length; ++i) {
        if (zipCodes[i].replace(/\s+/, '').length !== 5) return false;
    }
    return true;
}

export const does_not_contain_spaces: Func<[string, Record<string, Inputs.InputField>], boolean> = (input, current_form) => {
    return /^\S+$/.test(input);
};

export const has_number: Func<[string, Record<string, Inputs.InputField>], boolean> = (input, current_form) => {
    return /\d/.test(input);
};

export const has_lower_case_letter: Func<[string, Record<string, Inputs.InputField>], boolean> = (input, current_form) => {
    return /[a-z]/.test(input);
};

export const has_upper_case_letter: Func<[string, Record<string, Inputs.InputField>], boolean> = (input, current_form) => {
    return /[A-Z]/.test(input);
};

export const has_special_character: Func<[string, Record<string, Inputs.InputField>], boolean> = (input, current_form) => {
    return /[^a-zA-Z\d\s]+/.test(input);
};

export const has_no_more_than_three_repetitive_characters: Func<[string, Record<string, Inputs.InputField>], boolean> = (input, current_form) => {
    return !/(.)\1{2,}/.test(input);
};

export const has_no_more_than_three_sequential_characters: Func<[string, Record<string, Inputs.InputField>], boolean> = (input, current_form) => {
    return !/(?:(.)\1{2,})/.test(input);
};

export const values_match = (input1: string, input2: string): Func<[string, Record<string, Inputs.InputField>], boolean> => {
    return (input, current_form) => {
        return input1 === input2;
    }
};