import {Dropdown} from "react-bootstrap";
import React from "react";

import styles from './NotificationDropdownItem.module.scss';
import NotificationItem from "../NotificationItem/NotificationItem";
import {Notification} from "../../../../types";

type Props = {
    notification: Notification
    on_action: (notification: Notification) => void;
};

const NotificationDropdownItem = ({notification, on_action}: Props) => {
    return (
        <>
            <Dropdown.Divider/>
            <Dropdown.Item className={styles.NotificationDropdownItem} onClick={() => on_action(notification)}>
                <NotificationItem notification={notification}/>
            </Dropdown.Item>
        </>
    )
};

export default NotificationDropdownItem;
