import React, {useEffect, useRef} from "react";
import {Col, Form} from "react-bootstrap";

import * as Input from '../../../inputs';
import Formatters from "../../../util/Formatters";

type Props = {
    element: Input.PhoneNumberField;
    changed(new_value: any): void;
    blurred?(): void;
    form_control_class_name?: string;
};

const PhoneNumberElement = ({element, changed, blurred, form_control_class_name}: Props) => {

    const get_pos = (val: string) => {
        let len_offset: number;
        if (element.value.length > 6) {
            len_offset = 4;
        } else if (element.value.length > 3) {
            len_offset = 3;
        } else {
            len_offset = 1;
        }
        return element.value.length + len_offset;
    };

    const on_changed = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        changed(event.target.value.replace(/[^\d]/g, ''));
    };

    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (ref && ref.current) {
            const pos = get_pos(element.value);
            ref.current.setSelectionRange(pos, pos);
        }
    }, [element.value]);

    const render_form_control = () => (
        <Form.Control
            type='text'
            name={element.name || element.dom_id}
            placeholder={element.placeholder || ''}
            value={Formatters.format_phone_number(element.value)}
            onChange={on_changed}
            onBlur={blurred}
            ref={ref}
            isInvalid={!element.valid && element.touched}
            disabled={!!element.disabled}
            isValid={!element.hide_valid && element.valid}
            className={form_control_class_name}/>
    );
    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
};

export default PhoneNumberElement;
