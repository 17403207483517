import React from "react";
import {CarrierBrandingDocument} from "../../../BrandingTypes";
import StateType from "../../../store/store";
import {connect, ConnectedProps} from "react-redux";

const map_state_to_props = (state: StateType) => ({
    state: {
        carrier_branding: state.general.carrier_branding
    }
});

const connector = connect(map_state_to_props);

type Props = ConnectedProps<typeof connector> & {
    get_term: (carrier_branding: CarrierBrandingDocument) => string | undefined;
    default_term: string;
};

const BrandingTerm = ({state, get_term, default_term}: Props) => {
    const term = state.carrier_branding ? get_term(state.carrier_branding) ?? default_term : default_term;
    return <>{term}</>;
}



export default connector(BrandingTerm);
