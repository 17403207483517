import React, {CSSProperties} from "react";
import {Button, Navbar} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {GeneralActions} from "../../store/actions/GeneralActions";
import StateType from "../../store/store";

import styles from './Header.module.scss';
import ContactBanner from "../ContactBanner/ContactBanner";
import HeaderLogo from "./HeaderLogo/HeaderLogo";
import {useLocation} from "react-router-dom";
import NotificationCenter from "./NotificationCenter/NotificationCenter";
import ProfileDropdown from "./ProfileDropdown/ProfileDropdown";
import {CarrierHeaderBrandingDocument} from "../../BrandingTypes";
import ConditionalComponent from "../../hoc/ConditionalComponent/ConditionalComponent";

const map_state_to_props = (state: StateType) => ({
    state: {
        is_guest: state.account.is_guest,
        branding_header: state.general.carrier_branding?.header,
        user_data: state.account.user_data,
        branding_language: state.branding.branding_language,
    }
});

const map_dispatch_to_props = (dispatch: any) => ({
    actions: {
        show_mobile_nav: () => dispatch(GeneralActions.show_mobile_nav())
    }
});

const connector = connect(map_state_to_props, map_dispatch_to_props);

type BrandingLogoProps = {
    branding_header: CarrierHeaderBrandingDocument | undefined;
    is_guest: boolean;
    branding_language: 'en' | 'es';
};

const BrandingLogo = ({branding_header, is_guest, branding_language}: BrandingLogoProps) => {
    if (!branding_header || branding_header.logo === 'default') return (
        <HeaderLogo
            logo={{
                type: 'img',
                alt: "Big Wave",
                target: is_guest ? undefined : "/",
                src: '/images/help_on_demand_logo.png',
            }}
            branding_language={branding_language}
        />
    );
    if (!branding_header.logo) return null;
    const logo = branding_header.logo.filter(logo => {
        switch (logo.visibility) {
            case 'llp':
                return is_guest;
            case "system":
                return !is_guest;
            case "both":
                return true;
        }
    });
    return (
        <>
            {logo.map((logo, index) => (
                <HeaderLogo
                    key={index}
                    logo={logo}
                    text_color={branding_header.colors?.links}
                    branding_language={branding_language}
                />
            ))}
        </>
    );
};

const Header = ({state, actions}: ConnectedProps<typeof connector>) => {

    const location = useLocation();

    const branding_styles: CSSProperties = {};
    const default_header_paths = [
        '/disclosure'
    ];

    const default_header_paths_found = (default_header_paths.findIndex(path => location.pathname.indexOf(path) >= 0) >= 0)

    if (state.branding_header && !default_header_paths_found) {
        if (state.branding_header.colors.background) {
            branding_styles.backgroundColor = `${state.branding_header.colors.background}`
        }
    }

    const hidden_header_paths = [
        '/login',
        '/forgot_password',
        '/forgot_username',
        '/expired',
        '/forgot',
        '/registration',
        '/select_profile',
        '/association',
    ];
    if (hidden_header_paths.findIndex(path => location.pathname.indexOf(path) >= 0) >= 0) return null;

    const show_toggle = (() => {
        if (state.is_guest) return false;
        const hidden_toggle_paths = new Set<string>([
            '/disclosure.html'
        ]);
        return !hidden_toggle_paths.has(location.pathname);
    })();

    return (
        <>
            <Navbar expand="lg" variant="dark" className={styles.Header} style={branding_styles}>
                <div className="d-flex justify-content-between align-items-center w-100 ps-3 pe-3">
                    <div>
                        <ConditionalComponent show={show_toggle}>
                            <Button
                                className={'me-2 d-xl-none'}
                                variant={'nav-toggle'}
                                onClick={event => {
                                    event.preventDefault();
                                    actions.show_mobile_nav();
                                }}
                                title={'Navigation Toggle'}
                            >
                                <i className={`bi bi-list fs-1 ${styles.NavbarTextLinkBlue}`}
                                   style={{color: state.branding_header?.colors.links}}/>
                            </Button>
                        </ConditionalComponent>
                    </div>
                    <div className={'d-lg-flex align-items-lg-end'}>
                        <BrandingLogo
                            branding_header={!default_header_paths_found ? state.branding_header : undefined}
                            is_guest={state.is_guest}
                            branding_language={state.branding_language}
                        />
                    </div>
                    <div className={'d-flex align-items-center'}>
                        <div>
                            <NotificationCenter/>
                        </div>
                        <div>
                            <ProfileDropdown/>
                        </div>
                    </div>
                </div>
            </Navbar>
            {state.branding_header?.contact && !default_header_paths_found ? (
                <ContactBanner
                    banner={state.branding_header.contact}
                    branding_language={state.branding_language}
                />
            ) : null}
        </>
    )
};

export default connector(Header);
