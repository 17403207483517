import AdminAPI from "../../api/AdminAPI";
import GeneralAPI from "../../api/GeneralAPI";
import { GeneralActions } from "./GeneralActions";
import * as action_types from './actionTypes';
import { AdminBrokerSearchBindingModel, AdminLeadSearchBindingModel, CarrierIdGuidNameDTO, NonBrokerUserDTO, BrokerUserDTO, UserTypeDTO } from "../../types";
import AccountActions from "./AccountActions";
import {selectedType} from "react-bootstrap-typeahead/types/propTypes";

export default class AdminActions {
    static load_admin_data(token: string) {
        return async (dispatch: any) => {
            try {
                dispatch(AdminActions.set_loading_admin_data(true));
                const [time_zones, broker_preferred_contact_methods, minimal_carriers, lead_preferred_contact_methods] = await Promise.all([
                    GeneralAPI.get_time_zones(),
                    GeneralAPI.get_broker_preferred_contact_methods(),
                    AdminAPI.get_min_carriers(token),
                    GeneralAPI.get_lead_preferred_contact_methods()
                ]);
                dispatch(AdminActions.clear_saved_lead_search());
                dispatch(GeneralActions.set_timezones(time_zones));
                dispatch(GeneralActions.set_broker_preferred_contact_methods(broker_preferred_contact_methods));
                dispatch(GeneralActions.set_lead_preferred_contact_methods(lead_preferred_contact_methods));
                dispatch(AdminActions.set_min_carriers(minimal_carriers));
                dispatch(AdminActions.set_selected_carrier_guid(minimal_carriers[0].guid));
                dispatch(GeneralActions.select_guessed_default_timezone(time_zones));
                dispatch(AdminActions.set_loading_admin_data(false));
            } catch (e) {
                dispatch(GeneralActions.logout_if_unauthorized(e));
            }
        };
    }

    static set_loading_admin_data(loading_admin_data: boolean) {
        return (dispatch: any) => {
            dispatch({ type: action_types.ADMIN_SET_LOADING_ADMIN_DATA, loading_admin_data });
        };
    }

    static clear_admin_data() {
        return (dispatch: any) => {
            dispatch(AdminActions.set_min_carriers([]));
            dispatch(AdminActions.set_selected_carrier_guid(""));
            dispatch(AdminActions.set_selected_broker(null));
            dispatch(AdminActions.clear_saved_lead_search());
            dispatch(AdminActions.set_user_types([]));
        };
    }

    static set_min_carriers(carriers: CarrierIdGuidNameDTO[]) {
        return (dispatch: any) => {
            dispatch({ type: action_types.ADMIN_SET_MIN_CARRIERS, min_carriers: carriers })
        };
    }

    static set_selected_carrier_guid(selected_carrier_guid: string) {
        return (dispatch: any) => {
            dispatch({ type: action_types.ADMIN_SET_SELECTED_CARRIER_GUID, selected_carrier_guid });
        }
    }

    static go_to_reassign_lead(lead_guid: string, token: string) {
        return async (dispatch: any) => {
            dispatch(GeneralActions.show_spinning_wheel());
            try {
                const lead = await AdminAPI.get_lead_by_guid(lead_guid, token);
                dispatch({ type: action_types.ADMIN_SET_REASSIGN_LEAD, reassign_lead: lead });
                dispatch(GeneralActions.nav_to('/admin/assign_broker'));
                dispatch(GeneralActions.hide_spinning_wheel());
            } catch (e) {
                dispatch(GeneralActions.hide_spinning_wheel());
                dispatch(GeneralActions.logout_if_unauthorized(e));
            }
        };
    }

    static cancel_reassign_lead() {
        return (dispatch: any) => {
            dispatch({ type: action_types.ADMIN_SET_REASSIGN_LEAD, reassign_lead_guid: null });
            dispatch(AccountActions.go_home());
        };
    }

    static clear_saved_lead_search() {
        return (dispatch: any) => {
            dispatch({ type: action_types.ADMIN_SET_SAVED_LEAD_SEARCH, saved_lead_search: null })
        };
    }

    static save_lead_search(search: AdminLeadSearchBindingModel) {
        return (dispatch: any) => {
            dispatch({ type: action_types.ADMIN_SET_SAVED_LEAD_SEARCH, saved_lead_search: { ...search } });
        };
    }

    static save_broker_search(search: AdminBrokerSearchBindingModel) {
        return (dispatch: any) => {
            dispatch({ type: action_types.ADMIN_SET_SAVED_BROKER_SEARCH, saved_broker_search: { ...search } });
        }
    }

    static go_to_edit_user(user: NonBrokerUserDTO) {
        return (dispatch: any) => {
            dispatch({ type: action_types.ADMIN_SET_EDIT_USER, edit_user: { ...user } });
            dispatch(GeneralActions.nav_to('/admin/manageUsers/edit-user'));
        };
    };

    static cancel_edit_user() {
        return (dispatch: any) => {
            dispatch({ type: action_types.ADMIN_SET_EDIT_USER, edit_user: null });
            dispatch(GeneralActions.nav_to('/admin/manageUsers'));
        }
    }

    static set_user_types(types: UserTypeDTO[]) {
        return (dispatch: any) => {
            dispatch({ type: action_types.ADMIN_SET_USER_TYPES, user_types: [...types] });
        };
    }

    static set_selected_broker(broker: BrokerUserDTO | null) {
        return (dispatch: any) => {
            dispatch({ type: action_types.ADMIN_SET_SELECTED_BROKER, selected_broker: broker});
        };
    }

    static set_selected_user_type_guid(user_type_guid: string){
        return (dispatch: any) => {
            dispatch({type: action_types.ADMIN_SET_SELECTED_USER_TYPE, selected_user_type: user_type_guid});
        };
    }
    static set_manual_override_master_toggle_switch(manual_override_master_toggle_switch: boolean, broker_guid: string, token: string) {
        return async (dispatch: any) => {
            dispatch(GeneralActions.show_spinning_wheel());
            try {
                await AdminAPI.set_broker_manual_override_master_toggle_switch(manual_override_master_toggle_switch, broker_guid, token);
                dispatch({ type: action_types.ADMIN_SET_MANUAL_OVERRIDE_MASTER_TOGGLE_SWITCH, manual_override_master_toggle_switch });
            } catch (e) {
                dispatch(GeneralActions.logout_if_unauthorized(e));
            } finally {
                dispatch(GeneralActions.hide_spinning_wheel());
            }
        };
    }

    static set_availability(availability: boolean, broker_guid: string, token: string, ) {
        return async (dispatch: any) => {
            dispatch(GeneralActions.show_spinning_wheel());
            try {
                await AdminAPI.set_broker_availability(availability, broker_guid, token);
                dispatch({ type: action_types.ADMIN_SET_BROKER_AVAILABILITY, availability });
            } catch (e) {
                dispatch(GeneralActions.logout_if_unauthorized(e));
            } finally {
                dispatch(GeneralActions.hide_spinning_wheel());
            }
        }
    }

}
