import React from 'react';

import {connect, ConnectedProps} from "react-redux";
import StateType from "../../store/store";
import {Alert} from "react-bootstrap";
import {BrokerUserDTO} from "../../types";
import BrokerStatusIds from "../../util/BrokerStatusIds";
import {useLocation} from "react-router-dom";
import {ReferralTerm} from "../BrandingEngine/BrandingTerm/BrandingTerms";
import reactStringReplace from 'react-string-replace';

const map_state_to_props = (state: StateType) => ({
    state: {
        is_broker: state.account.is_broker,
        broker_data: state.account.user_data as BrokerUserDTO,
        selected_carrier_guid: state.broker.selected_carrier_guid,
        carrier_branding: state.general.carrier_branding,
    }
});

const connector = connect(map_state_to_props);

export const DEFAULT_SUSPEND_BANNER = 'You are currently in a Suspended status and are not eligible to receive {ReferralTerm}s. For further details, please contact your {CarrierName} administrator at {SupportEmail}.';

type ExpertSuspendedBannerProps = ConnectedProps<typeof connector> & {};

const ExpertSuspendedBanner = ({
                                   state: {
                                       is_broker,
                                       broker_data,
                                       selected_carrier_guid,
                                       carrier_branding,
                                   },
                               }: ExpertSuspendedBannerProps) => {

    const location = useLocation();

    if (!is_broker) return null;
    if (!broker_data.brokerData) return null;
    const {carriers} = broker_data.brokerData;
    const carrier = carriers.find(carrier => carrier.carrier.guid.trim().toLowerCase() === selected_carrier_guid?.trim().toLowerCase());
    if (!carrier || carrier.statusId !== BrokerStatusIds.SUSPENDED) return null;
    if (!carrier.carrier.showSuspendBanner) return null;

    const excluded_pathnames = new Set<string>([
        '/select_profile',
    ]);
    if (excluded_pathnames.has(location.pathname)) return null;

    const {carrier: {name: carrier_name, email: carrier_email}} = carrier;
    const support_email = carrier_email ?? 'support@helpondemand.com';

    const replace_carrier_name = (source: string | React.ReactNode[]) => (
        reactStringReplace(source, /(\{CarrierName})/g, (match, i) => <React.Fragment key={match + i}>{carrier_name}</React.Fragment>)
    );
    const replace_referral_term = (source: string | React.ReactNode[]) => reactStringReplace(source, /(\{ReferralTerm})/g, (match, i) =>
        <ReferralTerm key={match + i}/>);
    const replace_support_email = (source: string | React.ReactNode[]) => reactStringReplace(source, /(\{SupportEmail})/g, (match, i) =>
        <a key={match + i} href={`mailto:${support_email}`}>{support_email}</a>);

    return (
        <Alert
            className={'text-center mb-0'}
            variant={'danger'}
        >
            {replace_support_email(replace_referral_term(replace_carrier_name(carrier_branding?.suspend_banner ?? DEFAULT_SUSPEND_BANNER)))}
        </Alert>
    );
};

export default connector(ExpertSuspendedBanner);
