import {Form} from "react-bootstrap";
import * as Inputs from '../../../inputs';


type Props = {
    element: Inputs.CheckboxField;
    changed(value: any): void;
    blurred?(): void;
    group_className?: string;
    label_className?: string;
};

const CheckboxElement = ({element, changed, group_className, blurred, label_className}: Props) => {
    const render_error = () => {
        if (!element.error) return null;
        return (<span className={'text-danger'}>{element.error}</span>);
    };
    const text_label = label_className ? <span className={label_className}>{element.label}</span> : element.label;
    const render_check = () => {
        if (element.label_node || text_label) return (
            <Form.Check
                type={element.box_type}
                id={element.dom_id}
                checked={element.value}
                label={element.label_node ?? text_label}
                name={element.name || element.dom_id}
                disabled={!!element.disabled || !!element.read_only}
                onBlur={blurred}
                className={element.box_type === 'switch' ? 'custom-switch' : undefined}
                onChange={(event) => changed(event.target.checked)}
                readOnly={element.read_only}
            />
        );
        if (element.box_type === 'switch') return (
            <div className={'custom-switch form-switch'}>
                <input name={element.name || element.dom_id}
                       type="checkbox"
                       id={element.dom_id}
                       className="form-check-input"
                       checked={element.value}
                       onChange={(event) => changed(event.target.checked)}
                       title={element.title ?? element.name ?? element.dom_id}
                       onBlur={blurred}
                       disabled={!!element.disabled || !!element.read_only}
                       readOnly={element.read_only}
                />
            </div>
        );
        return (
            <input name={element.name || element.dom_id}
                   type="checkbox"
                   id={element.dom_id}
                   className="form-check-input"
                   checked={element.value}
                   onChange={(event) => changed(event.target.checked)}
                   title={element.title ?? element.name ?? element.dom_id}
                   onBlur={blurred}
                   readOnly={element.read_only}
                   disabled={!!element.disabled || !!element.read_only}
            />
        );
    };
    return (
        <div className={group_className}>
            {render_check()}
            {render_error()}
            {element.bottom_info}
        </div>
    );
};

export default CheckboxElement;

