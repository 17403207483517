import React from "react";
import {Col, Form} from "react-bootstrap";

import * as Input from '../../../inputs';

type Props = {
    element: Input.TextField;
    blurred?(): void;
    changed(new_value: any): void;
    keyDown?(event: any): void;
    inputRef?: ((instance: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined;
    form_control_class_name?: string;
};

const TextElement = ({element, changed, blurred, keyDown, inputRef, form_control_class_name}: Props) => {
    const render_form_control = () => (
        <Form.Control
            type='text'
            name={element.name || element.dom_id}
            placeholder={element.placeholder || ''}
            value={element.value}
            onChange={event => changed(event.target.value)}
            onBlur={blurred}
            isInvalid={!element.valid && element.touched}
            disabled={!!element.disabled}
            ref={inputRef}
            onKeyDown={keyDown}
            className={form_control_class_name}
            isValid={!element.hide_valid && element.valid}/>
    );
    const render_error = () => {
        if (!element.error) return null;
        return (<div className={'text-danger'}>{element.error}</div>);
    };
    if (element.label_span) {
        return (
            <Col sm={element.label_span === 'auto' ? 'auto' : 12 - element.label_span}>
                {render_form_control()}
                {render_error()}
                {element.bottom_info}
            </Col>
        )
    }
    return (
        <>
            {render_form_control()}
            {render_error()}
            {element.bottom_info}
        </>
    )
};

export default TextElement;
