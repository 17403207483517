import {Notification} from "../../../../types";
import {Badge} from "react-bootstrap";
import React from "react";

import styles from './NotificationItem.module.scss';

type Props = {
    notification: Notification;
};

const NotificationItem = ({notification: {title, sub_title, count}}: Props) => {
    return (
        <div className={'d-flex justify-content-between align-items-start'}>
            <div>
                <div className={`${styles.NotificationItemText} fw-bold`}>{title}</div>
                {sub_title ? <small className={styles.NotificationItemText}>{sub_title}</small> : null}
            </div>
            {count <= 0 ? null : (
                <div className={'ms-2'}>
                    <Badge pill bg={'primary'}>{count}</Badge>
                </div>
            )}
        </div>
    );
};

export default NotificationItem;
