import {
    CarrierIdGuidNameDTO, CarrierMinDTO
} from "../../types";
import * as action_types from '../actions/actionTypes';

export type BrokerState = {
    loading_broker_data: boolean;
    min_carriers: CarrierIdGuidNameDTO[];
    selected_carrier_guid: string | null;
    availability_form_cache: any;
    association_carrier: CarrierMinDTO | null;
};

const initial_state: BrokerState = {
    loading_broker_data: true,
    min_carriers: [],
    selected_carrier_guid: null,
    availability_form_cache: null,
    association_carrier: null
};

const set_loading_broker_data = (state: BrokerState, action: any): BrokerState => {
    return {
        ...state,
        loading_broker_data: action.loading_broker_data
    }
};

const set_min_carriers = (state: BrokerState, action: any): BrokerState => {
    return {
        ...state,
        min_carriers: [...action.min_carriers],
        selected_carrier_guid: action.min_carriers.length > 0 ? action.min_carriers[0].guid : null
    }
};

const set_selected_carrier_guid = (state: BrokerState, action: any): BrokerState => {
    return {
        ...state,
        selected_carrier_guid: action.selected_carrier_guid
    }
};

const set_availibility_form_cache = (state: BrokerState, action: any): BrokerState => {
    return {
        ...state,
        availability_form_cache: action.availability_form
    }
};

const set_association_carrier = (state: BrokerState, action: any): BrokerState => ({
    ...state,
    association_carrier: action.association_carrier
});

const reducer = (state = initial_state, action: any) => {
    switch (action.type) {
        case action_types.BROKER_SET_LOADING_BROKER_DATA:
            return set_loading_broker_data(state, action);
        case action_types.BROKER_SET_MIN_CARRIERS:
            return set_min_carriers(state, action);
        case action_types.BROKER_SET_SELECTED_CARRIER_GUID:
            return set_selected_carrier_guid(state, action);
        case action_types.BROKER_SET_AVAILABILITY_FORM_CACHE:
            return set_availibility_form_cache(state, action);
        case action_types.BROKER_SET_ASSOCIATION_CARRIER:
            return set_association_carrier(state, action);
        default:
            return state;
    }
};

export default reducer;
