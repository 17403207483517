import React, {FormEvent, useEffect, useState} from "react";
import {Button, Card, Col, Form, Image, InputGroup, Modal, Row, Spinner, Tab} from "react-bootstrap";
import StateType from "../../../store/store";
import {connect, ConnectedProps} from "react-redux";
import {GeneralActions} from "../../../store/actions/GeneralActions";
import useAccessToken from "../../../hooks/useAccessToken";
import AdminAPI from "../../../api/AdminAPI";
import copy from "copy-to-clipboard";
import * as Input from '../../../inputs';
import Forms from "../../../util/Forms";
import VerticalInputElementForm from "../../../components/VerticalInputElementForm/VerticalInputElementForm";
import {AxiosError} from "axios";
import CarrierAPI from "../../../api/CarrierAPI";

const map_state_to_props = (state: StateType) => ({
    state: {
        is_admin: state.account.is_admin,
        is_carrier: state.account.is_carrier,
        show_image_library: state.general.show_image_library
    }
});

const map_dispatch_to_props = (dispatch: any) => ({
    actions: {
        show_spinner: () => dispatch(GeneralActions.show_spinning_wheel()),
        hide_spinner: () => dispatch(GeneralActions.hide_spinning_wheel()),
        close_image_library: () => dispatch(GeneralActions.close_image_library()),
        set_error_from_response: (e: any) => dispatch(GeneralActions.set_error_from_response(e)),
        logout_if_unauthorized: (e: any) => dispatch(GeneralActions.logout_if_unauthorized(e))
    }
});

const connector = connect(map_state_to_props, map_dispatch_to_props);

interface UploadImageForm extends Record<string, Input.InputField> {
    image_file: Input.SingleFileSelect;
}

const HODImageLibraryModal = ({state, actions}: ConnectedProps<typeof connector>) => {

    const access_token = useAccessToken();

    const [image_keys, set_image_keys] = useState<string[]>([]);
    const [is_loading, set_is_loading] = useState<boolean>(false);
    const [active_key, set_active_key] = useState<string>('images');

    const [upload_image_form, set_upload_image_form] = useState<UploadImageForm>({
        image_file: {
            type: Input.FieldDataType.SingleFileSelect,
            touched: false,
            valid: false,
            hide_valid: true,
            value: null,
            dom_id: 'hod-image-library-image-select',
            validator: file => file !== null
        }
    });
    const [upload_image_form_valid, set_upload_image_form_valid] = useState<boolean>(false);

    const [dark_mode_images, set_dark_mode_images] = useState<{ [key: string]: 'white' | 'dark' }>({});

    const [image_search, set_image_search] = useState<string>('');

    const toggle_image_key = (key: string) => {
        const updated_images = {...dark_mode_images};
        const is_currently_dark = updated_images[key] === 'dark';
        updated_images[key] = is_currently_dark ? 'white' : 'dark';
        set_dark_mode_images(updated_images);
    };

    const load_image_keys = async () => {
        if (is_loading) return;
        try {
            set_is_loading(true);
            set_image_keys([]);
            set_active_key('images');
            set_dark_mode_images({});
            const keys = state.is_admin ?
                await AdminAPI.list_image_library_images(access_token) :
                await CarrierAPI.list_image_library_images(access_token);
            const sorted_keys = [...keys].sort();
            set_image_search('');
            set_image_keys(sorted_keys);
        } finally {
            set_is_loading(false);
        }
    };

    useEffect(() => {
        if (state.show_image_library === true) {
            load_image_keys().then();
        }
    }, [state.show_image_library])

    const on_image_upload_submit = async (event: FormEvent) => {
        event.preventDefault();
        const {form, form_valid} = Forms.filter_and_validate_form(upload_image_form);
        const final_form = form as UploadImageForm;
        if (!form_valid || !final_form.image_file.value) {
            set_upload_image_form(final_form);
            set_upload_image_form_valid(form_valid);
            return;
        }
        try {
            actions.show_spinner();
            if (state.is_admin) {
                await AdminAPI.upload_image(final_form.image_file.value, access_token);
            } else {
                await AdminAPI.upload_image(final_form.image_file.value, access_token);
            }
            actions.hide_spinner();
            set_active_key('images');
        } catch (e: any) {
            actions.hide_spinner();
            actions.logout_if_unauthorized(e);
            const axios_error = e as AxiosError<any>;
            if (axios_error && axios_error.response && axios_error.response.data && axios_error.response.data.message) {
                set_upload_image_form({
                    ...upload_image_form,
                    image_file: {
                        ...upload_image_form.image_file,
                        touched: true,
                        valid: false,
                        value: null,
                        error: `${axios_error.response.data.message}`
                    }
                });
                set_upload_image_form_valid(false);
            } else {
                actions.set_error_from_response(e);
                actions.close_image_library();
            }
        }
    };

    const on_image_upload_change = (key: string, value: any) => {
        const {form, form_valid} = Forms.update_form({key, value, form: upload_image_form});
        const updated_form = form as UploadImageForm;
        if (form_valid) updated_form.image_file.error = undefined;
        set_upload_image_form(updated_form);
        set_upload_image_form_valid(form_valid);
    };

    useEffect(() => {
        if (!state.show_image_library) return;
        set_upload_image_form({
            ...upload_image_form,
            image_file: {
                ...upload_image_form.image_file,
                touched: false,
                valid: false,
                value: null
            }
        })
        set_upload_image_form_valid(false);
        if (active_key === 'images') load_image_keys();
    }, [active_key])

    if (!state.is_admin && !state.is_carrier) return null;

    const render_body = () => {
        if (is_loading) {
            return (
                <div className={'text-center'}>
                    <Spinner animation={'border'} role={'status'}>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )
        }
        const render_images = () => {
            const render_image_card = (image_key: string) => {
                const image_src = `/api/general/image-lib/${image_key}`;
                const is_dark = dark_mode_images[image_key] === 'dark';
                return (
                    <Card className={'h-100'} bg={is_dark ? 'dark' : 'white'} text={is_dark ? 'light' : 'dark'}>
                        <Card.Body className={'h-100'}>
                            <Row>
                                <Col xs={'auto'} className={'ms-auto mb-2'}>
                                    <Button type={'button'}
                                            variant={is_dark ? 'light' : 'dark'}
                                            onClick={(event) => {
                                                toggle_image_key(image_key);
                                                event.currentTarget.blur();
                                            }}>
                                        <i className={`bi bi-${is_dark ? 'sun' : 'moon'}`}/>
                                    </Button>
                                </Col>
                            </Row>
                            <Image fluid
                                   src={image_src}
                                   className={'mb-3'}/>
                            <Card.Text>
                                {image_key}
                            </Card.Text>
                        </Card.Body>
                        <Card.Body>

                            <Row>
                                <Col xs={12} className={'d-grid'}>
                                    <Button
                                        variant={'green'}
                                        type={'button'}
                                        onClick={event => {
                                            copy(image_src);
                                            event.currentTarget.blur();
                                        }}
                                    >Copy URL</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                );
            };
            const search_query = image_search.trim().toLowerCase();
            const filtered_keys = search_query.length > 0 ? image_keys.filter(image_key => image_key.indexOf(search_query) >= 0) : image_keys;
            return (
                <Row xs={1} sm={3} md={4} className={'g-4'}>
                    {filtered_keys.map(image_key => (
                        <Col key={image_key}>
                            {render_image_card(image_key)}
                        </Col>
                    ))}
                </Row>
            )
        };
        const render_preview = () => {
            if (upload_image_form.image_file.value === null) return null;
            return (
                <Image src={URL.createObjectURL(upload_image_form.image_file.value)} fluid/>
            )
        };
        return (
            <Tab.Container activeKey={active_key} onSelect={k => set_active_key(k ?? 'images')}>
                <Row>
                    <Col xs={12} lg={3}>
                        <Row>
                            <Col xs={12} className={'d-grid mb-2'}>
                                <Button type={'button'}
                                        active={active_key === 'images'}
                                        variant={'outline-primary'}
                                        onClick={() => set_active_key('images')}>Browse Images</Button>
                            </Col>
                            <Col xs={12} className={'d-grid mb-2'}>
                                <Button type={'button'}
                                        active={active_key === 'upload'}
                                        variant={'outline-primary'}
                                        onClick={() => set_active_key('upload')}>Upload Image</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey={'images'}>
                                <Row className={'mb-3'}>
                                    <Col>
                                        <InputGroup>
                                            <InputGroup.Text><i className={'bi bi-search'}/></InputGroup.Text>
                                            <Form.Control
                                                placeholder={'Search Images'}
                                                value={image_search}
                                                onChange={event => set_image_search(event.target.value)}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                {render_images()}
                            </Tab.Pane>
                            <Tab.Pane eventKey={'upload'}>
                                <h3>Upload Image</h3>
                                <Form onSubmit={on_image_upload_submit}>
                                    <VerticalInputElementForm fields={upload_image_form}
                                                              group_className={'mb-3'}
                                                              changed={on_image_upload_change}/>
                                    {render_preview()}
                                    <Row className={'mt-3'}>
                                        <Col xs={6} className={'ms-auto d-grid'}>
                                            <Button type={'submit'}
                                                    variant={'green'}
                                                    disabled={!upload_image_form_valid}>Upload Image</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        )
    };

    return (
        <Modal size={'xl'}
               show={state.show_image_library}
               onHide={() => actions.close_image_library()}>
            <Modal.Header closeButton>
                <Modal.Title>HOD Image Library</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {render_body()}
            </Modal.Body>
        </Modal>
    )
};

export default connector(HODImageLibraryModal);
