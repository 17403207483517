import React from "react";
import {LinkContainer} from "react-router-bootstrap";
import {useLocation} from "react-router-dom";
import {NavItem} from "../Navigation";

type Props = {
    nav_item: NavItem;
};

const NavigationItem = ({ nav_item }: Props) => {
    const location = useLocation();
    let is_active = false;
    if (nav_item.is_active) {
        is_active = nav_item.is_active(location.pathname);
    }
    return (
        <LinkContainer to={nav_item.path}>
            <a className={`btn NavigationButton rounded-pill text-start${is_active ? ' active' : ''}`}>{nav_item.title}</a>
        </LinkContainer>
    )
};

export default NavigationItem;
