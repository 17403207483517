import React from "react";
import NotificationBar from "../NotificationBar/NotificationBar";

type Props = {
    on_close_click(): void;
    text: string;
};

const SuccessBar = ({on_close_click, text}: Props) => {
    return (
        <NotificationBar variant={'success'} on_close_click={() => on_close_click()}>
            {text}
        </NotificationBar>
    )
};

export default SuccessBar;
