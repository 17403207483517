import {HeaderLogoDocument} from "../../../BrandingTypes";

import styles from './HeaderLogo.module.scss';
import {Image, Navbar} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

type Props = {
    logo: HeaderLogoDocument;
    text_color?: string;
    branding_language?: 'en' | 'es';
};

const HeaderLogo = ({logo, text_color, branding_language}: Props) => {
    const logo_src = branding_language === 'es' ? logo.src_es ?? logo.src : logo.src;
    const logo_alt = branding_language === 'es' ? logo.alt_es ?? logo.alt : logo.alt;
    const render_as_image = () => {
        return (
            <Image className={styles.HeaderLogo} src={logo_src} alt={logo_alt} fluid/>
        )
    };
    const render_as_text = () => {
        return (
            <span className={`align-self-end ${styles.HeaderText}`} style={{color: text_color}}>{logo_src}</span>
        );
    };
    const render_brand = () => (
        <Navbar.Brand href={logo.target}>
            {logo.type === 'img' ? render_as_image() : render_as_text()}
        </Navbar.Brand>
    );
    if (logo.target && !(/^http(s?):\/\/.+$/i.test(logo.target))) return (
        <LinkContainer to={logo.target}
                       className={'d-flex justify-content-center h-100'}>{render_brand()}</LinkContainer>
    )
    return (
        <>{render_brand()}</>
    );
};

export default HeaderLogo;
