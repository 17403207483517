import {CarrierIdGuidNameDTO, LeadDetailAdminDTO, NonBrokerUserDTO, UserTypeDTO} from '../../types';

import * as action_types from '../actions/actionTypes';

export type CarrierUserState = {
    loading_carrier_user_data: boolean;
    min_carrier: CarrierIdGuidNameDTO | null;
    reassign_lead: LeadDetailAdminDTO | null;
    edit_user: NonBrokerUserDTO | null;
    user_types: UserTypeDTO[];
};

const initial_state: CarrierUserState = {
    loading_carrier_user_data: true,
    min_carrier: null,
    reassign_lead: null,
    edit_user: null,
    user_types: [],
};

const set_loading_carrier_user_data = (state: CarrierUserState, action: any): CarrierUserState => ({
    ...state,
    loading_carrier_user_data: action.loading_carrier_user_data
});

const set_min_carrier = (state: CarrierUserState, action: any): CarrierUserState => ({
    ...state,
    min_carrier: {...action.carrier}
})

const set_reassign_lead = (state: CarrierUserState, action: any): CarrierUserState => ({
    ...state,
    reassign_lead: action.reassign_lead
});

const set_edit_user = (state: CarrierUserState, action: any): CarrierUserState => ({
    ...state,
    edit_user: {...action.edit_user},
});

const set_user_types = (state: CarrierUserState, actions: any): CarrierUserState => ({
    ...state,
    user_types: actions.user_types,
});

const reducer = (state = initial_state, action: any) => {
    switch (action.type) {
        case action_types.CARRIER_USER_SET_LOADING_CARRIER_USER_DATA:
            return set_loading_carrier_user_data(state, action);
        case action_types.CARRIER_USER_SET_MIN_CARRIER:
            return set_min_carrier(state, action);
        case action_types.CARRIER_USER_SET_REASSIGN_LEAD:
            return set_reassign_lead(state, action);
        case action_types.CARRIER_USER_SET_EDIT_USER:
            return set_edit_user(state, action);
        case action_types.CARRIER_USER_SET_USER_TYPES:
            return set_user_types(state, action);
        default:
            return state;
    }
};

export default reducer;
