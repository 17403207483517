import {BrandingDocument} from "../../BrandingTypes";
import * as action_types from '../actions/actionTypes';

export type BrandingState = {
    banner_loading_states: { [banner_name: string]: boolean };
    branding_documents: { [banner_name: string]: BrandingDocument };
    branding_language: "en" | "es",
};

const initial_state: BrandingState = {
    banner_loading_states: {},
    branding_documents: {},
    branding_language: "en",
};

const set_branding_document_is_loading = (state: BrandingState, action: any): BrandingState => {
    const loading_states = {...state.banner_loading_states};
    loading_states[`${action.banner_name}`.trim().toLowerCase()] = true;
    return {
        ...state,
        banner_loading_states: loading_states
    }
}

const set_branding_document = (state: BrandingState, action: any): BrandingState => {
    const branding_document = action.branding_document as BrandingDocument;
    const loading_states = {...state.banner_loading_states};
    const branding_documents = {...state.branding_documents};
    const banner_name = branding_document.banner_name.trim().toLowerCase();
    loading_states[banner_name] = false;
    branding_documents[banner_name] = branding_document;
    return {
        ...state,
        banner_loading_states: loading_states,
        branding_documents: branding_documents
    }
};

const set_branding_language = (state: BrandingState, action: any): BrandingState => {
    return {
        ...state,
        branding_language: action.language
    }
}

const reducer = (state = initial_state, action: any): BrandingState => {
    switch (action.type) {
        case action_types.BRANDING_SET_BRANDING_DOCUMENT:
            return set_branding_document(state, action);
        case action_types.BRANDING_SET_BRANDING_DOCUMENT_IS_LOADING:
            return set_branding_document_is_loading(state, action);
        case action_types.BRANDING_SET_BRANDING_LANGUAGE:
            return set_branding_language(state, action);
        default:
            return state;
    }
};

export default reducer;
